import * as React from "react";
import { EditBase,useEditController, useRedirect, SimpleForm,useGetList, AutocompleteArrayInput,ReferenceArrayInput,useGetMany, TextInput, SelectInput, required, BooleanInput, NumberInput, ReferenceInput, ImageInput, ImageField } from "react-admin";
import { Card, Grid } from "@mui/material";
import {useLocation} from "react-router-dom";

const WaterBodiesEdit = () => {
  const location = useLocation();

  const waterBodyTypeFilter = location.pathname === '/basins' ? 2 : 1;

  const redirect = useRedirect();
  const { record, isLoading } = useEditController();
  const isBasinEdit = record && record.waterBodyType === '/water_body_types/2';

  const onSuccess = () => {
    if (isBasinEdit) {
      redirect('/basins');
    } else {
      redirect('/water_bodies');
    }
  };
  const { data } = useGetList('water_body_types', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
});


const  dataTown  = useGetList('towns', {
  sort: { field: 'name', order: 'ASC' },
  pagination: { page: 1, perPage: 400 },
});

let choicesTown = [];
if (dataTown.data !== undefined) {
  dataTown.data.map((town, index) => {
    choicesTown[index] =
        {
          id: town.id,
          name: town.name
        };
  });
}


   return(
      <EditBase redirect="list" mutationOptions={{ onSuccess }} mutationMode="pessimistic" sanitizeEmptyValues>
        <div>
          <Card>
            <SimpleForm >
              <Grid container className={"containerInput"}>
                <Grid item md={6} xs={12}>
                  <TextInput source="name" label={record.waterBodyType === "/water_body_types/2" ? "Nom du bassin" : "Nom de l’etang"} validate={[required()]} fullWidth/>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={6} xs={12}>
                  <SelectInput  source="town" choices={choicesTown} label="Commune" initialValue={"/towns/133"} validate={[required()]} fullWidth />
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={6}>
                  <TextInput source="numDDPP" label="Numéro DDPP" fullWidth/>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={3} className={"itemForm1"}>
                  <NumberInput source="cadastralSurface" label="Surface cadastrale (en ha)" fullWidth/>
                </Grid>
                <Grid item md={3} className={"itemForm2"}>
                  <NumberInput source="waterSurface" label={record.waterBodyType === "/water_body_types/2" ? "Surface en eau (en m²)" : "Surface en eau (en ha)"} validate={[required()]} fullWidth/>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={3} className={"itemForm1"}>
                  <TextInput source="currentOwner" label="Propriétaire actuel" fullWidth/>
                </Grid>
                <Grid item md={3} className={"itemForm2"}>
                  <TextInput source="previousOwner" label="Propriétaire passé" fullWidth/>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={6}>
                  <ReferenceInput source="currentCollector" label="Nom du collecteur" reference="collectors">
                    <SelectInput optionText="name" label="Nom du collecteur" validate={[required()]} fullWidth/>
                  </ReferenceInput>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={6} xs={12}>
                  <ReferenceArrayInput source="previousCollector" reference="collectors" label="Nom du collecteur passé">
                    <AutocompleteArrayInput label="Nom du collecteur passé" optionText="name"/>
                  </ReferenceArrayInput>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={3} xs={12} className={"itemForm1"}>
                  <NumberInput source="averageDepth" label="Profondeur moyenne (en m)" fullWidth/>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={2} className={"itemForm1"}>
                  <BooleanInput source="aerator" label="Aérateur" fullWidth/>
                </Grid>
                <Grid item md={2}>
                  <BooleanInput source="electricity" label="Electricité" fullWidth/>
                </Grid>
                <Grid item md={2}>
                  <BooleanInput source="feeder" label="Nourrisseur" fullWidth/>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={3} className={"itemForm1"}>
                  <NumberInput source="distance" label="Distance siège/étang (en km)" fullWidth/>
                </Grid>
              </Grid>
              <Grid container className={"containerInput"}>
                <Grid item md={3}>
                  <ImageInput source="picture" label="Photo" accept="image/*">
                    <ImageField source="src" title="title"/>
                  </ImageInput>
                </Grid>
              </Grid>
              {data !== undefined ? <TextInput source="waterBodyType" label="Etangs" defaultValue={record.waterBodyType} type={"hidden"}/> : ''}



            </SimpleForm>
          </Card>
        </div>
      </EditBase>
   );
 }

export default WaterBodiesEdit;
