import { List,ReferenceField,useDataProvider,BooleanInput, useNotify, useUnselectAll, FilterForm,ReferenceInput,SelectInput,useRecordContext,BulkDeleteButton,usePermissions,ShowButton,useListContext,useList,FunctionField,useGetOne,useListController,TopToolbar,ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box,  Typography } from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';

const Empty = () => {
    const { permissions } = usePermissions();
    let data = useListController();
    return(
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore de conformité marque.
            </Typography>
            {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            :''}
            {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?
            <Button href={"#/brand_compliances/create"} label="Créer"/>
            :''}
            
        </Box>
    );
}



function formatRole(role) {
    const withoutPrefix = role.replace('ROLE_', '');

    switch (withoutPrefix) {
        case 'FISHFARMER':
            return 'Pisciculteur';
        case 'TRANSFORMER':
            return 'Transformateur';
        case 'COLLECTOR':
            return 'Collecteur';
        default:
            return withoutPrefix;  // If the role doesn't match any of the specified cases, return the role as-is.
    }
}

const dateRenderer = (record) => {
    const startDate = moment(record.startDate).format('YYYY');
    const endDate = moment(record.endDate).format('YYYY');
    return `${startDate} / ${endDate}`;
  };
  const prepareUserChoices = async (dataProvider) => {
    const { data: users } = await dataProvider.getList('users', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'firstName', order: 'ASC' },
    });

    return users.map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName} ${user.structureName ? '/ ' + user.structureName : ''}`
    }));
};
const PostListActions = () => {
    let data = useListController();
    const dataProvider = useDataProvider();

    const [userChoices, setUserChoices] = useState([]);
    const { permissions } = usePermissions();

    useEffect(() => {
        if (permissions?.includes('ROLE_ADMIN')) {
            prepareUserChoices(dataProvider).then(setUserChoices);
        }
    }, [dataProvider, permissions]);
    const choicesUser = [
        { role: "ROLE_FISHFARMER", name: formatRole("ROLE_FISHFARMER") },
        { role: "ROLE_TRANSFORMER", name: formatRole("ROLE_TRANSFORMER") },
        { role: "ROLE_COLLECTOR", name: formatRole("ROLE_COLLECTOR") },
      ];
    const postFilters = [
        <ReferenceInput source="campaign" label="Campagne" reference="campaigns" alwaysOn>
            <SelectInput 
                emptyText="Toutes les campagnes" 
                optionText={dateRenderer} 
                label="Campagne" 
                fullWidth  
            />
        </ReferenceInput>
        
      ];
      const userFilters = permissions?.includes('ROLE_ADMIN') ? [
        <SelectInput 
            source="user" 
            choices={userChoices}
            optionValue="id" 
            optionText="name" 
            label="Nom/Prénom/Organisme" 
            fullWidth 
            alwaysOn 
        />,
    ] : [];

    const postFiltersRoles = [
        <SelectInput 
            source="role"
            choices={choicesUser}
            optionValue="role"
            optionText="name"
            label="Rôles"
            fullWidth
            alwaysOn
          />
    ];
    const postFiltersCompliance = [
        <SelectInput 
            source="compliance" 
            label="Conformité"
            choices={[
                { id: 1, name: 'Conforme' },
                { id: 0, name: 'Non conforme' }
            ]}
            optionValue="id"
            optionText="name"
            alwaysOn
        />
    ];
    
    return (
        
        <TopToolbar>
            <Stack direction="row" justifyContent="flex-end"   alignItems="center">

                <span style={{display: 'contents'}} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFilters} /></span>
                <span style={{display: 'contents'}} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={userFilters} /></span>
                <span style={{display: 'contents'}} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFiltersRoles} /></span>
                <span style={{display: 'contents'}} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFiltersCompliance} /></span>
 

                <div style={{display: 'flex',marginRight:10}}>
                    {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?
                        <Button href={"#/brand_compliances/create"} label="Créer"/>
                        :''}
                </div>
            </Stack>
        </TopToolbar>
        
    );
}
const CustomBulkDeleteButton = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = useListContext();
    
    const customHandleDelete = async () => {
        try {

            const { data: brandCompliances } = await dataProvider.getMany('brand_compliances', { ids: selectedIds });
            
            // Récupérez les userId des éléments sélectionnés
            const userIds = brandCompliances.map(item => item.user);
            
            // Supprimer des données de la table principale
            // await dataProvider.deleteMany(props.resource, { ids: selectedIds });

            // Supprimer des données de l'autre table en utilisant userId comme filtre
            for (const userId of userIds) {
                // Récupérez tous les compliance_form_contents pour un userId spécifique
                const { data: complianceContents } = await dataProvider.getList('compliance_form_contents', {
                    filter: { user: userId },
                    pagination: { page: 1, perPage: 10000 }, // Assurez-vous d'obtenir tous les enregistrements. Adaptez selon vos besoins.
                    sort: { field: "id", order: "ASC" }
                });
    
                // Récupérez les identifiants des compliance_form_contents
                const contentIds = complianceContents.map(item => item.id);
    
                // Supprimez les compliance_form_contents en utilisant leurs identifiants
                if (contentIds.length > 0) {
                    await dataProvider.deleteMany('compliance_form_contents', { ids: contentIds });
                }
            }

            notify('Elements supprimés');
            unselectAll(props.resource);
        } catch (error) {
            notify('Erreur lors de la suppression', 'warning');
        }
    };

    return <BulkDeleteButton {...props} onClick={customHandleDelete} />;
};


const PostBulkActionButtons = (props) => (
    <>
        <CustomBulkDeleteButton {...props} />
    </>
);
const BrandComplianceList = () => {
    useHideModifierLabel();
    const { permissions } = usePermissions();
    let data = useListController();
    const [currentRole, setCurrentRole] = useState(null);
    return (
        <List actions={<PostListActions />} empty={<Empty />}>
            <h1 className={"titleName"}>{data !== undefined ? data.defaultTitle : ''}</h1>

            <Datagrid bulkActionButtons={<PostBulkActionButtons {...data} />} >
                <TextField source="originId" label="#" />
                <ReferenceField link={false} label="Campagne" source="campaign" reference="campaigns">
                    <FunctionField render={record => record && `${moment(record.startDate).format('YYYY')} / ${moment(record.endDate).format('YYYY')}`} />
                </ReferenceField>
                <FunctionField 
                source="role" 
                label="Rôle" 
                render={record => {
                    const role = formatRole(record.role);
                    setCurrentRole(role);  // Mise à jour de l'état
                    return role;
                }} 
            />
            <ReferenceField label="Nom/Prénom/Organisme" source="user" reference="brandcompliances" link={false}>
                <FunctionField render={record => record &&  `${record.firstName} ${record.lastName} ${record.structureName ? ' / ' + record.structureName : ''}`} emptyText="missing data"/>
            </ReferenceField>
                <FunctionField 
                    label="Conformité" 
                    source="compliance"
                    render={record => (
                        <span style={{ color: record.compliance ? '#2E7D32' : '#B00020'}}>
                            {record.compliance ? 'Conforme' : 'Non conforme'}
                        </span>
                    )}
                />
                
                {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?
                    <EditButton label="MODIFIER"/>
                :''}      
                
                <ShowButton/>
            </Datagrid>
        </List>
    );
}
export default BrandComplianceList;

