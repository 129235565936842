import * as React from 'react';
import { Edit, FormDataConsumer, useListController, useRedirect,useEditController, useGetOne, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';
import { useLocation } from 'react-router-dom';

const FertilizationEdit = () => {
  const redirect = useRedirect();
  const {data} = useGetList('campaigns', {
    sort: {field: 'name', order: 'ASC'},
    pagination: {page: 1, perPage: 1000},
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate + " / " + endDate
          };


    });
  }
  const { record, isLoading } = useEditController();
  const onSuccess = () => {
    const searchParams = new URLSearchParams(location.search);
    const isBasin = searchParams.get('isBasin') === 'true';
    const basinParam = isBasin ? '&isBasin=true' : '';

    redirect({
      pathname: '/action_fertilizations',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}${basinParam}`,
    });
  };
  const  waterBody   = useGetOne('water_bodies', { id: record !== undefined ? record.waterBody : ''});
  let current = useListController();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isBasin = searchParams.get('isBasin') === 'true';
  return (
      <Edit mutationOptions={{ onSuccess }}  mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues>
          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          {isBasin && (
          <Grid container>
            <Grid item xs={6}>
              <SelectInput
                source="affiliation"
                label="Affiliation de l'action"
                choices={[
                  { id: 'Production estivale 1', name: 'Production estivale 1' },
                  { id: 'Production estivale 2', name: 'Production estivale 2' },
                  { id: 'Stockage hivernal 1', name: 'Stockage hivernal 1' },
                  { id: 'Stockage hivernal 2', name: 'Stockage hivernal 2' }
                ]}
                validate={[required()]}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput source="fertilization" reference="fertilizations">
                <SelectInput optionText="name" label="Type de produit" validate={[required()]} fullWidth/>
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container>

            <FormDataConsumer>
              {({ formData, ...rest }) => formData.fertilization == '/fertilizations/1' &&
                <Grid item xs={3} className={"itemForm1"}>
                  <NumberInput source="quantity" label={"Quantité (tonnes)"} validate={[required()]} fullWidth/>
                </Grid>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.fertilization == '/fertilizations/2' &&
                <Grid item xs={3} className={"itemForm1"}>
                  <NumberInput source="quantity" label={"Quantité (litre)"} validate={[required()]} fullWidth/>
                </Grid>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.fertilization == '/fertilizations/3' &&
                <Grid item xs={3} className={"itemForm1"}>
                  <NumberInput source="quantity" label={"Quantité (m3)"} validate={[required()]} fullWidth/>
                </Grid>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.fertilization == '/fertilizations/4' &&
                <Grid item xs={3} className={"itemForm1"}>
                  <NumberInput source="quantity" label={"Quantité (kg)"} validate={[required()]} fullWidth/>
                </Grid>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.fertilization == '/fertilizations/5' &&
                <Grid item xs={3} className={"itemForm1"}>
                  <NumberInput source="quantity" label={"Quantité (tonnes)"} validate={[required()]} fullWidth/>
                </Grid>
              }
            </FormDataConsumer>

            <Grid item xs={3} className={"itemForm2"}>
              <NumberInput source="price" label="Prix d'achat à l'unité (en € HT)" fullWidth/>
            </Grid>

          </Grid>


          <Grid container>
            <Grid item xs={6}>
              <TextInput source="note" label="Remarque(s)" multiline fullWidth />
            </Grid>
          </Grid>
          <TextInput source="waterBody" className={"hidden"} type={"hidden"}/>

        </SimpleForm>
      </Edit>
  );
}
export default FertilizationEdit;
