// in src/posts.js
import { List,useRecordContext,FilterForm,CreateButton,ReferenceField,ReferenceInput,FunctionField,SelectInput,usePermissions,useGetOne,useListController,TopToolbar,ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box,  Typography } from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'
import orderExporter from '../Tools/ExportExcel/exportFishPrice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';

import React, { useEffect } from "react";

const Empty = () => {
    let data = useListController();
    return(
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore de Prix cotisation.
            </Typography>
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            <Button href={"#/member_ship_prices/create"} >Créer</Button>
        </Box>
    );
}

const PostListActions = () => {
    let data = useListController();
    console.log(data);
    return (
        <TopToolbar>
            <Button href={"#/member_ship_prices/create"} >Créer</Button>
            <ExportButton />
        </TopToolbar>
    );
}

const postFilters = [
    <ReferenceInput source="user" label="Utilisateur" reference="users" alwaysOn >
      <SelectInput emptyText="Tous les utilisateurs" optionText="username" label="Utilisateur" fullWidth  defaultValue="TOUS"/>
    </ReferenceInput>
];

const TTCField = ({ source }) => {
    const record = useRecordContext();
    if (record.contribution !== undefined) {
                return (record.contribution*1.2);
        }


};


const ContributionPriceList = () => {
  useHideModifierLabel();
  let data = useListController();
  const waterBody = useGetOne('water_bodies', { id: data.filterValues.waterBody });
  const { permissions } = usePermissions();
  return (
    <div>
      <h1 className={"titleName"}>{data !== undefined ? data.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
      <List exporter={orderExporter} empty={<Empty />} >
        <Datagrid>

            <TextField source="memberShipYear" label="Année d'adhésion" />
            <ReferenceField label="Statut" source="memberStatus" reference="memberStatus">
                <FunctionField render={record => record && record.name !== "" ?`${record.name}` : "Stade non connu"} emptyText="missing data"/>
            </ReferenceField>
            <TextField source="contribution" label="Prix adhésion en € HT" />
            <TTCField source="contribution" label="Prix adhésion en € TTC"/>


            <EditButton label="MODIFIER"/>
        </Datagrid>
      </List>

    </div>

);
}
export default ContributionPriceList;
