import * as React from 'react';
import { Edit, useListController, useGetOne,useEditController,useRedirect, useGetList, SimpleForm, DateTimeInput, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';


const ObservationEdit = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: {page: 1, perPage: 1000},
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };
    });
  }
  const { record, isLoading } = useEditController();
  const onSuccess = () => {
    redirect({
      pathname: '/action_observations',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}`,
    });
  };
  const  waterBody   = useGetOne('water_bodies', { id: record !== undefined ? record.waterBody : ''});
  let current = useListController();
  return (
      <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic">
        <SimpleForm>
          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <TextInput source="generalObservation" label="Observations générales" multiline fullWidth />
            </Grid>
          </Grid>
          <TextInput source="waterBody" className={"hidden"} type={"hidden"}/>


        </SimpleForm>
      </Edit>
  );
}

export default ObservationEdit;
