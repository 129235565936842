import React, { useState, useEffect } from "react";
import { Create, useListController, useDataProvider, useRedirect, useGetOne, useGetList, SimpleForm, DateTimeInput, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid, Typography, Button} from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { Link } from 'react-router-dom';

const ObservationCreate = () => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const handleCampaignChange = (campaign) => {
    setSelectedCampaign(campaign);
    
};
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: {page: 1, perPage: 1000},
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };
    });
  }
  const queryParameters = new URLSearchParams(window.location.hash.split('?')[1])
  const id = queryParameters.get("id")
  const waterBody = useGetOne('water_bodies', {id: id});
  const onSuccess = () => {
    redirect({
      pathname: '/action_observations',
      search: `filter=${JSON.stringify({ waterBody: id })}`,
    });
  };
  let current = useListController();
  const [combinationExists, setCombinationExists] = useState(null);
  const [existingEntryId, setExistingEntryId] = useState(null);
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (selectedCampaign && id) {
        const checkExistingCompliance = async () => {
          dataProvider.getList('action_observations', {
            sort: { field: 'id', order: 'ASC' },
            pagination: { page: 1, perPage: 1 },
            filter: {
                waterBody: id,
                campaign: selectedCampaign
            }
          }).then(({ data, total }) => {
              setCombinationExists(total > 0);
              if (total > 0) {
                  setExistingEntryId(data[0].id);
              }
          }).catch(error => {
              console.error('Erreur lors de la récupération de brandcompliance:', error);
          });
            
        };

        checkExistingCompliance();
    }
}, [selectedCampaign, id, dataProvider]);
  return (
      <Create mutationOptions={{ onSuccess }} mutationMode="pessimistic">
        <SimpleForm>
          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne"  onChange={(e) => handleCampaignChange(e.target.value)} validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          {combinationExists && (
              <div>
                  <Typography variant="body1" style={{color:'crimson'}}>
                      Cette étang possède déjà une observation générale pour cette campagne. 
                      <br/>
                      Appuyez sur 
                      <Link to={`/action_observations/${encodeURIComponent(`${existingEntryId}`)}`}><Button style={{fontWeight:'bold'}}>MODIFIER</Button></Link>
                      si vous voulez la modifier

                  </Typography>
              </div>
          )}
          <Grid container>
            <Grid item xs={6}>
              <TextInput source="generalObservation" label="Observations générales" multiline fullWidth />
            </Grid>
          </Grid>
          {waterBody.data !== undefined ? <TextInput source="waterBody" className={"hidden"} defaultValue={waterBody.data.id} type={"hidden"}/> : ''}


        </SimpleForm>
      </Create>
  );
}

export default ObservationCreate;
