import { List, useMutation, useRecordContext, ReferenceField, FunctionField, useGetOne, useListController, TopToolbar, ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box, Typography } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment'
import 'moment/locale/fr'
import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getAllData(dataProvider, waterBodyType) {
  let allData = [];
  let page = 1;
  const perPage = 100;
  const filters = { waterBodyType: waterBodyType };
  return new Promise(async (resolve) => {
      while (true) {
          const { data, total } = await dataProvider.getList('water_bodies', {
              pagination: { page: page, perPage: perPage },
              sort: { field: 'id', order: 'ASC' },
              filter: filters
          });

          allData = allData.concat(data);

          if (allData.length >= total) {
              resolve(allData);
              return;
          }
          page++;
      }
  });
}
let cache = {};

async function fetchRecords(dataProvider, orders, resource, key) {
  let ids;
  if (Array.isArray(orders[0][key])) {
    // Si la propriété est un tableau, on l'aplatit.
    ids = orders.flatMap(order => order[key]).filter(id => id !== undefined);
  } else {
    ids = orders.map(order => order[key]).filter(id => id !== undefined);
  }

  if (cache[resource] && ids.every(id => cache[resource][id])) {
    return { data: ids.map(id => cache[resource][id]) };
  }

  const result = await dataProvider.getMany(resource, { ids: ids });
  if (!cache[resource]) {
    cache[resource] = {};
  }
  result.data.forEach(item => {
    cache[resource][item.id] = item;
  });

  return result;
}


async function processData(datavide, dataProvider) {
  const orders = await getAllData(dataProvider, datavide[0].waterBodyType);
  const [towns, previousCollectors, currentCollectors] = await Promise.all([
    fetchRecords(dataProvider, orders, 'town', 'town'),
    fetchRecords(dataProvider, orders, 'previousCollector', 'previousCollector'),
    fetchRecords(dataProvider, orders, 'currentCollector', 'currentCollector'),
  ]);
  // const townIds = orders.map(order => order.town).filter(townId => townId !== undefined);
  // dataProvider.getMany('town', { ids: townIds }).then(towns => {
  //   const previousCollectorIds = orders
  //     .map(order => order.previousCollector)
  //     .filter(previousCollectorId => previousCollectorId !== undefined)
  //     .flat();
  //   dataProvider.getMany('previousCollector', { ids: previousCollectorIds }).then(previousCollectors => {
  //     const currentCollectorIds = orders.map(order => order.currentCollector).filter(currentCollectorId => currentCollectorId !== undefined);
  //     dataProvider.getMany('currentCollector', { ids: currentCollectorIds }).then(currentCollectors => {
  let key = 0;
  return orders.map(order => {
    let town = towns.data.find(town => town.id === order.town);
    let previousCollectorNames = previousCollectors.data
      .filter(previousCollector => order.previousCollector.includes(previousCollector.id))
      .map(previousCollector => previousCollector.name)
      .join(", ");
    let currentCollector = currentCollectors.data.find(currentCollector => currentCollector.id === order.currentCollector);
    console.log(orders);
    key++;
    return {
      "ID": order.originId,
      "Nom": order.name,
      "Commune": town.name ? town.name : "",
      "Surface en eau (en ha)": order.waterSurface,
      "N° DDPP": order.numDDPP ? order.numDDPP : "",
      "Surface cadastrale (en ha)": order.cadastralSurface ? order.cadastralSurface : "",
      "Propriétaire actuel": order.currentOwner,
      "Propriétaire passé": order.previousOwner ? order.previousOwner : "",

      "Nom du collecteur": currentCollector.name ? currentCollector.name : "",
      "Nom du collecteur passé": previousCollectorNames,
      "Profondeur moyenne (en m)": order.averageDepth ? order.averageDepth : "",
      "Aérateur": order.aerator ? "Oui" : "Non",
      "Electricité": order.electricity ? "Oui" : "Non",
      "Nourrisseur": order.feeder ? "Oui" : "Non",
      "Distance siège/étang (en km)": order.distance ? "Oui" : "Non",
    };
  });


}

export async function orderExporter(datavide, fetchRelatedRecords, dataProvider) {
  const ordersForExport = await processData(datavide, dataProvider);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Orders');
  const headerRow = worksheet.addRow(Object.keys(ordersForExport[0]));
  headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
  headerRow.eachCell(cell => {
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    if (cell.value) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4e73df' }
      };
    }
  });

  ordersForExport.forEach(order => {
    const row = worksheet.addRow(Object.values(order));
    row.eachCell(cell => {
      cell.alignment = { horizontal: 'left' };
    });
  });
  worksheet.getRow(1).height = 30;

  const defaultColumnWidth = 20;
  const specificColumnWidths = {
    2: 28,
    6: 28,
    10: 25

  };
  worksheet.columns.forEach((column, columnIndex) => {
    if (specificColumnWidths[columnIndex + 1]) {
      column.width = specificColumnWidths[columnIndex + 1];
    } else {
      column.width = defaultColumnWidth;
    }
  });
  workbook.xlsx.writeBuffer().then(buffer => {
    downloadExcel(buffer, 'Mes étangs');
  });
}
export async function orderExporterPDF(datavide, fetchRelatedRecords, dataProvider) {
  const ordersForExport = await processData(datavide, dataProvider);

  const pdfContent = {
    pageOrientation: 'landscape',
    content: [
      { text: 'Étangs', style: 'title' }
    ],
    styles: {
      title: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      header: {
        color:'white',
        fontSize: 6,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      body: {
        fontSize: 6,
      },

    }
  };
  const defaultColumnWidth = 48
  const specificColumnWidths = {
    2: 60,
    7: 60,
    11: 65
  };

  const tableHeader = Object.keys(ordersForExport[0]);
  const tableBody = ordersForExport.map(order => Object.values(order));
  const styledTableBody = tableBody.map(row => {
    return row.map(cellValue => ({ text: cellValue, style: 'body' }));
  });
  pdfContent.content.push({
    layout: {
      fillColor: function (rowIndex, node, columnIndex) {
        return rowIndex === 0 ? '#4e73df' : null; // If it's the header row, set the background color.
      },
      hLineColor: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 'black' : '#AAAAAA';
      },
      vLineColor: function (i, node) {
        return '#AAAAAA';
      },
      paddingLeft: function (i, node) { return 3.5; },
      paddingRight: function (i, node) { return 3.5; },
      paddingTop: function (i, node) { return 3.5; },
      paddingBottom: function (i, node) { return 3.5; }
    },
    table: {
      headerRows: 1,
      widths: tableHeader.map((_, index) => specificColumnWidths[index + 1] || defaultColumnWidth), // Assuming specificColumnWidths and defaultColumnWidth are defined.
      body: [[...tableHeader.map(header => ({ text: header, style: 'header' }))], ...styledTableBody]
    }
  });


  pdfMake.createPdf(pdfContent).download('Étangs.pdf');

}

const downloadExcel = (buffer, filename) => {
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
function clearCache() {
  cache = {};
}
clearCache();
export default orderExporter;
