import * as React from "react";
import { Card, CardContent } from '@mui/material';
import { Title, useCreatePath, Link, useRecordContext, useGetRecordId, useGetOne } from 'react-admin';
import { Grid } from '@mui/material';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useLocation } from 'react-router-dom';

const Manager = () => {

  let recordId;
  { recordId = useGetRecordId() }
  { console.log(recordId) }
  const { data, isLoading, error } = useGetOne('water_bodies', { id: '/water_bodies/' + recordId });
  const createPath = useCreatePath();
  const location = useLocation();

  const isBasin = location.pathname.includes('/basins');
  const basinParam = isBasin ? '&isBasin=true' : '';

  console.log('BJRRRR' + basinParam);

  return (
    <Card className={"ManagerPage"}>
      <h1 className="WaterBodiesTitle">{data !== undefined ? data.name : ''}</h1>
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_fish_stockings',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-circle-plus"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_fish_stockings',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Empoissonnage
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_fishes',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-fish"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_fishes',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Pêche
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_foods',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-wheat-awn"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_foods',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Apport d'alimentation
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_limings',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-chart-line"></i></div>
              Chaulage
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_fertilizations',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-droplet"></i></div>
              Fertilisation
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_aerators',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-wind"></i></div>
              <span>Aération</span>
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_pumpings',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-house-flood-water"></i></div>
              Pompage
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_protections',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-crow"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_protections',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Protection cormorans
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_regulated_animals',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-triangle-exclamation"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_regulated_animals',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Régulation ragondins, cormorans
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_assecs',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-tractor"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_assecs',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Gestion de l'assec
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_fishing_teams',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-euro-sign"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_fishing_teams',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Coût équipe de pêche
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_maintenance_works',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-wrench"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_maintenance_works',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Autres charges
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Link to={{
              pathname: '/action_observations',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              <div className={"btnAction"}><i className="fas fa-eye"></i></div>
            </Link>
            <Link to={{
              pathname: '/action_observations',
              search: `filter=${JSON.stringify({ waterBody: '/water_bodies/' + recordId })}${basinParam}`,
            }}>
              Observation
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Manager;
