import React, { useState } from "react";
import { Edit,useDataProvider, useListController,useGetOne,FormDataConsumer, useRedirect, useEditController, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'
import { dataProvider } from "../providers/dataProviderHydra";
import { RichTextInput } from 'ra-input-rich-text';
import { useLocation } from 'react-router-dom';


const FishStockingEdit = (props) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: {page: 1, perPage: 1000},
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };


    });
  }
  const { record, isLoading } = useEditController();
  let [stockingOriginSuccess, setStockingOriginSuccess] = useState(null);
  const onSuccess = async () => {
    if (record) {      
      console.log(stockingOriginSuccess);
      if (stockingOriginSuccess === "/stocking_origins/2" && record.waterBodyStockingOrigin !== null) { // Si Vendu et waterBodyStockingOrigin existe
        await dataProvider.update('action_fish_stockings', { id: record.id, data: { waterBodyStockingOrigin: null } });
      } else if (stockingOriginSuccess === "/stocking_origins/1" && record.collector !== null) { // Si Gardé et Collector existe
        await dataProvider.update('action_fish_stockings', { id: record.id, data: { collector: null } });
      }
    }
    const searchParams = new URLSearchParams(location.search);
    const isBasin = searchParams.get('isBasin') === 'true';
    const basinParam = isBasin ? '&isBasin=true' : '';
    redirect({
      pathname: '/action_fish_stockings',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}${basinParam}`,
    });
  };

  let current = useListController();
  function GetNameSpieces(id) {
    const  spieciesName = useGetOne('specie_fishes', { id: id });
    if(spieciesName.data)  {
      return spieciesName.data.name;
    } else {
      return spieciesName.data;
    }
  }
  function GetDefaultId(id) {
    const { data } = useGetList('specie_fishes/uniq');
    console.log(data);
    if (data !== undefined) {
      const test = data.filter(({name}) => name === GetNameSpieces(id));
    }


  }
  const formatSpecieFish = (record) => {
    if (!record.stage) {
      record.stage = "Stade non connu";
    }
    return record.stage;
  };

  const firstChoice = (formData) => {
    const datas = GetDefaultId(formData.specieFish)
    console.log("test",datas);


  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isBasin = searchParams.get('isBasin') === 'true';
    const  waterBody   = useGetOne('water_bodies', { id: record !== undefined ? record.waterBody : ''});
  return(
      <Edit mutationOptions={{ onSuccess }}  mutationMode="pessimistic" sanitizeEmptyValues>
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
        <SimpleForm sanitizeEmptyValues>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          {isBasin && (
          <Grid container>
            <Grid item xs={6}>
              <SelectInput
                source="affiliation"
                label="Affiliation de l'action"
                choices={[
                  { id: 'Production estivale 1', name: 'Production estivale 1' },
                  { id: 'Production estivale 2', name: 'Production estivale 2' },
                  { id: 'Stockage hivernal 1', name: 'Stockage hivernal 1' },
                  { id: 'Stockage hivernal 2', name: 'Stockage hivernal 2' }
                ]}
                validate={[required()]}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput source="specieFish"  reference="specie_fishes/uniq">
                <SelectInput optionText="name" label="Espèce" validate={[required()]} fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.specieFish &&
              <Grid container>
                <Grid item xs={6}>
                  <ReferenceInput source="specieFish" reference={"specie_fishes?name="+GetNameSpieces(formData.specieFish)}>
                    <SelectInput  optionText={formatSpecieFish} label="Stade espèce"  fullWidth />
                  </ReferenceInput>
                </Grid>
              </Grid>
            }
          </FormDataConsumer>
          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <NumberInput source="numberHead" label="Nombre de tête(s)"   fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="averageWeight" label="Poids moyen (en kg)" fullWidth />
            </Grid>
            <Grid item xs={3} className={"itemForm2"}>
              <NumberInput source="totalWeight" label="Poids total (en kg)"  validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput source="stockingOrigin"  reference="stocking_origins">
                <SelectInput optionText="name" label="Origine" validate={[required()]} fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.stockingOrigin === "/stocking_origins/2" &&
                <Grid container>
                  <Grid item xs={6}>
                    <ReferenceInput source="collector"  reference="collectors">
                      <SelectInput optionText="name" label="Collecteur"  fullWidth onChange={setStockingOriginSuccess('/stocking_origins/2')}/>
                    </ReferenceInput>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.stockingOrigin === "/stocking_origins/1" &&
                <Grid container>
                  <Grid item xs={6}>
                    <ReferenceInput source="waterBodyStockingOrigin"  reference="water_bodies" perPage={5000}>
                      <SelectInput optionText="name" label="Liste étangs/bassins"  fullWidth onChange={setStockingOriginSuccess('/stocking_origins/1')}/>
                    </ReferenceInput>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>

          <Grid container>
            <Grid item xs={6} className={"itemForm1"}>
              <DateInput  source="date" label="Date" validate={[required()]} fullWidth />
            </Grid>
          </Grid>


          <FormDataConsumer>
            {({ formData, ...rest }) => formData.stockingOrigin === "/stocking_origins/1" &&
              <Grid container>
                <Grid item xs={6}>
                  <ReferenceInput source="fishPrice" reference={"fish_prices?campaign="+formData.campaign+"&specieFish="+formData.specieFish}>
                    <SelectInput optionText="costPrice" label="Tarif (en € HT / kg)" fullWidth/>
                  </ReferenceInput>
                </Grid>
              </Grid>
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) => formData.stockingOrigin === "/stocking_origins/2" &&
              <Grid container>
                <Grid item xs={6}>
                  <ReferenceInput source="fishPrice" reference={"fish_prices?campaign="+formData.campaign+"&specieFish="+formData.specieFish}>
                    <SelectInput optionText="purchaseRateCollector" label="Tarifs (en € HT)" fullWidth/>
                  </ReferenceInput>
                </Grid>
              </Grid>
            }
          </FormDataConsumer>
          <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>
          <TextInput source="waterBody" className={"hidden"} type={"hidden"}/>
        </SimpleForm>
      </Edit>
  );
}


export default FishStockingEdit;
