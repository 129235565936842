import AeratorCreate from './AeratorCreate';
import AeratorEdit from './AeratorEdit';
import AeratorList from './AeratorList';
import AeratorShow from './AeratorShow';

export default {
  list: AeratorList,
  create: AeratorCreate,
  edit: AeratorEdit,
  show: AeratorShow,
};
