import React, { useEffect, useState, useRef } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TextField } from '@mui/material';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';


function GrantFormEdit({ actionsData, onUpdateData }) {
    const [actions, setActions] = useState([]);
    const [expanded, setExpanded] = useState({});
    const hasSetInitialActions = useRef(false);
    const handleActionChange = (actionId, field, value) => {
        const updatedActions = [...actions];
        const actionIndex = updatedActions.findIndex(action => action.grantFormAction.id === actionId);
    
            if (actionIndex !== -1) {
                updatedActions[actionIndex][field] = value;
                if (updatedActions[actionIndex].grantFormAction.parent_id != null) {
                    const parentIndex = updatedActions.findIndex(action => action.grantFormAction.id === updatedActions[actionIndex].grantFormAction.parent_id);
                    if (parentIndex !== -1) {
                        const allSubActionsChecked = updatedActions.filter(action => action.grantFormAction.parent_id === updatedActions[actionIndex].grantFormAction.parent_id).every(action => action.grantBoolean);
                        updatedActions[parentIndex].grantBoolean = allSubActionsChecked;
                    }
                }
                if (updatedActions[actionIndex].grantFormAction.parent_id == null && updatedActions[actionIndex].grantBoolean !== value) {
                    updatedActions.forEach((action, idx) => {
                        if (action.grantFormAction.parent_id === actionId) {
                            updatedActions[idx].grantBoolean = value;
                        }
                    });
                }
            }
            setActions(updatedActions);
            onUpdateData(updatedActions.map(({ action, ...rest }) => rest));
    };
    
    const handleToggle = (parentId) => {
        setExpanded(prevExpanded => ({
            ...prevExpanded,
            [parentId]: !prevExpanded[parentId]
        }));
    };
    const mainActions = actions.filter(action => action.grantFormAction.parent_id == null);
    const subActions = actions.filter(action => action.grantFormAction.parent_id != null);
    useEffect(() => {
        if (!hasSetInitialActions.current && actionsData && actionsData.length > 0) {
            setActions(actionsData);
            hasSetInitialActions.current = true;
        }
    }, [actionsData]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [currentActionIndex, setCurrentActionIndex] = useState(null);
    
    const handleOpenDialog = (index, content) => {
        setDialogContent(content);
        setCurrentActionIndex(index);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            PaperProps={{ style: { minWidth: '300px' } }} 
        >
            <DialogTitle>{"Contenu"}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>

        <Table className="compliance-table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '500px' }}>Étapes</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold' }}>Cocher si étape terminée</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Commentaires</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {mainActions.map((action, index) => (
                    <React.Fragment key={`main-action-${action.grantFormAction.id}-${index}`}>
                        <TableRow key={index}>
                        <TableCell onClick={() => handleToggle(action.grantFormAction.id)}>{action.grantFormAction.action}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                            <Checkbox
                                color="primary"
                                checked={action.grantBoolean === true}
                                onChange={(e) => handleActionChange(action.grantFormAction.id, 'grantBoolean', e.target.checked)}
                                // disabled={!action.grantBoolean && subActions.some(sub => {
                                //     const parentIdNumber = parseInt(sub.grantFormAction.parent_id, 10);
                                //     const actionIdNumberMatch = action.grantFormAction.id.match(/\d+$/);
                                //     const actionIdNumber = actionIdNumberMatch ? parseInt(actionIdNumberMatch[0], 10) : null;
                                //     return parentIdNumber === actionIdNumber && !sub.grantBoolean;
                                // })}
                                
                            />
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TextField 
                                    placeholder="Entrer vos commentaires" 
                                    variant="outlined" 
                                    size="small" 
                                    fullWidth 
                                    style={{ fontSize: '12px' }}
                                    defaultValue={action.comment}
                                    onChange={(e) => handleActionChange(action.grantFormAction.id, 'comment', e.target.value)}
                                    InputProps={{ 
                                        style: { fontSize: '12px' },
                                        endAdornment: (
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleOpenDialog(action.id, action.comment)}
                                            >
                                                <VisibilityIcon fontSize="small" />
                                            </IconButton>
                                        )
                                    }} 
                                />
                            </TableCell>
                           
                        </TableRow>
                        {subActions.filter(subAction => {
                            const parentIdNumber = parseInt(subAction.grantFormAction.parent_id, 10);
                            const actionIdNumberMatch = action.grantFormAction.id.match(/\d+$/);
                            const actionIdNumber = actionIdNumberMatch ? parseInt(actionIdNumberMatch[0], 10) : null;
                            return parentIdNumber === actionIdNumber;
                            }).map((subAction, subIndex) => (
                            <TableRow key={`sub-action-edit-${subAction.grantFormAction.id}-${index}`} style={{backgroundColor: '#f4f4f4'}}>
                                    <TableCell style={{ paddingLeft: 40 }}>
                                        <SubdirectoryArrowRightIcon style={{marginTop:-8}} />
                                        {subAction.grantFormAction.action}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                    <Checkbox 
                                        color="primary" 
                                        checked={subAction.grantBoolean === true}
                                        onChange={(e) => handleActionChange(subAction.grantFormAction.id, 'grantBoolean', e.target.checked)}
                                    />
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <TextField 
                                            placeholder="Entrer vos commentaires" 
                                            variant="outlined" 
                                            size="small" 
                                            fullWidth 
                                            style={{ fontSize: '12px' }}
                                            value={subAction.comment}
                                            onChange={(e) => handleActionChange(subAction.grantFormAction.id, 'comment', e.target.value)}
                                            InputProps={{ 
                                                style: { fontSize: '12px' },
                                                endAdornment: (
                                                    <IconButton 
                                                        size="small" 
                                                        onClick={() => handleOpenDialog(subAction.id, subAction.comment)}
                                                    >
                                                        <VisibilityIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                            }} 
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
        </>
    );
}

export default GrantFormEdit;
