import PumpingCreate from './PumpingCreate';
import PumpingEdit from './PumpingEdit';
import PumpingList from './PumpingList';
import PumpingShow from './PumpingShow';

export default {
  list: PumpingList,
  create: PumpingCreate,
  edit: PumpingEdit,
  show: PumpingShow,
};
