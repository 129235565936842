import { List, useMutation, useRecordContext, ReferenceField, FunctionField, useGetOne, useListController, TopToolbar, ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box, Typography } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment'
import 'moment/locale/fr'
import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


function getAllData(dataProvider, waterBodyId, campaignValue) {
  let allData = [];
  let page = 1;
  const perPage = 100; 
  const filters = { waterBody: waterBodyId };
  if (campaignValue !== null) {
    filters.campaign = campaignValue;
  }
  return new Promise(async (resolve) => {
      while (true) {
          const { data, total } = await dataProvider.getList('action_fish_stockings', {
              pagination: { page: page, perPage: perPage },
              sort: { field: 'id', order: 'ASC' },
              filter: filters
          });

          allData = allData.concat(data);

          if (allData.length >= total) {
              resolve(allData);
              return;
          }
          page++;
      }
  });
}
let cache = {};

async function fetchRecords(dataProvider, orders, resource, key) {
  const ids = orders.map(order => order[key]).filter(id => id !== undefined);

  // Vérifiez si les données sont déjà dans le cache
  if (cache[resource] && ids.every(id => cache[resource][id])) {
    return { data: ids.map(id => cache[resource][id]) };
  }

  const result = await dataProvider.getMany(resource, { ids: ids });

  // Stockez les données dans le cache
  if (!cache[resource]) {
    cache[resource] = {};
  }
  result.data.forEach(item => {
    cache[resource][item.id] = item;
  });

  return result;
}


async function processData(datavide, dataProvider, campaignValue) {
  const orders = await getAllData(dataProvider, datavide[0].waterBody, campaignValue);
  
  const [campaigns, specieFishs, stockingOrigins, collectors, waterBodyStockingOrigins, fishPrices] = await Promise.all([
      fetchRecords(dataProvider, orders, 'campaigns', 'campaign'),
      fetchRecords(dataProvider, orders, 'specieFish', 'specieFish'),
      fetchRecords(dataProvider, orders, 'stockingOrigin', 'stockingOrigin'),
      fetchRecords(dataProvider, orders, 'collector', 'collector'),
      fetchRecords(dataProvider, orders, 'waterBodyStockingOrigin', 'waterBodyStockingOrigin'),
      fetchRecords(dataProvider, orders, 'fishPrice', 'fishPrice')
  ]);

  let key = 0;
  return orders.map(order => {
    let campaign = campaigns.data[key];
    let specieFish = specieFishs.data[key];
    let stockingOrigin = stockingOrigins.data[key];
    let collector = collectors.data.find(collector => collector.id === order.collector);
    let waterBodyStockingOrigin = waterBodyStockingOrigins.data.find(wBody => wBody.id === order.waterBodyStockingOrigin);
    let fishPrice = fishPrices.data[key];

    key++;

    let unitCost = "";
    let totalCost = "";

    if (order.stockingOrigin === "/stocking_origins/2" && fishPrice) {
        unitCost = fishPrice.purchaseRateCollector;
        totalCost = order.totalWeight ? fishPrice.purchaseRateCollector * order.totalWeight : "";
    } else if (order.stockingOrigin === "/stocking_origins/1" && fishPrice) {
        unitCost = fishPrice.costPrice;
        totalCost = order.totalWeight ? fishPrice.costPrice * order.totalWeight : "";
    }

    return {
        "ID": order.originId,
        "Campagne": `${moment(campaign.startDate).format('DD MMMM YYYY')} / ${moment(campaign.endDate).format('DD MMMM YYYY')}`,
        "Date": moment(order.date).format('YYYY'),
        "Origine précise": stockingOrigin.name,
        "Stade Espèce": specieFish.stage,
        "Vendeur": collector ? collector.name : "",
        "Étangs/Bassins d'origine": waterBodyStockingOrigin ? waterBodyStockingOrigin.name : "",
        "Nombre de têtes": order.numberHead ? order.numberHead : "",
        "Poids moyen (en kg)": order.averageWeight ? order.averageWeight : "",
        "Poids total (en kg)": order.totalWeight ? order.totalWeight : "",
        "Coût unitaire (€ HT /kg)": unitCost,
        "Coût total (en € HT)": totalCost,
        "Remarques": order.note ? order.note : "",
    };
  });
}

export async function orderExporter(datavide, fetchRelatedRecords, dataProvider, campaignValue, exportFileName) {
  const ordersForExport = await processData(datavide, dataProvider, campaignValue);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(exportFileName);
  const headerRow = worksheet.addRow(Object.keys(ordersForExport[0]));
  headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
  headerRow.eachCell(cell => {
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    if (cell.value) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4e73df' }
      };
    }
  });

  ordersForExport.forEach(order => {
    const row = worksheet.addRow(Object.values(order));
    row.eachCell(cell => {
      cell.alignment = { horizontal: 'left' };
    });
  });
  worksheet.getRow(1).height = 30;

  const defaultColumnWidth = 20;
  const specificColumnWidths = {
    2: 28,
    6: 25,
    10: 25

  };
  worksheet.columns.forEach((column, columnIndex) => {
    if (specificColumnWidths[columnIndex + 1]) {
      column.width = specificColumnWidths[columnIndex + 1];
    } else {
      column.width = defaultColumnWidth;
    }
  });
  workbook.xlsx.writeBuffer().then(buffer => {
    downloadExcel(buffer, exportFileName);
  });
}

export async function orderExporterPDF(datavide, fetchRelatedRecords, dataProvider, campaignValue, exportFileName) {
  const ordersForExport = await processData(datavide, dataProvider, campaignValue);
  
  const pdfContent = {
    pageOrientation: 'landscape',
    content: [
      { text: exportFileName, style: 'title' }
    ],
    styles: {
      title: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      header: {
        color:'white',
        fontSize: 6,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      body: {
        fontSize: 6,
      },
      
    }
  };
  const defaultColumnWidth = 48
  const specificColumnWidths = {
    2: 60,
    7: 60,
    11: 65
  };

  const tableHeader = Object.keys(ordersForExport[0]);
  const tableBody = ordersForExport.map(order => Object.values(order));
  const styledTableBody = tableBody.map(row => {
    return row.map(cellValue => ({ text: cellValue, style: 'body' }));
  });
  pdfContent.content.push({
    layout: {
      fillColor: function (rowIndex, node, columnIndex) {
        return rowIndex === 0 ? '#4e73df' : null; // If it's the header row, set the background color.
      },
      hLineColor: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 'black' : '#AAAAAA';
      },
      vLineColor: function (i, node) {
        return '#AAAAAA';
      },
      paddingLeft: function (i, node) { return 3.5; },
      paddingRight: function (i, node) { return 3.5; },
      paddingTop: function (i, node) { return 3.5; },
      paddingBottom: function (i, node) { return 3.5; }
    },
    table: {
      headerRows: 1,
      widths: tableHeader.map((_, index) => specificColumnWidths[index + 1] || defaultColumnWidth), // Assuming specificColumnWidths and defaultColumnWidth are defined.
      body: [[...tableHeader.map(header => ({ text: header, style: 'header' }))], ...styledTableBody]
    }
  });


  pdfMake.createPdf(pdfContent).download(exportFileName+ '.pdf');
              
}

const downloadExcel = (buffer, filename) => {
  try {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Erreur lors du téléchargement Excel :', error);
  }
};
function clearCache() {
  cache = {};
}
clearCache();
export default orderExporter;
