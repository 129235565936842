import * as React from 'react';
import { AppBar,useRedirect ,useGetIdentity, Link,Logout, useAuthenticated, useNavigate, UserMenu, useUserMenu } from 'react-admin';
import { MenuItem, Toolbar, RefreshIconButton, ListItemIcon, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const SettingsMenuItem = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const auth = useAuthenticated();
  const redirect = useRedirect();
  const { data, isLoading, error } = useGetIdentity();
  const handleMonCompteClick = () => {
    // Vérifiez si l'utilisateur est authentifié avant de rediriger
    console.log(auth);

    if (data.id) {
      console.log(auth.id);
      redirect(`/users/${data.id}/show`);
    }
  };

  return (
    <MenuItem>
      <Link to={data.id ? `/users/%2Fusers%2F${data.id}/show` : '/'} style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Mon compte</ListItemText>
      </Link>
    </MenuItem>

  );
});


export const MyAppBar = () => (
  <AppBar
    userMenu={
      <UserMenu>
        <SettingsMenuItem />
        <Logout />
      </UserMenu>
    }
  />
);
