import * as React from 'react';
import { Edit,FormDataConsumer, useListController, useGetOne,useEditController, useGetList, useRedirect, SimpleForm, DateTimeInput, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';
import { useLocation } from 'react-router-dom';


const AssecEdit = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: {page: 1, perPage: 1000},
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };
    });
  }
  const { record, isLoading } = useEditController();
  const onSuccess = () => {
    const searchParams = new URLSearchParams(location.search);
    const isBasin = searchParams.get('isBasin') === 'true';
    const basinParam = isBasin ? '&isBasin=true' : '';

    redirect({
      pathname: '/action_assecs',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}${basinParam}`,
    });
  };
  const  waterBody   = useGetOne('water_bodies', { id: record !== undefined ? record.waterBody : ''});
  let current = useListController();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isBasin = searchParams.get('isBasin') === 'true';
  return (
      <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic" sanitizeEmptyValues>
        <SimpleForm>

          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''}
            - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]}
                           fullWidth/>
            </Grid>
          </Grid>
          {isBasin && (
          <Grid container>
            <Grid item xs={6}>
              <SelectInput
                source="affiliation"
                label="Affiliation de l'action"
                choices={[
                  { id: 'Production estivale 1', name: 'Production estivale 1' },
                  { id: 'Production estivale 2', name: 'Production estivale 2' },
                  { id: 'Stockage hivernal 1', name: 'Stockage hivernal 1' },
                  { id: 'Stockage hivernal 2', name: 'Stockage hivernal 2' }
                ]}
                validate={[required()]}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <DateInput source="startDate" label="Date début" validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={3} className={"itemForm2"}>
              <DateInput source="endDate" label="Date fin" validate={[required()]} fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={3} className={"itemForm1"}>
              <BooleanInput source="desinfection" label="Désinfection pêcherie" fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={3}>
              <BooleanInput source="tillage" label="Travail du sol" fullWidth/>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.tillage &&
                <Grid container className={"containerInput"}>
                  <Grid item md={6} xs={12}>
                    <TextInput source="usedTool" label="Outil utilisé"  fullWidth/>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.tillage &&
                <Grid container>
                  <Grid item xs={6}>
                    <NumberInput source="workedArea" label="Surface travaillée (en ha)"
                                 fullWidth/>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>

          <Grid container className={"containerInput"}>
            <Grid item md={3} className={"itemForm1"}>
              <BooleanInput source="liming" label="Chaulage" fullWidth/>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.liming &&
                <Grid container className={"containerInput"}>
                  <Grid item md={6}>

                    <ReferenceInput source="limingProduct" label="Produit chaulage" reference="limings">
                      <SelectInput optionText="name" label="Produit chaulage"
                                   fullWidth/>
                    </ReferenceInput>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.liming &&
                <Grid container>
                  <Grid item xs={6}>
                    <NumberInput source="dose" label="Dose (T/ha)"  fullWidth/>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>

          <Grid container className={"containerInput"}>
            <Grid item md={3} className={"itemForm1"}>
              <BooleanInput source="cultivated" label="Cultivé" fullWidth/>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.cultivated &&
                <Grid container className={"containerInput"}>
                  <Grid item md={6}>
                    <ReferenceInput source="assec" label="Type de culture" reference="assecs">
                      <SelectInput optionText="name" label="Type de culture"
                                   fullWidth/>
                    </ReferenceInput>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.cultivated &&
              <Grid container className={"containerInput"}>
                <Grid item md={3} className={"itemForm1"}>
                  <BooleanInput source="cropHarvest" label="Récolte culture" fullWidth/>
                </Grid>
              </Grid>
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.cultivated &&
              <Grid container className={"containerInput"}>
                <Grid item md={3} className={"itemForm1"}>
                  <BooleanInput source="grinding" label="Broyage culture" fullWidth/>
                </Grid>
              </Grid>
            }
          </FormDataConsumer>
          <Grid container className={"containerInput"}>
            <Grid item md={3}>
              <BooleanInput source="phytosanitaryProducts" label="Produits phytosanitaires" fullWidth/>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.phytosanitaryProducts &&
                <Grid container className={"containerInput"}>
                  <Grid item md={6} xs={12}>
                    <TextInput source="typePhytosanitaryProducts" label="Type de produit phyto"
                               fullWidth/>
                  </Grid>
                </Grid>
            }
          </FormDataConsumer>

          <Grid container className={"containerInput"}>
            <Grid item md={6} xs={12}>
              <BooleanInput source="grindingPlantBelt" label="Broyage ceinture vegetale"
                             fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={3} className={"itemForm1"}>
              <BooleanInput source="cleaning" label="Curage" fullWidth/>
            </Grid>
            <Grid item md={3}>
              <BooleanInput source="maintenanceWork" label="Travaux d'entretien" fullWidth/>
            </Grid>
          </Grid>
          <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>
          <TextInput source="waterBody" className={"hidden"} type={"hidden"}/>

        </SimpleForm>
      </Edit>
  );
}

export default AssecEdit;
