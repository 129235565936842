import { entrypoint } from "../config";
import jwtDecode from "jwt-decode";
import { dataProvider } from "./dataProviderHydra";

export const storeToken = (token) => {
    const decodedToken = jwtDecode(token);
    localStorage.setItem("token", token);
    localStorage.setItem("groups", decodedToken.roles);
    return token;
};

const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    const verified2FA = localStorage.getItem("verified2FA") || false;

    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp && typeof decodedToken.exp === "number") {
            if (Date.now() < decodedToken.exp * 1000) {
                return true;
            }
        }
    }

    return false;
};

export const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(entrypoint + "/auth", {
            method: "POST",
            body: JSON.stringify({ email:username, password }),
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });

        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                const isEnabled2FA = data["2fa_enabled"] || false;
                const twoFactorMethod = data["2fa_method"] || "email";
                const token = data.token;
                let id;
                const decodedToken = jwtDecode(token);
                localStorage.setItem('auth', JSON.stringify(decodedToken));
                localStorage.setItem('token', token);
                localStorage.setItem('permissions', decodedToken.permissions);
                if (!decodedToken.membershipStatus) {
                    throw new Error("Attention la cotisation à l’APPED pour l'année en cours n’a pas été réglée");
                }
                if (token) {
                    id = storeToken(token);
                }
                return Promise.resolve({ data: { id, twoFactorMethod }, isEnabled2FA });
            })
            .then(({ data, isEnabled2FA = false }) => {
                if (isEnabled2FA) {
                    return { redirectTo: "/news" };
                }
                return { redirectTo: '/news' };

            });
    },
    logout: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("groups");
        return Promise.resolve();
    },
    checkAuth: () => {
        const loggedIn = isAuthenticated();
        return loggedIn
            ? Promise.resolve()
            : Promise.reject({ redirectTo: "/login" });
    },
    checkError: (error) => {
        const status = error?.status || error?.response?.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem("token");
            localStorage.removeItem("groups");
            return Promise.reject({ redirectTo: "/login" });
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const groups = localStorage.getItem("groups");


        if (!groups) {
            localStorage.removeItem("token");
            localStorage.removeItem("groups");

            return Promise.reject({ redirectTo: "/login" });
        }
        return Promise.resolve(groups.split(","));
    },
    getIdentity: () => {
        try {
            const { id } = JSON.parse(localStorage.getItem('auth'));

            return Promise.resolve({ id });
        } catch (error) {
            return Promise.reject(error);
        }
    }
};
