import FishCreate from './FishCreate';
import FishEdit from './FishEdit';
import FishList from './FishList';
import FishShow from './FishShow';

export default {
  list: FishList,
  create: FishCreate,
  edit: FishEdit,
  show: FishShow,
};
