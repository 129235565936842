import * as React from 'react';
import { Edit,useGetOne,useListController,FormDataConsumer, useRedirect, useEditController, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'


const ContributionPriceEdit = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };


    });
  }
  const { record, isLoading } = useEditController();
  const onSuccess = () => {
    redirect({
      pathname: '/fish_prices',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}`,
    });
  };

    const  waterBody   = useGetOne('water_bodies', { id: record !== undefined ? record.waterBody : ''});
  function GetNameSpieces(id) {
    console.log(id);
    const  spieciesName = useGetOne('specie_fishes', { id: id });
    if(spieciesName.data)  {
      return spieciesName.data.name;
    } else {
      return spieciesName.data;
    }
  }
  function GetDefaultId(id) {
    const { data } = useGetList('specie_fishes/uniq');
    console.log(data);
    if (data !== undefined) {
      const test = data.filter(({name}) => name === GetNameSpieces(id));
    }


  }
  const formatSpecieFish = (record) => {
    if (!record.stage) {
      record.stage = "Saisir le stade de l'espèce";
    }
    return record.stage;
  };

  const firstChoice = (formData) => {
    const datas = GetDefaultId(formData.specieFish)
    console.log("test",datas);


  };
  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  const prepareYearChoices = () => {
    const currentYear = getCurrentYear()-5;
    const choices = [];

    for (let i = 0; i <= 10; i++) {
      const year = currentYear + i;
      choices.push({ id: year.toString(), name: year.toString() });
    }
    console.log(choices);
    return choices;
  };
  const YearSelectInput = props => {
    return <SelectInput {...props} choices={prepareYearChoices()} />;
  };
  let current = useListController();

  return(
      <Edit mutationOptions={{ onSuccess }}  mutationMode="pessimistic">
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''}</h1>
        <SimpleForm sanitizeEmptyValues>
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput source="memberStatusId"  reference="member_statuses" allowEmpty>
                <SelectInput optionText="name" label="Statut de l'adhérent" validate={[required()]} fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <YearSelectInput source="memberShipYear" label="Année d'adhésion" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <NumberInput source="contribution"  label="Cotisation HT (en € HT)" validate={[required()]} fullWidth />
            </Grid>
          </Grid>

        </SimpleForm>
      </Edit>
  );
}


export default ContributionPriceEdit;
