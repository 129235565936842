// in src/posts.js
import { List, Datagrid,ExportButton, useDataProvider, FilterForm,useListController, dataProvider, CreateButton, Button, FilterButton, TextField,ReferenceInput,SelectInput,usePermissions,TextInput, EditButton, useRecordContext, useRedirect, ReferenceField, FunctionField } from 'react-admin';
import orderExporter from '../Tools/ExportExcel/exportWaterBodies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Box,  Typography } from '@mui/material';



const MyButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const location = useLocation();
  const waterBodyTypeFilterBtn = location.pathname === '/basins' ? '/basins' : '';
  
  const handleClick = () => {
    console.log(record);
    redirect(`/manager/${record.originId}${waterBodyTypeFilterBtn}`);
  };

  return (
    <button className={'ManagerButton'} onClick={handleClick}>
      Gérer
    </button>
  );
};






const WaterBodiesList = () => {
    useHideModifierLabel();

  const { permissions } = usePermissions();
  const location = useLocation();
  const waterBodyTypeFilter = location.pathname === '/basins' ? 2 : 1;

  const Empty = () => {
    return(
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore d'étangs.
            </Typography>
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            {waterBodyTypeFilter === 2 ?
            <Button href={"#/basins/create"} label="Créer"/> : <Button href={"#/water_bodies/create"} label="Créer"/>}
        </Box>
    );
  }
  const CustomCreateButton = (props) => {
    const redirect = useRedirect();

    return (
      <Button
        label="Créer"
        onClick={() => redirect('/basins/create')}
        startIcon={<AddIcon />}
        {...props}
      />
    );
  };


  const CustomEditButton = (props) => {
    const record = useRecordContext();
    const redirect = useRedirect();

    return (
      <Button
        label="Modifier"
        onClick={(e) => {
          e.stopPropagation();
          const encodedId = encodeURIComponent(`${record.id}`);
          redirect(`/basins/${encodedId}`);
        }}
        startIcon={<EditIcon />}
        {...props}
      />
    );
  };
  const prepareUserChoices = async (dataProvider) => {
    const { data: users } = await dataProvider.getList('users', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'lastName', order: 'ASC' },
    });

    return users.map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName} ${user.structureName ? '/ ' + user.structureName : ''}`
    }));
  };
  const ListToolbar = () => {
    let data = useListController();
    const dataProvider = useDataProvider();
    const [userChoices, setUserChoices] = useState([]);
    const filteredData = data?.data?.filter(item => {
      console.log(item.waterBodyType, `/water_body_types/${waterBodyTypeFilter}`); // Afficher la valeur de waterBodyType pour chaque élément
      return item.waterBodyType === `/water_body_types/${waterBodyTypeFilter}`;
    });
    console.log(filteredData);
    useEffect(() => {
      if (permissions.includes('ROLE_ADMIN')) {
          prepareUserChoices(dataProvider).then(setUserChoices);
      }
    }, [dataProvider, permissions]);
    const postFiltersUsers = [
      <SelectInput
              source="user"
              choices={userChoices}
              optionValue="id"
              optionText="name"
              emptyText="Tous les utilisateurs"
              label="Nom/Prénom/Organisme"
              fullWidth
              alwaysOn
          />
    ];

    return(
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
      {permissions !== undefined && permissions.includes('ROLE_ADMIN') &&
        <span style={{display: 'contents'}}>
          <FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} />
          <FilterForm filters={postFiltersUsers} />
        </span>
      }
      <div style={{display: 'flex'}}>
        {location.pathname === '/basins' ? <CustomCreateButton /> : <CreateButton />}
        <ExportButton label='Exporter' onClick={() => orderExporter(filteredData, null, dataProvider)}/>
      </div>
    </Stack>
    )

  }

return (
  <div>
    <h1 className={"title"}>{waterBodyTypeFilter === 1 ? 'Mes étangs' : 'Mes bassins'}</h1>
    <List title="Mes Etangs"  actions={<ListToolbar />}  exporter={false} empty={<Empty />} filter={{ waterBodyType: waterBodyTypeFilter }} disableSyncWithLocation>
      <Datagrid>
        <TextField source="originId" label="#" />
        <TextField source="name" label="Nom" />
        <ReferenceField label="Commune" source="town" reference="towns" link={false}>
          <FunctionField render={record => record && `${record.name}`} />
        </ReferenceField>
          <TextField
              source="waterSurface"
              label={waterBodyTypeFilter === 1 ? "Surface en eau (en ha)" : "Surface en eau (en m²)"}
          />
        <TextField source="numDDPP" label="N° DDPP" />
        <MyButton className={'ManagerButton'}>Gérer</MyButton>
        <EditButton label="MODIFIER"/>
      </Datagrid>
    </List>
  </div>

)
};






export default WaterBodiesList;
