import * as React from 'react';
import {
  Create,
  useListController,
  FormDataConsumer,
  useRedirect,
  useGetOne,
  SimpleForm,
  useGetList,
  required,
  NumberInput,
  SelectInput,
  ReferenceInput
} from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';

const FishPriceCreate = () => {
  const redirect = useRedirect();
  const { data: campaignsData } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 1000 },
  });

  let choices = [];
  if (campaignsData) {
    campaignsData.forEach((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] = {
        id: campaign.id,
        name: `${startDate} / ${endDate}`,
      };
    });
  }

  const onSuccess = () => {
    redirect('/fish_prices');
  };

  const GetNameSpieces = (id) => {
    const { data: speciesData } = useGetOne('specie_fishes', { id });
    return speciesData ? speciesData.name : '';
  };

  const GetDefaultId = (id) => {
    const { data: uniqData } = useGetList('specie_fishes/uniq');
    return uniqData ? uniqData.filter(({ name }) => name === GetNameSpieces(id)) : [];
  };

  const formatSpecieFish = (record) => {
    if (!record.stage) {
      record.stage = "Stade non connu";
    }
    return record.stage;
  };

  const firstChoice = (formData) => {
    return GetDefaultId(formData.specieFish);
  };

  const current = useListController();

  return (
    <Create mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <h1 className="titleName">{current.defaultTitle || ''}</h1>
      <SimpleForm sanitizeEmptyValues>
        <Grid container>
          <Grid item xs={6}>
            <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <ReferenceInput source="specieFish1" reference="specie_fishes/uniq">
              <SelectInput optionText="name" label="Espèce" validate={[required()]} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.specieFish1 && (
            <Grid container>
              <Grid item xs={6}>
                <ReferenceInput
                  source="specieFish"
                  sort={{ field: 'id', order: 'DESC' }}
                  reference={`specie_fishes?name=${GetNameSpieces(formData.specieFish1)}`}
                >
                  <SelectInput optionText={formatSpecieFish} label="Saisir stade espèce" validate={[required()]} fullWidth />
                </ReferenceInput>
              </Grid>
            </Grid>
          )}
        </FormDataConsumer>
        <Grid container>
          <Grid item xs={3} className="itemForm1">
            <NumberInput
              source="disposalFeeCollector"
              label="Vendu au collecteur (en € HT/kg)"
              min={0}
              step={0.01}
              validate={[required()]}
              fullWidth
              parse={v => v === '' ? null : parseFloat(v)} // Parse input to float, handle empty input as null
              format={v => v == null ? '' : parseFloat(v).toFixed(2)} // Format the float value to 2 decimal places
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} className="itemForm1">
            <NumberInput
              source="purchaseRateCollector"
              label="Acheté au collecteur (en € HT/kg)"
              min={0}
              step={0.01}
              validate={[required()]}
              fullWidth
              parse={v => v === '' ? null : parseFloat(v)} // Parse input to float, handle empty input as null
              format={v => v == null ? '' : parseFloat(v).toFixed(2)} // Format the float value to 2 decimal places
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} className="itemForm1">
            <NumberInput
              source="costPrice"
              label="Empoissonnage autoproduit ou gardé tarif (€ HT/kg)"
              min={0}
              step={0.01}
              validate={[required()]}
              fullWidth
              parse={v => v === '' ? null : parseFloat(v)} // Parse input to float, handle empty input as null
              format={v => v == null ? '' : parseFloat(v).toFixed(2)} // Format the float value to 2 decimal places
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default FishPriceCreate;
