import FishStockingCreate from './FishStockingCreate';
import FishStockingEdit from './FishStockingEdit';
import FishStockingList from './FishStockingList';
import FishStockingShow from './FishStockingShow';

export default {
  list: FishStockingList,
  create: FishStockingCreate,
  edit: FishStockingEdit,
  show: FishStockingShow,
};
