import { TranslationMessages } from "react-admin";
import deutschMessages from "ra-language-german";

const customDeutschMessages: TranslationMessages = {
    ...deutschMessages,
    pos: {
        edit: "Bearbeiten"
    }
};

export default customDeutschMessages;
