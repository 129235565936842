import ContributionPriceCreate from './ContributionPriceCreate';
import ContributionPriceEdit from './ContributionPriceEdit';
import ContributionPriceList from './ContributionPriceList';
import ContributionPriceShow from './ContributionPriceShow';

export default {
  list: ContributionPriceList,
  create: ContributionPriceCreate,
  edit: ContributionPriceEdit,
  show: ContributionPriceShow,
};
