import AssecCreate from './AssecCreate';
import AssecEdit from './AssecEdit';
import AssecList from './AssecList';
import AssecShow from './AssecShow';

export default {
  list: AssecList,
  create: AssecCreate,
  edit: AssecEdit,
  show: AssecShow,
};
