import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-admin';
import './backbutton.css';

const BackButton = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        const searchParams = new URLSearchParams(location.search);
        const filterParam = searchParams.get('filter');

        if (filterParam) {
            try {
                const filterObject = JSON.parse(decodeURIComponent(filterParam));
                if (filterObject.waterBody) {
                    const waterBodyId = filterObject.waterBody;
                    navigate('/manager/' + waterBodyId.split("/water_bodies/")[1]);
                }
            } catch (error) {
                console.error("Erreur lors de l'analyse du paramètre 'filter'.", error);
            }
        }
    };

    return (
        <Button label="Retour aux actions" id="btnRetour" style={{width:170}} onClick={handleClick} />
    );
};

export default BackButton;
