import React from 'react';
import { useLocation } from 'react-router-dom';
import { ResourceContextProvider } from 'react-admin';
import WaterBodiesCreate from './WaterBodiesCreate';
import WaterBodiesEdit from './WaterBodiesEdit';
import WaterBodiesList from './WaterBodiesList';
import WaterBodiesShow from './WaterBodiesShow';

const WaterBodiesRouter = () => {
    const location = useLocation();
    const path = location.pathname;

    // Le nom de la ressource
    const resourceName = "water_bodies";

    // Détermine quel sous-composant afficher
    let ComponentToRender;

    if (path.endsWith('/create')) {
        ComponentToRender = <WaterBodiesCreate />;
    } else if (path.match(/\/basins\/%2Fwater_bodies%2F\d+$/)) {
        ComponentToRender = <WaterBodiesEdit />;
    } else {
        ComponentToRender = <WaterBodiesList />;
    }

    return (
        <ResourceContextProvider value={resourceName}>
            {ComponentToRender}
        </ResourceContextProvider>
    );
};

export default WaterBodiesRouter;
