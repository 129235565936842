import FishingTeamCreate from './FishingTeamCreate';
import FishingTeamEdit from './FishingTeamEdit';
import FishingTeamList from './FishingTeamList';
import FishingTeamShow from './FishingTeamShow';

export default {
  list: FishingTeamList,
  create: FishingTeamCreate,
  edit: FishingTeamEdit,
  show: FishingTeamShow,
};
