import FoodCreate from './FoodCreate';
import FoodEdit from './FoodEdit';
import FoodList from './FoodList';
import FoodShow from './FoodShow';

export default {
  list: FoodList,
  create: FoodCreate,
  edit: FoodEdit,
  show: FoodShow,
};
