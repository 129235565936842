import React from 'react';
import PropTypes from 'prop-types';

const ShortDescription = ({ description, maxLength = 100 }) => {
    // Check if the description needs to be shortened
    const needsShortening = description.length > maxLength;

    // Get the truncated text
    const truncatedText = description.substring(0, maxLength);
    const displayText = needsShortening ? `${truncatedText}...` : description;
    // Return the shortened or original description
    return (
    <div className="content" dangerouslySetInnerHTML={{__html: displayText}}></div>

);
};

ShortDescription.propTypes = {
    description: PropTypes.string.isRequired,
    maxLength: PropTypes.number
};

export default ShortDescription;