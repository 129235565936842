import { List,ReferenceField, useRedirect, useDataProvider, ReferenceInput, SelectInput, FilterForm, useListContext,useRecordContext,FunctionField,useGetOne,useListController,TopToolbar,ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box,  Typography } from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'
import {orderExporter, orderExporterPDF} from '../Tools/ExportExcel/exportFertilization';
import React, { useState,useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';
import { useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

const Empty = () => {
    let data = useListController();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isBasin = searchParams.get('isBasin') === 'true';
    return(
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore de Fertilisation.
            </Typography>
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            <Button href={`#/action_fertilizations/create?id=${data.filterValues.waterBody}${isBasin ? '&isBasin=true' : ''}`  } label="Créer"/>
        </Box>
    );
}
const dateRenderer = (record) => {
  const startDate = moment(record.startDate).format('YYYY');
  const endDate = moment(record.endDate).format('YYYY');
  return `${startDate} / ${endDate}`;
};
const PostListActions = (waterName) => {
  let data = useListController();
  const dataProvider = useDataProvider();
  const [campaignValue, setCampaignValue] = useState(null); 

  const handleSelectChange = (event) => {
    setCampaignValue(event.target.value);
    
  };
  let waterNameExport = waterName?.waterName;
  if (waterNameExport) {
  waterNameExport = waterNameExport.replace(/[*?:\/[\]]/g, '_'); 
  }
  let exportFileName = 'Fertilisation - ' + (waterNameExport || '');
  const postFilters = [
    <ReferenceInput source="campaign" label="Campagne" reference="campaigns" alwaysOn>
        <SelectInput 
            emptyText="Toutes les campagnes" 
            optionText={dateRenderer} 
            label="Campagne" 
            fullWidth  
            defaultValue="" 
            onChange={handleSelectChange}
        />
    </ReferenceInput>
  ];
  const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isBasin = searchParams.get('isBasin') === 'true';
 return (
  <TopToolbar>
      <Stack direction="row" justifyContent="flex-end"   alignItems="center">

          <span style={{display: 'contents'}} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFilters} /></span>
          <div style={{display: 'flex'}}>
          <Button href={`#/action_fertilizations/create?id=${data.filterValues.waterBody}${isBasin ? '&isBasin=true' : ''}`  } label="Créer"/>
          <div style={{marginLeft:10}}>
          <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#38a9b4', height: 30, width: 'auto', border: 'none', margin: '0 20px 0 0', display:'flex', alignItems:'center' }}>
              <span style={{ marginRight: 10 }}>Exporter</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu" style={{ padding: 10, border: '1px solid #f2f2f2', width:200 }}>
                  <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <ExportButton label='Exporter (Excel)' onClick={() => orderExporter(data.data, null, dataProvider, campaignValue, exportFileName)}/>
                  <ExportButton label='Exporter (PDF)' onClick={() => orderExporterPDF(data.data, null, dataProvider, campaignValue, exportFileName)}/>
                  </div>
              </Dropdown.Menu>
              </Dropdown>
              </div>
          </div>
      </Stack>
  </TopToolbar>
);
}
const CustomEditButton = (props) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isBasin = searchParams.get('isBasin') === 'true';

  return (
      <Button
      label="Modifier"
      onClick={(e) => {
        e.stopPropagation();
        const encodedId = encodeURIComponent(`${record.id}`);
        redirect({
          pathname: `/action_assecs/${encodedId}`,
          search: isBasin ? '?isBasin=true' : ''
        });
      }}
      startIcon={<EditIcon />}
      {...props}
    />
  );
};
const EmptyTextField = ({ source }) => {
    const record = useRecordContext();
    console.log(record);
    if (record[source] === undefined) {
        return (<span style={{color:'red'}}>Non saisi</span>);
    } else {
        return (<span>{record && record[source]}</span>);
    }

};

const UnitTextField = ({ source }) => {
  const record = useRecordContext();
  console.log('test',record.fertilization);
  if (record[source] === undefined) {
    return (<span style={{color:'red'}}>Non saisi</span>);
  } else {
    if (record.fertilization == '/fertilizations/1') {
      return (<span>{record && record[source]} tonnes</span>);
    }
    if (record.fertilization == '/fertilizations/2') {
      return (<span>{record && record[source]} L</span>);
    }
    if (record.fertilization == '/fertilizations/3') {
      return (<span>{record && record[source]} m3</span>);
    }
    if (record.fertilization == '/fertilizations/4') {
      return (<span>{record && record[source]} kg</span>);
    }
    if (record.fertilization == '/fertilizations/5') {
      return (<span>{record && record[source]} T</span>);
    }

  }

};

const FertilizationList = () => {
    useEffect(() => {
      const headers = document.querySelectorAll('.MuiTableRow-root.MuiTableRow-head .MuiTableCell-root > span');
      
      headers.forEach(header => {
        if (header.textContent === 'MODIFIER') {
          header.classList.add('hide-label');
        }
      });
    }, []);
    let data = useListController();
    const waterBody = useGetOne('water_bodies', { id: data.filterValues.waterBody });
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isBasin = searchParams.get('isBasin') === 'true';
    return (
        <List actions={<PostListActions waterName={waterBody?.data?.name}/>} exporter={false} empty={<Empty />}>
            <h1 className={"titleName"}>{data !== undefined ? data.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
            <Datagrid>
                <TextField source="originId" label="#" />
              <ReferenceField link={false}  label="Campagne" source="campaign" reference="campaigns">
                <FunctionField render={record => record && `${moment(record.startDate).format('YYYY')} / ${moment(record.endDate).format('YYYY')}`} />
              </ReferenceField>
              {isBasin && <TextField source="affiliation" label="Affiliation de l'action" />}
              <ReferenceField link={false} label="Fertilisation" source="fertilization" reference="fertilizations">
                <FunctionField render={record => record && `${record.name}`} />
              </ReferenceField>
                <UnitTextField source="quantity" label="Quantité"/>
                <EmptyTextField source="price"  label="Prix d'achat à l'unité (en € HT)"/>
              <FunctionField
                label="Coût total (en € HT)"
                render={record => `${record.quantity*record.price}`}
              />;
                <CustomEditButton label="MODIFIER"/>
            </Datagrid>
        </List>
    );
}
export default FertilizationList;
