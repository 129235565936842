import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import JumboDemoCard from "../../@jumbo/components/JumboDemoCard";

const transformData = (data, categoryNames, categoryPropertyName, valuePropertyName) => {
    return data.map(item => {
      const transformed = { campaign_date: item.campaign_date };
  
      // Initialise all category values to 0
      categoryNames.forEach(category => {
        transformed[category] = 0;
      });
  
      // Fill in actual category values
      item[categoryPropertyName].forEach(category => {
        transformed[category.name] = category[valuePropertyName];
      });
  
      return transformed;
    });
  };
  

  

const CombinedBarChart = ({ title, rawData, size, unityValue, ticks, categoryPropertyName, valuePropertyName }) => {
    const categoryColorMap = {
      Carpes: "#8249F4",
      Tanches: "#BBF449",
      Blancs: "#4966F4",
      Carnassiers: "#D749F4",
      Indésirables: "#F449BB",
      Autres: "#FFC107",
    };
  
    const categoryNamesUnsorted = [...new Set(rawData.flatMap(item => item[categoryPropertyName].map(cat => cat.name)))];
    const categoryValues = rawData.reduce((values, item) => {
      item[categoryPropertyName].forEach(category => {
        values[category.name] = (values[category.name] || 0) + category[valuePropertyName];
      });
      return values;
    }, {});
  
    const categoryNames = categoryNamesUnsorted.sort((a, b) => categoryValues[b] - categoryValues[a]);
    const categoryNamesForLegend = [...categoryNames].sort();
    const data = useMemo(() => transformData(rawData, categoryNames, categoryPropertyName, valuePropertyName), [rawData, categoryNames, categoryPropertyName, valuePropertyName]);
  
    const formatTick = (value) => {
        const absValue = Math.abs(value);
        if (absValue >= 1000 && absValue < 1000000) {
          return (value < 0 ? "-" : "") + (absValue / 1000).toLocaleString('en') + 'k';
        }
        if (absValue >= 1000000) {
          return (value < 0 ? "-" : "") + (absValue / 1000000).toLocaleString('en') + 'M';
        } 
        return (value < 0 ? "-" : "") + value.toLocaleString('en');
      };
      
      const CustomLegend = () => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', marginTop: 20 }}>
            {categoryNamesForLegend.map((entry, index) => (
              <div
                key={`legend-${index}`}
                style={{ display: 'flex', alignItems: 'center', margin: '30px 5px 5px 10px', position: 'relative' }}
              >
                <div style={{ width: 10, height: 10, backgroundColor: categoryColorMap[entry], marginRight: 5 }}></div>
                <span style={{ fontSize: '12px' }}>{entry}</span>
              </div>
            ))}
          </div>
        );
      };
      
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'white', padding: 15, borderRadius: '5px', border: '1px solid darkgray' }}>
            <p style={{ fontWeight: 'bold', marginBottom: 10 }}>
                <span>{label}</span>
            </p>
            {payload.map((entry, index) => (
                <p key={`tooltip-${index}`} style={{wordWrap:'break-word', color: entry.fill }}>
                    {`${entry.name} : ${entry.value.toFixed(2)} ${unityValue ? unityValue : ''}`}
                </p>
            ))}
            </div>
        );
        }

        return null;
    };

  return (
    <JumboDemoCard
      title={
        <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {Array.isArray(title)
            ? title.map((line, i) => <div key={i} style={{ lineHeight: '1.8' }}>{line}</div>)
            : title}
        </div>
      }
      wrapperSx={{ pt: 0, backgroundColor: 'background.paper' }}
    >
      <ResponsiveContainer width="100%" height={size ? size : 300}>
        <BarChart data={data} margin={{ top: 15, right: 0, left: 0, bottom: 0 }}>
            <YAxis ticks={[0, ...ticks]} tickFormatter={formatTick} axisLine={false} />
            <XAxis dataKey="campaign_date" tick={{ fontSize: 10 }} interval={0} angle={-45} textAnchor="end" />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <Legend content={<CustomLegend />} />
            {
                categoryNames.map((category, index) => (
                <Bar key={category} dataKey={category} stackId="1" fill={categoryColorMap[category]} />
                ))
            }
        </BarChart>
      </ResponsiveContainer>
    </JumboDemoCard>
  );
};

export default CombinedBarChart;
