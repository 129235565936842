import React, { useState, useEffect, useRef } from "react";
import { Edit, FormDataConsumer, useDataProvider,AutocompleteInput ,useShowController, useRefresh, SaveButton, Toolbar, useListController, useRedirect,useEditController, useGetOne, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';
import ComplianceFormEdit from '../Tools/ComplianceForm/ComplianceFormEdit';
import isEqual from 'lodash/isEqual';

const BrandComplianceEdit = (props) => {
  const { record, isLoading } = useEditController();
  const redirect = useRedirect();
  const [complianceData, setComplianceData] = useState(null);
  const userId = record?.user;
  const campaignId = record?.campaign;
  const prevTransformedDataRef = useRef();

  const { data: complianceContents } = useGetList('compliance_form_contents', {
    sort: { field: 'compliance_form_action_id', order: 'ASC' },
    filter: { user: userId, campaign: campaignId }
  });


  const actionIds = [...new Set(complianceContents?.map(content => content.complianceFormAction))];
  const { data: complianceActions } = useGetList('compliance_form_actions', {
    filters: { id_in: actionIds.join(",") }
  });
  console.log(complianceActions);
  const transformedData = complianceContents?.map(content => {
      const action = complianceActions?.find(action => action.id === content.complianceFormAction);
      return {
          ...content,
          complianceFormAction: {
              id: action?.id,
              action: action?.action
          }
      };
  });

  const [reRenderKey, setReRenderKey] = useState(0);
  const [hasShownComplianceForm, setHasShownComplianceForm] = useState(false);
  const varData = transformedData;
  console.log(hasShownComplianceForm);
  useEffect(() => {
    // Vérifiez si transformedData a changé en profondeur par rapport à sa version précédente
    if (!isEqual(transformedData, prevTransformedDataRef.current)) {
      prevTransformedDataRef.current = transformedData;
      if (!hasShownComplianceForm) {
        setHasShownComplianceForm(true);
      }
      setReRenderKey((prevKey) => prevKey + 1);

    }
  }, [transformedData]);
  const onSuccess = (response) => {
      let userIdRaw = response?.user;
      let userIdSuccess = userIdRaw?.split('/')[2];
      let campaignIdRaw = response?.campaign;
      let campaignIdSuccess = campaignIdRaw?.split('/')[2];
      const url = `https://api.poissonsdedombes.fr/brandcompliance/compliance-form-content-edit/${userIdSuccess}/${campaignIdSuccess}`;
      const token = localStorage.getItem('token');
      fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(complianceData)
      })
      .then(response => response.json())
      .then(data => {
          if (data.error) {
              console.error(`Erreur : ${data.error}`);
          } else {

              console.log(data.message);


          }
      })
      .catch(error => {
          console.error('Une erreur est survenue lors de l\'envoi des données:', error);
      });


    redirect({
      pathname: '/brand_compliances',

    });
  };

  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
        {
          id: campaign.id,
          name: startDate+" / "+endDate
        };


    });
  }
  const choicesUser = [
    { role: "ROLE_FISHFARMER", name: formatRole("ROLE_FISHFARMER") },
    { role: "ROLE_TRANSFORMER", name: formatRole("ROLE_TRANSFORMER") },
    { role: "ROLE_COLLECTOR", name: formatRole("ROLE_COLLECTOR") },
  ];

    function formatRole(role) {
      const withoutPrefix = role.replace('ROLE_', '');

      switch (withoutPrefix) {
          case 'FISHFARMER':
              return 'Pisciculteur';
          case 'TRANSFORMER':
              return 'Transformateur';
          case 'COLLECTOR':
              return 'Collecteur';
          default:
              return withoutPrefix;  // If the role doesn't match any of the specified cases, return the role as-is.
      }
  }
  const [selectedRole, setSelectedRole] = useState(record.role);

  const { data: filteredUsers } = useGetList(
    'users',
    {
      sort: { field: 'firstName', order: 'ASC' },
      filter: { roles: selectedRole ? [selectedRole] : [] }, // Utilisation d'un tableau ici
    },
    { enabled: !!selectedRole }
);

  const userChoices = filteredUsers
    ? Object.values(filteredUsers).map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`
      }))
    : [];
    const handleRoleChange = (roleValue) => {
      setSelectedRole(roleValue);
    };
    const [initialComplianceData, setInitialComplianceData] = useState(complianceData);
  const [saveButtonAlwaysEnable, setSaveButtonAlwaysEnable] = useState(false);

  useEffect(() => {
    if (complianceData !== initialComplianceData) {
      setSaveButtonAlwaysEnable(true); // Activez toujours le bouton si complianceData a changé par rapport à la valeur initiale
    } else {
      setSaveButtonAlwaysEnable(false); // Sinon, ne l'activez pas toujours
    }
  }, [complianceData, initialComplianceData]);

  const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton alwaysEnable={saveButtonAlwaysEnable} />
    </Toolbar>
  );

  return (
      <Edit mutationOptions={{ onSuccess }}  mutationMode="pessimistic" {...record}>
        <SimpleForm sanitizeEmptyValues toolbar={<CustomToolbar />}>
          <h1 className={"titleName"}>{record !== undefined ? 'Conformité Marque' : ''}</h1>
          <Grid container>
          <Grid item xs={6}>
          <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
          <SelectInput
            source="role"
            choices={choicesUser}
            optionValue="role"
            optionText="name"
            label="Rôles de l'utilisateur"
            onChange={(e) => handleRoleChange(e.target.value)}
            fullWidth
          />

          </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={6}>
                {selectedRole &&
                    <AutocompleteInput
                        label="Utilisateur"
                        source="user"
                        choices={userChoices}
                        fullWidth
                    />
                }
            </Grid>
        </Grid>

        {hasShownComplianceForm && <ComplianceFormEdit key={reRenderKey} actionsData={transformedData} onUpdateData={setComplianceData} />}
        <Grid container>
          <Grid item xs={4}>
            <TextInput label="Commentaire global" source="note" fullWidth/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <BooleanInput label="Conformité" source="compliance" validate={required()} />
          </Grid>
        </Grid>
        </SimpleForm>
      </Edit>
  );
}
export default BrandComplianceEdit;
