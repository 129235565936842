import * as React from 'react';
import { Create, useRecordContext , useDataProvider, FormDataConsumer, useListController, useRedirect, useGetOne, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { RichTextInput } from 'ra-input-rich-text';
import 'moment/locale/fr';


const LevelIndicatorCreate = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 1000 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
        {
          id: campaign.id,
          name: startDate+" / "+endDate
        };


    });
  }

  const onSuccess = () => {
    redirect({
      pathname: '/level_indicators',
    });
  };
  let current = useListController();
  let record = useRecordContext();
  const dataProvider = useDataProvider();
  const [choicesLevel, setChoicesLevel] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  // React.useEffect(() => {
  //     dataProvider.getList('level_indicators', { pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'ASC' } })
  //         .then(({ data }) => {
  //             setChoicesLevel(data);
  //             setLoading(false);
  //         })
  //         .catch(err => {
  //             setError(err);
  //             setLoading(false);
  //         });
  // }, [dataProvider]);

  // if (loading) return <span>Loading...</span>;
  // if (error) return <span>Error: {error.message}</span>;
  // const transformedChoices = choicesLevel.map(item => ({
  //   id: item.technicalLevel, 
  //   name: item.technicalLevel.toString()
  // }));
  return(
    <Create  mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleForm sanitizeEmptyValues>
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''}</h1>
        <Grid container>
          <Grid item xs={6}>
            <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
          {/* <FormDataConsumer>
              {({ formData, ...rest }) => {
                const campaignChosen = formData.campaign;
                const filteredChoices = choicesLevel
                  .filter(item => item.campaign === `${campaignChosen}`)
                  .map(item => ({
                    id: item.technicalLevel, 
                    name: item.technicalLevel.toString()
                  }));
                return (
                  <Grid container>
                    <Grid item xs={6} className={"itemForm1"}>
                      <SelectInput 
                        source="technicalLevel" 
                        label="Niveau technique" 
                        choices={filteredChoices} 
                        validate={[required()]} 
                        fullWidth 
                        {...rest}
                      />
                    </Grid>
                  </Grid>
                );
              }}
            </FormDataConsumer> */}
              <SelectInput source="technicalLevel" label="Niveau technique" choices={[
                { id: 1, name: '1' },
                { id: 2, name: '2' },
                { id: 3, name: '3' },
              ]} fullWidth/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="brutYield" label="Rendement brut (en kg/ha)" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="fishStocking" label="Empoissonnage (en kg/ha)" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="netYield" label="Rendement net (en kg/ha)" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="growthCoefficient" label="Coefficient d'accroissement" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="profits" label="Recettes (en € HT/ha)" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="averageSellingPrice" label="Prix moyen de vente (en € HT/ha)" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="charges" label="Charges (en €/ha)" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={"itemForm1"}>
            <NumberInput source="brutMargins" label="Marge brute (en € HT/ha)" fullWidth />
          </Grid>
        </Grid>

      </SimpleForm>
    </Create>
  );
}


export default LevelIndicatorCreate;
