// import { Navigate, Route } from "react-router-dom";
import {
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider
    // useIntrospection,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import { entrypoint } from "../config";

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

// const RedirectToLogin = () => {
//     const introspect = useIntrospection();
//     if (localStorage.getItem("token")) {
//         introspect();
//         return <></>;
//     }
//     return <Navigate to="/login" replace />;
// }
const apiDocumentationParser = async () => {
    try {
        console.log(getHeaders);
        return await parseHydraDocumentation(entrypoint, { headers: getHeaders });
    } catch (result) {
        console.log(result);

        const { api, response, status } = result;
        if (status !== 401 || !response) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        return {
            api,
            response,
            status
            // customRoutes: [
            //     <Route key="/" path="/" element={<RedirectToLogin />} />
            // ],
        };
    }
};

export const dataProvider = baseHydraDataProvider({
    entrypoint,
    httpClient: fetchHydra,
    apiDocumentationParser,
    useEmbedded: true,
});

