import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TextField } from '@mui/material';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { entrypoint } from "../../config/index.js";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';


function GrantFormCreate({ role, onUpdateData }) {
    const [actions, setActions] = useState([]);
    const [expanded, setExpanded] = useState({});
    const token = localStorage.getItem('token');
    const handleActionChange = (actionId, field, value) => {
        const updatedActions = [...actions];
        const actionIndex = updatedActions.findIndex(action => action.id === actionId);
    
            if (actionIndex !== -1) {
                updatedActions[actionIndex][field] = value;
                if (updatedActions[actionIndex].parent_id != null) {
                    const parentIndex = updatedActions.findIndex(action => action.id === updatedActions[actionIndex].parent_id);
                    if (parentIndex !== -1) {
                        const allSubActionsChecked = updatedActions.filter(action => action.parent_id === updatedActions[actionIndex].parent_id).every(action => action.grantBoolean);
                        updatedActions[parentIndex].grantBoolean = allSubActionsChecked;
                    }
                }
                if (updatedActions[actionIndex].parent_id == null && updatedActions[actionIndex].grantBoolean !== value) {
                    updatedActions.forEach((action, idx) => {
                        if (action.parent_id === actionId) {
                            updatedActions[idx].grantBoolean = value;
                        }
                    });
                }
            }
            setActions(updatedActions);
            onUpdateData(updatedActions.map(({ action, ...rest }) => rest));
    };
    
    const handleToggle = (parentId) => {
        setExpanded(prevExpanded => ({
            ...prevExpanded,
            [parentId]: !prevExpanded[parentId]
        }));
    };
    const mainActions = actions.filter(action => action.parent_id == null);
    const subActions = actions.filter(action => action.parent_id != null);

    useEffect(() => {
        fetch(`${entrypoint}/grantrequest/grant-form-action/${role}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => setActions(data.actions))
        .catch(error => console.error('There was a problem with the fetch operation:', error.message));
    }, [role, token]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [currentActionIndex, setCurrentActionIndex] = useState(null);

    const handleOpenDialog = (index, content) => {
        setDialogContent(content);
        setCurrentActionIndex(index);
        setOpenDialog(true);
    };

    const handleValidateAndClose = () => {
        if (currentActionIndex !== null) {
            handleActionChange(currentActionIndex, 'comment', dialogContent);
        }
        setOpenDialog(false);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                PaperProps={{ style: { minWidth: '300px' } }} 
            >
                <DialogTitle>{"Contenu"}</DialogTitle>
                <DialogContent>
                    <TextField 
                        value={dialogContent}
                        onChange={(e) => setDialogContent(e.target.value)}
                        fullWidth 
                        multiline
                        variant="outlined"
                        rows={4}
                        InputProps={{
                            inputProps: {
                                style: {
                                    resize: 'both',
                                    overflow: 'auto',
                                    maxHeight: '400px'
                                }
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleValidateAndClose} color="primary">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
    
            <Table className="compliance-table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '500px' }}>Étapes</TableCell>
                        <TableCell style={{ textAlign: 'center', fontWeight:'bold' }}>Cocher si étape terminée</TableCell>
                        <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Commentaires</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {mainActions.map((action, index) => (
                    <React.Fragment key={`main-action-${action.id}`}>
                        <TableRow>
                            <TableCell onClick={() => handleToggle(action.id)}>{action.action}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                            <Checkbox
                                color="primary"
                                checked={action.grantBoolean === true}
                                onChange={(e) => handleActionChange(action.id, 'grantBoolean', e.target.checked)}
                                // disabled={!action.grantBoolean && subActions.some(sub => sub.parent_id === action.id && !sub.grantBoolean)}
                            />
                                </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TextField 
                                    placeholder="Entrer vos commentaires" 
                                    variant="outlined" 
                                    size="small" 
                                    fullWidth 
                                    style={{ fontSize: '12px' }}
                                    value={action.comment}
                                    onChange={(e) => handleActionChange(action.id, 'comment', e.target.value)}
                                    InputProps={{ 
                                        style: { fontSize: '12px' },
                                        endAdornment: (
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleOpenDialog(action.id, action.comment)}
                                            >
                                                <VisibilityIcon fontSize="small" />
                                            </IconButton>
                                        )
                                    }} 
                                />
                            </TableCell>
                        </TableRow>
                        {subActions.filter(subAction => subAction.parent_id === action.id).map((subAction, subIndex) => (
                            <TableRow key={`sub-action-${subAction.id}`} style={{backgroundColor: '#f4f4f4'}}>
                                    <TableCell style={{ paddingLeft: 40 }}>
                                        <SubdirectoryArrowRightIcon style={{marginTop:-8}} />
                                        {subAction.action}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                    <Checkbox 
                                        color="primary" 
                                        checked={subAction.grantBoolean === true}
                                        onChange={(e) => handleActionChange(subAction.id, 'grantBoolean', e.target.checked)}
                                    />
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <TextField 
                                            placeholder="Entrer vos commentaires" 
                                            variant="outlined" 
                                            size="small" 
                                            fullWidth 
                                            style={{ fontSize: '12px' }}
                                            value={subAction.comment}
                                            onChange={(e) => handleActionChange(subAction.id, 'comment', e.target.value)}
                                            InputProps={{ 
                                                style: { fontSize: '12px' },
                                                endAdornment: (
                                                    <IconButton 
                                                        size="small" 
                                                        onClick={() => handleOpenDialog(subAction.id, subAction.comment)}
                                                    >
                                                        <VisibilityIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                            }} 
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </>
    );
    
}

export default GrantFormCreate;
