import * as React from 'react';
import { Create, useListController, useRedirect, useGetOne, useGetList, SimpleForm, DateTimeInput, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';
import { useLocation } from 'react-router-dom';

const MaintenanceWorkCreate = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: {page: 1, perPage: 1000},
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };
    });
  }
  const queryParameters = new URLSearchParams(window.location.hash.split('?')[1])
  const id = queryParameters.get("id")
  const waterBody = useGetOne('water_bodies', {id: id});
  const onSuccess = () => {
    const searchParams = new URLSearchParams(location.search);
    const isBasin = searchParams.get('isBasin') === 'true';
    const basinParam = isBasin ? '&isBasin=true' : '';
    redirect({
      pathname: '/action_maintenance_works',
      search: `filter=${JSON.stringify({ waterBody: id })}${basinParam}`,
    });
  };
  let current = useListController();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isBasin = searchParams.get('isBasin') === 'true';
  return (
      <Create mutationOptions={{ onSuccess }} mutationMode="pessimistic" sanitizeEmptyValues>
        <SimpleForm>
          <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />

            </Grid>
          </Grid>
          {isBasin && (
          <Grid container>
            <Grid item xs={6}>
              <SelectInput
                source="affiliation"
                label="Affiliation de l'action"
                choices={[
                  { id: 'Production estivale 1', name: 'Production estivale 1' },
                  { id: 'Production estivale 2', name: 'Production estivale 2' },
                  { id: 'Stockage hivernal 1', name: 'Stockage hivernal 1' },
                  { id: 'Stockage hivernal 2', name: 'Stockage hivernal 2' }
                ]}
                validate={[required()]}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
          <Grid container>
            <Grid item xs={3}>
              <DateInput source="date" label="Date" validate={[required()]} fullWidth/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <TextInput source="workDone" label="Nature de la charge" validate={[required()]} fullWidth/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <NumberInput source="estimatedCost" label="Coût estimé (en € HT)"  fullWidth/>
            </Grid>
          </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>



          {waterBody.data !== undefined ? <TextInput source="waterBody" className={"hidden"} defaultValue={waterBody.data.id} type={"hidden"}/> : ''}


        </SimpleForm>
      </Create>
  );
}

export default MaintenanceWorkCreate;
