import React, {useEffect, useState} from 'react';
import { Edit,useGetOne,useUpdateMany, useListController,FormDataConsumer, useRedirect, useEditController, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid, Typography} from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'



const FishPriceEdit = () => {
  const redirect = useRedirect();
  let current = useListController();
  const [idsOfActionFish, setIdsOfActionFish] = useState([]);
  const [idsOfActionFishStockings, setIdsOfActionFishStockings] = useState([]);


  const { data } = useGetList('campaigns', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: 5 } });
  const { record, isLoading } = useEditController();
  const [currentCampaign, setCurrentCampaign] = useState(record.campaign);
  const actionFishDataReq = useGetList('action_fishes', { filter: { fishPrice: record.id }, pagination: { page: 1, perPage: 100 } });
  const actionFishStockingDataReq = useGetList('action_fish_stockings', { filter: { fishPrice: record.id }, pagination: { page: 1, perPage: 100 } });
  // const fishPriceDataReq = useGetOne('fish_prices', { id: record.id })
  const [updateMany] = useUpdateMany('action_fish_stockings', { ids: [], data: { fishPrice: null } });
  const [updateManyFish] = useUpdateMany('action_fishes', { ids: [], data: { fishPrice: null } });
  useEffect(() => {
      if (!record || !actionFishDataReq.data || !actionFishStockingDataReq.data) return;

      let filteredActionFish = [];
      let filteredActionFishStocking = [];
      if (actionFishDataReq.data) {
          filteredActionFish = actionFishDataReq.data.filter(item => item.campaign !== currentCampaign);
      }
      if (actionFishStockingDataReq.data) {
          filteredActionFishStocking = actionFishStockingDataReq.data.filter(item => item.campaign !== currentCampaign);
      }
          
      setIdsOfActionFish(filteredActionFish.map(item => item.id));
      setIdsOfActionFishStockings(filteredActionFishStocking.map(item => item.id));
      
  }, [currentCampaign, record, actionFishDataReq.data, actionFishStockingDataReq.data]);

  if (isLoading || actionFishDataReq.loading || actionFishStockingDataReq.loading) {
      return <div>Loading...</div>;
  }
  
    

  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
          {
            id: campaign.id,
            name: startDate+" / "+endDate
          };


    });
  }
  
  const onSuccess = () => {
    updateMany('action_fish_stockings', { ids: idsOfActionFishStockings, data: { fishPrice: null } });
    updateManyFish('action_fishes', { ids: idsOfActionFish, data: { fishPrice: null } });
    redirect({
      pathname: '/fish_prices',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}`,
    });
  };
  function GetNameSpieces(id) {
    const  spieciesName = useGetOne('specie_fishes', { id: id });
    if(spieciesName.data)  {
      return spieciesName.data.name;
    } else {
      return spieciesName.data;
    }
  }
  function GetDefaultId(id) {
    const { data } = useGetList('specie_fishes/uniq');
    console.log(data);
    if (data !== undefined) {
      const test = data.filter(({name}) => name === GetNameSpieces(id));
    }


  }
  const formatSpecieFish = (record) => {
    if (!record.stage) {
      record.stage = "Saisir le stade de l'espèce";
    }
    return record.stage;
  };

  const firstChoice = (formData) => {
    const datas = GetDefaultId(formData.specieFish)
    console.log("test",datas);


  };
  

  return(
      <Edit mutationOptions={{ onSuccess }}  mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues>
        <Typography variant="h4" gutterBottom>{current !== undefined ? current.defaultTitle : ''}</Typography>
          <Grid container>
            <Grid item xs={6}>
              <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth onChange={(e) => setCurrentCampaign(e.target.value)}/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput source="specieFish"  reference="specie_fishes/uniq">
                <SelectInput optionText="name" label="Espèce" validate={[required()]} fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.specieFish &&
              <Grid container>
                <Grid item xs={6}>
                  <ReferenceInput source="specieFish" sort={{ field: 'stage', order: 'DESC' }} reference={"specie_fishes?name="+GetNameSpieces(formData.specieFish)}>
                    <SelectInput optionText={formatSpecieFish} label="Stade espèce" validate={[required()]} fullWidth />
                  </ReferenceInput>
                </Grid>
              </Grid>
            }
          </FormDataConsumer>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="disposalFeeCollector" label="Vendu au collecteur (en € HT/kg)"  validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="purchaseRateCollector" label="Acheté au collecteur (en € HT/kg)"  validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={"itemForm1"}>
              <NumberInput source="costPrice" label="Empoissonnage autoproduit ou gardé tarif (€ HT/kg)"  validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <TextInput source="waterBody" className={"hidden"} type={"hidden"}/>

        </SimpleForm>
      </Edit>
  );
}


export default FishPriceEdit;
