import WaterBodiesCreate from './WaterBodiesCreate';
import WaterBodiesEdit from './WaterBodiesEdit';
import WaterBodiesList from './WaterBodiesList';
import WaterBodiesShow from './WaterBodiesShow';

export default {
  list: WaterBodiesList,
  create: WaterBodiesCreate,
  edit: WaterBodiesEdit,
  show: WaterBodiesShow,
};
