import ObservationCreate from './ObservationCreate';
import ObservationEdit from './ObservationEdit';
import ObservationList from './ObservationList';
import ObservationShow from './ObservationShow';

export default {
  list: ObservationList,
  create: ObservationCreate,
  edit: ObservationEdit,
  show: ObservationShow,
};
