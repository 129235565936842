import * as React from "react";
import { Show, SimpleShowLayout, useRecordContext, FormDataConsumer, useShowController, useGetMany, useGetList, TextField, DateField, RichTextField, ReferenceField, FunctionField, BooleanField } from 'react-admin';
import moment from 'moment';
import 'moment/locale/fr';
import GrantFormShow from "../Tools/GrantForm/GrantFormShow";

function formatRole(role) {
    const withoutPrefix = role.replace('ROLE_', '');

    switch (withoutPrefix) {
        case 'FISHFARMER':
            return 'Pisciculteur';
        case 'TRANSFORMER':
            return 'Transformateur';
        case 'COLLECTOR':
            return 'Collecteur';
        case 'ENCLOSER':
            return 'Ecloseur';
        default:
            return withoutPrefix;
    }
}

const MultilineTextField = ({ source, label }) => {
    const record = useRecordContext();
    const value = record ? record[source] : '';

    return (
        <div style={{ margin: '1em 0' }}>
            <textarea
                value={value}
                readOnly
                style={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '14px',
                    resize: 'none',
                    overflow: 'auto',
                    minHeight: '50px',
                    lineHeight: '1.5',
                    color: 'rgba(0, 0, 0, 0.87)',
                }}
            />
        </div>
    );
};

const GrantRequestShow = (props) => {
    const controllerProps = useShowController(props);

    const userId = controllerProps.record?.user;
    const grantYearId = controllerProps.record?.grantYear;
    const grantRequestId = controllerProps.record?.id;  // Ajout de grantRequestId

    const { data: grantContents } = useGetList('grant_form_contents', {
        sort: { field: 'grant_form_action_id', order: 'ASC' },
        filter: { user: userId, grantYear: grantYearId, grantRequest: grantRequestId }  // Ajout du filtre grantRequest
    });

    const actionIds = [...new Set(grantContents?.map(content => content.grantFormAction))];

    const { data: grantActions } = useGetList('grant_form_actions', {
        filters: { id_in: actionIds.join(",") }
    });

    const transformedData = grantContents?.map(content => {
        const action = grantActions?.find(action => action.id === content.grantFormAction);
        return {
            ...content,
            grantFormAction: {
                id: action?.id,
                action: action?.action,
                parent_id: action?.parentId
            }
        };
    });

    return (
        <Show actions={false} {...props} {...controllerProps}>
            <SimpleShowLayout>
                <TextField source="grantYear" label="Année de subvention" />
                <FunctionField
                    source="role"
                    label="Rôle"
                    render={record => formatRole(record.role)}
                />
                <ReferenceField label="Nom/Prénom/Organisme" source="user" reference="users" link={false}>
                    <FunctionField render={record => {
                        if (record && record.roles) {
                            if (record.roles?.includes("ROLE_FISHFARMER")) {
                                return `${record.firstName} ${record.lastName}`;
                            }
                            if (record.roles?.includes("ROLE_COLLECTOR")) {
                                return `${record.firstName} ${record.lastName} ${record.structureName ? ' / ' + record.structureName : ''}`;
                            }
                            if (record.roles?.includes("ROLE_TRANSFORMER")) {
                                return record.structureName || '';
                            }
                        }
                        return '';
                    }} emptyText="missing data" />
                </ReferenceField>
                {controllerProps.record?.directoryType && <MultilineTextField source="directoryType" label="Type de sous-dossier" />}
                {<GrantFormShow actions={transformedData} grantYear={controllerProps.record?.grantYear} />}
            </SimpleShowLayout>
        </Show>
    );
};

export default GrantRequestShow;
