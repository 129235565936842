import { useEffect } from 'react';

export function useHideModifierLabel() {
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for(let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const headers = document.querySelectorAll('.MuiTableRow-root.MuiTableRow-head .MuiTableCell-root > span');
          headers.forEach(header => {
            if (header.textContent === 'MODIFIER') {
              header.classList.add('hide-label');
            }
          });
        }
      }
    });

    // Observez les changements dans la configuration des 'childList' et des sous-arbres
    observer.observe(document.body, { childList: true, subtree: true });

    // Assurez-vous de déconnecter l'observateur lorsque le composant est démonté
    return () => observer.disconnect();
  }, []);
}
