import * as React from 'react';
import {
    BooleanInput,
    Create, ImageField, ImageInput,
    SimpleForm,
    TextInput, useGetOne,useShowController
} from 'react-admin';
import {Grid} from '@mui/material';
import 'moment/locale/fr'
import {RichTextInput} from 'ra-input-rich-text';


const NewsCreate = () => {


    return (
        <Create mutationMode="pessimistic" redirect="list">
            <h1 className={"titleName"}></h1>
            <SimpleForm sanitizeEmptyValues>
                <Grid container>
                    <Grid container className={"containerInput"}>
                        <Grid item md={3} className={"itemForm1"}>
                            <TextInput source="title" label="Titre" fullWidth/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <RichTextInput source="content" label="Contenu"/>
                    </Grid>
                </Grid>
                <Grid container className={"containerInput"}>
                    <Grid item md={3}>
                        <ImageInput source="file" label="Photo" accept="image/*">
                            <ImageField source="src" title="title"/>
                        </ImageInput>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} className={"itemForm1"}>
                        <BooleanInput source="state" label="Actif / Inactif" fullWidth/>
                    </Grid>
                </Grid>

        </SimpleForm>
</Create>
)
    ;
}


export default NewsCreate;
