import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TextField } from '@mui/material';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


function ComplianceFormShow({actions}) {

    const documentProofs = [
        "Présentation du registre d'étang consultation de l'espace adhérent",
        "Entretien visite avec l'exploitant"
    ];
    const mergeRules = [
        { start: 0, count: 5 },
        { start: 5, count: 11 }
    ];

    const getRowSpan = (index) => {
        for (let rule of mergeRules) {
            if (index === rule.start) return rule.count;
            if (index > rule.start && index < rule.start + rule.count) return 0;
        }
        return 1;
    };
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [currentActionIndex, setCurrentActionIndex] = useState(null);
    
    const handleOpenDialog = (index, content) => {
        setDialogContent(content);
        setCurrentActionIndex(index);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    let proofIndex = 0; 
    return (
        <>
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            PaperProps={{ style: { minWidth: '300px' } }} 
        >
            <DialogTitle>{"Contenu"}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>

        <Table className="compliance-table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '500px' }}>Actions</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '150px' }}>Documents preuve</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold' }}>Conforme: O/N</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Points d'amélioration</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Actions à conduire</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {actions ? actions.map((action, index) => {
                    const rowSpan = getRowSpan(index);
                    let proofText = '';
                    if (rowSpan > 0) {
                        proofText = documentProofs[proofIndex] || '';
                        proofIndex++;
                    }
                    return (
                        <TableRow key={index}>
                            <TableCell>{action.complianceFormAction.action}</TableCell>
                            { 
                                rowSpan > 0 && 
                                <TableCell rowSpan={rowSpan} style={{ textAlign: 'center' }}>
                                    {proofText}
                                </TableCell>
                            } 
                            <TableCell style={{ textAlign: 'center' }}>
                                {action.complianceBoolean ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <div style={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    alignItems: 'center', 
                                    whiteSpace: 'nowrap', 
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis', 
                                    width: '100%'
                                }}>
                                    <span>{action.improvementPoints ? action.improvementPoints : ''}</span>
                                    <IconButton size="small" onClick={() => handleOpenDialog(index, action.improvementPoints)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </div>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <div style={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    alignItems: 'center', 
                                    whiteSpace: 'nowrap', 
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis', 
                                    width: '100%'
                                }}>
                                    <span>{action.actionToTake ? action.actionToTake : ''}</span>
                                    <IconButton size="small" onClick={() => handleOpenDialog(index, action.actionToTake)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </div>
                            </TableCell>

                        </TableRow>
                    );
                }): ''}
            </TableBody>
        </Table>
        </>
    );
}

export default ComplianceFormShow;