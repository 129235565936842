import * as React from 'react';
import { Edit, TimeInput, useListController, useRedirect, useGetOne, useEditController, useGetList, SimpleForm, DateTimeInput, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { RichTextInput } from 'ra-input-rich-text';
import { useLocation } from 'react-router-dom';

const AeratorEdit = () => {
  const redirect = useRedirect();
  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: {page: 1, perPage: 1000},
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
      {
        id: campaign.id,
        name: startDate + " / " + endDate
      };
    });
  }
  const { record, isLoading } = useEditController();
  const onSuccess = () => {
    const searchParams = new URLSearchParams(location.search);
    const isBasin = searchParams.get('isBasin') === 'true';
    const basinParam = isBasin ? '&isBasin=true' : '';

    redirect({
      pathname: '/action_aerators',
      search: `filter=${JSON.stringify({ waterBody: record.waterBody })}${isBasin ? '&isBasin=true' : ''}`,
    });
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isBasin = searchParams.get('isBasin') === 'true';

  const waterBody = useGetOne('water_bodies', { id: record !== undefined ? record.waterBody : '' });
  moment.locale('fr');

  let current = useListController();
  return (
    <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic" sanitizeEmptyValues>
      <SimpleForm sanitizeEmptyValues>
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''} - {waterBody.data !== undefined ? waterBody.data.name : ''}</h1>
        <Grid container>
          <Grid item xs={6}>
            <SelectInput source="campaign" choices={choices} label="Campagne" validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        {isBasin && (
          <Grid container>
            <Grid item xs={6}>
              <SelectInput
                source="affiliation"
                label="Affiliation de l'action"
                choices={[
                  { id: 'Production estivale 1', name: 'Production estivale 1' },
                  { id: 'Production estivale 2', name: 'Production estivale 2' },
                  { id: 'Stockage hivernal 1', name: 'Stockage hivernal 1' },
                  { id: 'Stockage hivernal 2', name: 'Stockage hivernal 2' }
                ]}
                validate={[required()]}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={3} className={"itemForm1"}>
            <DateInput source="startDate" label="Date début" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={3} className={"itemForm2"}>
            <TimeInput source="startTime" format={v => moment(v).zone("+02:00").format('HH:mm')} label="Heure début" validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} className={"itemForm1"}>
            <DateInput source="endDate" label="Date fin" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={3} className={"itemForm2"}>
            <TimeInput source="endTime" format={v => moment(v).zone("+02:00").format('HH:mm')} label="Heure fin" validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} className={"itemForm1"}>
            <ReferenceInput source="aerator" reference="aerators">
              <SelectInput optionText="name" label="Type aerateur" validate={[required()]} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} className={"itemForm1"}>
            <NumberInput source="power" label="Puissance (en Kw)" fullWidth />
          </Grid>
          <Grid item xs={3} className={"itemForm2"}>
            <NumberInput source="electricalCost" label="Coût du Kw.h (en € HT)" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextInput source="note" label="Remarque(s)" multiline fullWidth />
          </Grid>
        </Grid>
        <TextInput source="waterBody" className={"hidden"} type={"hidden"} />
      </SimpleForm>
    </Edit>
  );
}

export default AeratorEdit;
