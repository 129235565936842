import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';

const FishingTeamShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="teaser" />
      <RichTextField source="body" />
      <DateField label="Publication date" source="published_at" />
    </SimpleShowLayout>
  </Show>
);
export default FishingTeamShow;
