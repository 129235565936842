import RegulatedAnimalCreate from './RegulatedAnimalCreate';
import RegulatedAnimalEdit from './RegulatedAnimalEdit';
import RegulatedAnimalList from './RegulatedAnimalList';
import RegulatedAnimalShow from './RegulatedAnimalShow';

export default {
  list: RegulatedAnimalList,
  create: RegulatedAnimalCreate,
  edit: RegulatedAnimalEdit,
  show: RegulatedAnimalShow,
};
