import axios from "axios";
import { entrypoint } from "../../config/index.js";

export const axiosInstance = axios.create({
  baseURL: `${entrypoint}/`,
});

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
