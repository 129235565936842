// in src/posts.js
import { List,ReferenceField,useRecordContext,useListContext,useList,FunctionField,useGetOne,useListController,TopToolbar,ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box,  Typography } from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'
import React, { useEffect } from "react";


const Empty = () => {
    let data = useListController();
    return(
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore d'indicateurs niveaux.
            </Typography>
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            <Button href={"#/level_indicators/create"} label="Créer"/>
        </Box>
    );
}

const PostListActions = () => {
    let data = useListController();
    return (
        <TopToolbar>
            <Button href={"#/level_indicators/create"} label="Créer"/>
            <ExportButton />
        </TopToolbar>
    );
}


const LevelIndicatorList = () => {
    let data = useListController();
    return (
    <List actions={<PostListActions />} empty={<Empty />}>
        <h1 className={"titleName"}>{data !== undefined ? data.defaultTitle : ''}</h1>

        <Datagrid>
            <TextField source="originId" label="#" />
            <ReferenceField link={false} label="Campagne" source="campaign" reference="campaigns">
                <FunctionField render={record => record && `${moment(record.startDate).format('YYYY')} / ${moment(record.endDate).format('YYYY')}`} />
            </ReferenceField>
            <TextField source="technicalLevel" label="Niveau technique" fullWidth />
            <TextField source="brutYield" label="Rendement brut (en kg/ha)" />
            <TextField source="fishStocking" label="Empoissonnage (en kg/ha)" />
            <TextField source="netYield" label="Rendement net (en kg/ha)" />
            <TextField source="growthCoefficient" label="Coefficient d'accroissement" />
            <TextField source="profits" label="Recettes (en € HT/ha)" />
            <TextField source="averageSellingPrice" label="Prix moyen de vente (en € HT/ha)" />
            <TextField source="charges" label="Charges (en €/ha)" />
            <TextField source="brutMargins" label="Marge brute (en € HT/ha)" />
            <EditButton label="MODIFIER"/>
        </Datagrid>
  </List>
);
}
export default LevelIndicatorList;
