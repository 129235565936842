import LimingCreate from './LimingCreate';
import LimingEdit from './LimingEdit';
import LimingList from './LimingList';
import LimingShow from './LimingShow';

export default {
  list: LimingList,
  create: LimingCreate,
  edit: LimingEdit,
  show: LimingShow,
};
