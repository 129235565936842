import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDataProvider,usePermissions, Filter, ReferenceArrayInput, useGetIdentity, AutocompleteArrayInput, required, CheckboxGroupInput, Form, FormDataConsumer, useGetList, SelectInput, ReferenceInput } from 'react-admin';
import "../index.css";
import { useForm } from 'react-final-form';
import balanceSheetImg from "../img/MaquetteV3.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import WaterBodyStats from "./WaterBodyStats";
import GlobalStats from "./GlobalStats"
import moment from 'moment';
import 'moment/locale/fr';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import CompareCampaign from "./CompareCampaign";
import CampaignAndWaterBody from "./CampaignAndWaterBody";
import Checked from "./Checked";




const ShowBalanceSheet = () => {
  const [filter, setFilter] = useState(null);
  const [choicesWaterBody, setChoicesWaterBody] = useState([]);
  let [filterUser, setFilterUser] = useState(null);

  const [waterBodyData, setWaterBodyData] = useState(null);
  const [campaignData, setCampaignData] = useState(null);
  const [campaignData2, setCampaignData2] = useState(null);
  const [townData, setTownData] = useState(null);
  const [collectorData, setCollectorData] = useState(null);
  const [compare, setCompare] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [inputWidth, setInputWidth] = useState(800); 
 
  const [userChoices, setUserChoices] = useState([]);

  const isCheckedCompare = isChecked && !compare && !waterBodyData;
  if(isChecked && (compare || waterBodyData)){
    setIsChecked(!isChecked);
    
  }
  const dataProvider = useDataProvider();
  const { data: campaignChoices } = useGetList('campaigns', {
    sort: { field: 'startDate', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
  });

  let sortedCampaignChoices = [];
  if (campaignChoices && Array.isArray(campaignChoices)) {
    sortedCampaignChoices = [...campaignChoices].sort((a, b) => moment.utc(a.startDate).diff(moment.utc(b.startDate)));
  }
  const choices = sortedCampaignChoices
      ? sortedCampaignChoices.map(campaign => {
        moment.locale('fr');
        const startDate = moment(campaign.startDate).format('YYYY');
        const endDate = moment(campaign.endDate).format('YYYY');
        return {
          id: campaign.id,
          name: startDate + ' / ' + endDate,
        };
      })
      : [];

  let lastChoice = choices[choices.length - 1];
  let lastChoice2 = lastChoice;
  let defaultValue1 = lastChoice ? lastChoice.id : null;
  let defaultValue2 = lastChoice2 ? lastChoice2.id : null;
  const [filterCampaign1, setFilterCampaign1] = useState(defaultValue1);
  const [filterCampaign2, setFilterCampaign2] = useState(defaultValue2);


  if (campaignData !== null) {
    lastChoice = null;
  }
  if (campaignData2 !== null) {
    lastChoice2 = null;
  }
  useEffect(() => {
    if (defaultValue1 !== null) {
      setFilterCampaign1(defaultValue1);
    }
  }, [defaultValue1]);

  useEffect(() => {
    if (defaultValue2 !== null) {
      setFilterCampaign2(defaultValue2);
    }
  }, [defaultValue2]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleFilterChange = async (event) => {
    const selectedWaterBodyId = event.target.value;
    setFilter(selectedWaterBodyId);

  };
  
  let [selectedValues, setSelectedValues] = useState(false);

  const handleFilterChangeUser = async (event) => {

    const selectedUserId = parseInt(event.target.value.split("/users/")[1]);
    setFilterUser(selectedUserId);
    setFilter(null); // Assurez-vous que setFilter est défini quelque part
    setSelectedValues(null);
    setChoicesWaterBody([])
    // Obtenez la liste des waterBodies
    const waterBodiesList = await dataProvider.getList('water_bodies', {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 100 },
      filter: { user: event.target.value }
    });
   
    setChoicesWaterBody(waterBodiesList.data);
    const waterBodiesURLs = waterBodiesList.data.map(waterBody => `${waterBody.id}`);
    
    setSelectedValues(null);
    setSelectedValues(waterBodiesURLs);

  };

  const handleFilterChangeCampaign1 = async (event) => {
    const selectedCampaignId1 = event.target.value;
    setFilterCampaign1(selectedCampaignId1);
    if (selectedCampaignId1 !== filterCampaign2 && selectedCampaignId1 !== lastChoice2?.id) {
      setCompare(true);
    } else {
      setCompare(false);
    }
  };

  const handleFilterChangeCampaign2 = async (event) => {
    const selectedCampaignId2 = event.target.value;
    setFilterCampaign2(selectedCampaignId2);
    if (selectedCampaignId2 !== filterCampaign1 && selectedCampaignId2 !== lastChoice?.id) {
      setCompare(true);
    } else {
      setCompare(false);
    }
  };
  useEffect(() => {
    const fetchWaterBodyData = async () => {
      if (filter) {
        try {
          const response = await dataProvider.getOne("water_bodies", {
            id: filter,
          });

          setWaterBodyData(response.data);
          if (response.data.town) {
            const townResponse = await dataProvider.getOne("towns", {
              id: response.data.town,
            });
            setTownData(townResponse.data);
          } else {
            setTownData(null);
          }
          if (response.data.currentCollector) {
            const collectorResponse = await dataProvider.getOne("currentCollector", {
              id: response.data.currentCollector,
            });
            setCollectorData(collectorResponse.data);
          } else {
            setCollectorData(null);
          }
        } catch (error) {
          console.error(error);
          setWaterBodyData(null);
          setTownData(null);
          setCollectorData(null);
        }
      } else {
        setWaterBodyData(null);
        setTownData(null);
        setCollectorData(null);
      }
    };

    fetchWaterBodyData();
  }, [filter, filterUser, dataProvider]);

  useEffect(() => {
    const fetchCampaignData = async () => {
      if (filterCampaign1) {
        try {
          const response = await dataProvider.getOne('campaigns', { id: filterCampaign1 });
          const startDate = moment(response.data.startDate).format('YYYY');
          const endDate = moment(response.data.endDate).format('YYYY');
          const campaignName = `${startDate} / ${endDate}`;

          setCampaignData({ ...response.data, name: campaignName });
        } catch (error) {
          console.error(error);
          setCampaignData(null);
        }
      } else {
        setCampaignData(null);
      }

    };

    fetchCampaignData();
  }, [filterCampaign1, dataProvider]);
  useEffect(() => {
    const fetchCampaignData2 = async () => {
      if (filterCampaign2) {
        try {
          const response = await dataProvider.getOne('campaigns', { id: filterCampaign2 });
          const startDate = moment(response.data.startDate).format('YYYY');
          const endDate = moment(response.data.endDate).format('YYYY');
          const campaignName = `${startDate} / ${endDate}`;

          setCampaignData2({ ...response.data, name: campaignName });
        } catch (error) {
          console.error(error);
          setCampaignData2(null);
        }
      } else {
        setCampaignData2(null);
      }

    };

    fetchCampaignData2();
  }, [filterCampaign2, dataProvider]);


  const filteredChoices2 = useMemo(() => {
    if (filterCampaign1) {
      const selectedCampaign1 = choices.find(choice => choice.id === filterCampaign1);
      if (selectedCampaign1) {
        const selectedYear = parseInt(selectedCampaign1.name.split(" / ")[0]);
        return choices.filter(choice => parseInt(choice.name.split(" / ")[0]) >= selectedYear);
      }
    }

    if (defaultValue1 === defaultValue2) {
      const selectedChoice = choices.find(choice => choice.id === defaultValue2);
      return selectedChoice ? [selectedChoice] : [];
    }

    return choices;
  }, [filterCampaign1, choices, defaultValue1, defaultValue2]);

  const filteredChoices1 = useMemo(() => {
    if (filterCampaign2) {
      const selectedCampaign2 = choices.find(choice => choice.id === filterCampaign2);
      if (selectedCampaign2) {
        const selectedYear = parseInt(selectedCampaign2.name.split(" / ")[0]);
        return choices.filter(choice => parseInt(choice.name.split(" / ")[0]) <= selectedYear);
      }
    }
    return choices;
  }, [filterCampaign2, choices]);

  const handleInputChange = newValue => {
    setSelectedValues(newValue);
    console.log(newValue);
    const newWidth = newValue.length * 50; 
    setInputWidth(200 + newWidth);
    
  };
  const handleSelectChange = (event) => {
    event.stopPropagation();
  };
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  
    const onlyUser = async (userId) => {
      const waterBodiesList = await dataProvider.getList('water_bodies', {
        sort: { field: 'id', order: 'ASC' },
        pagination: { page: 1, perPage: 100 },
        filter: { user: userId } 
      });
      setChoicesWaterBody(waterBodiesList.data);
      console.log(waterBodiesList);
      setChoicesWaterBody(waterBodiesList.data);
      const waterBodiesURLs = waterBodiesList.data.map(waterBody => `${waterBody.id}`);
      
      setSelectedValues(null);
      setSelectedValues(waterBodiesURLs);
      console.log(selectedValues);
    }
    const hasRun = useRef(false);

  if ((permissions !== undefined && !permissions.includes('ROLE_ADMIN')) && !hasRun.current) {
    let onlyUserId = `/users/${identity.id}`;
    onlyUser(onlyUserId);
    hasRun.current = true;
  }
  const validateSelectInput = [required()];
  const prepareUserChoices = async (dataProvider) => {
    const { data: users } = await dataProvider.getList('users', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'lastName', order: 'ASC' },
    });
  
    return users.map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName} ${user.structureName ? '/ ' + user.structureName : ''}`
    }));
  };
  useEffect(() => {
    if (permissions.includes('ROLE_ADMIN')) {
        prepareUserChoices(dataProvider).then(setUserChoices);
    }
}, [dataProvider, permissions]);
  console.log(filterUser);
  return (
      <>

        <div className="background-container container-fluid">
          <div className="chart-container flex-wrap" style={{ alignItems: 'center' }}>
            <p className="bodyTitle">BILAN D'EXPLOITATION
              {waterBodyData ? ' - ' + waterBodyData.name : ''}
              {campaignData ? ' - Campagne ' + campaignData.name : ' - Campagne ' + (lastChoice ? lastChoice?.name : '')}
              {campaignData2 && campaignData2?.name !== campaignData?.name ? ' - Campagne ' + campaignData2.name : (lastChoice2 !== lastChoice && lastChoice2?.name !== campaignData?.name ? ' - Campagne ' + lastChoice2?.name : '')}

            </p>
            <Form className="filter-container flex-grow-1">

              <div className="filter">
                <FontAwesomeIcon icon={faFilter} className="filter-icon" />

                {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?
                    // <ReferenceInput source="user" reference="users" style={{ marginRight : '10px'}}>
                    //   <FormDataConsumer>
                    //     {({ formData, ...rest }) => (
                    //         <SelectInput
                    //             className="autocomplete-input"
                    //             optionText="username"
                    //             label={"Utilisateurs"}
                                
                    //             emptyText="Tous les utilisateurs"
                    //             fullWidth
                    //             onChange={handleFilterChangeUser}
                    //             defaultValue={filter}
                    //             {...rest}
                    //         />
                    //     )}
                    //   </FormDataConsumer>
                    // </ReferenceInput>
                      <SelectInput 
                        className="autocomplete-input"
                        source="user" 
                        choices={userChoices}
                        optionValue="id" 
                        optionText="name" 
                        emptyText="Tous les utilisateurs"
                        label="Nom/Prénom/Organisme" 
                        onChange={handleFilterChangeUser}
                        defaultValue={filter}
                        fullWidth 
                        alwaysOn 
                      />
                    : ''}
                <span dangerouslySetInnerHTML={{__html: '&nbsp; &nbsp;'}} />
                {(permissions !== undefined && !permissions.includes('ROLE_ADMIN')) ||  (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUser !== null) && !(!waterBodyData && !compare && isChecked) ?


                    <FormDataConsumer>
                      {({ formData, ...rest }) => (
                          <SelectInput
                              style={{ leftRight : '10px'}}
                              className="autocomplete-input"
                              optionText="name"
                              source="waterBody"
                              label={!waterBodyData ? "Tout les étangs" : "Étang"}
                              fullWidth
                              defaultValue={filter}
                              choices={choicesWaterBody}
                              onChange={handleFilterChange}
                              {...rest}
                          />
                      )}
                    </FormDataConsumer>


                    : ''}
                


              </div>


              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#4e73df', height: 50, width: 'auto', border: 'none', margin: '0 30px 0 20px' }}>
                <span style={{ marginRight: 20 }}>{campaignData ? 'Campagne ' + campaignData.name : 'Campagne ' + (lastChoice ? lastChoice?.name : '')}
                  {campaignData2 && campaignData2?.name !== campaignData?.name ? ' - Campagne ' + campaignData2.name : (lastChoice2 !== lastChoice && lastChoice2?.name !== campaignData?.name ? ' - Campagne ' + lastChoice2?.name : '')}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu" style={{ padding: 20, border: '1px solid #f2f2f2' }}>
                  <div className="filterCampaign">
                    <FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: 0 }} />
                    <FormDataConsumer>
                      {({ formData, ...rest }) => (
                          <>
                            <span style={{ marginRight: 5 }}>De</span>
                            <SelectInput
                                source="campaign1"
                                choices={filteredChoices1}
                                className="autocomplete-input"
                                optionText="name"
                                label="Campagne"
                                defaultValue={defaultValue1}
                                onChange={handleFilterChangeCampaign1}
                                onClick={handleSelectChange}
                                validate={validateSelectInput}
                                {...rest}
                            />
                          </>
                      )}
                    </FormDataConsumer>

                    <FormDataConsumer>
                      {({ formData, ...rest }) => (
                          <>
                            <span style={{ marginRight: 5 }}>À</span>
                            <SelectInput
                                source="campaign2"
                                choices={filteredChoices2}
                                className="autocomplete-input"
                                optionText="name"
                                label="Campagne"
                                defaultValue={defaultValue2}
                                onChange={handleFilterChangeCampaign2}
                                onClick={handleSelectChange}
                                validate={validateSelectInput}
                                {...rest}
                            />
                          </>
                      )}
                    </FormDataConsumer>

                  </div>
                  <div className="filterCampaign" style={{ marginTop: 30 }}>

                    <input
                        className="form-check-input"
                        style={{ width: 25, height: 25, margin: 0, backgroundColor: (compare || waterBodyData) ? "lightgray" : "", pointerEvents: (compare || waterBodyData) ? "none" : "auto", }}
                        type="checkbox"
                        id="compareCheckbox"
                        checked={isCheckedCompare}
                        onChange={handleCheckboxChange}

                    />
                    <label className="form-check-label" htmlFor="compareCheckbox" style={{ color: (compare || waterBodyData) ? "lightgray" : "" }}>
                      Comparer avec
                    </label>

                    <FormDataConsumer>
                      {({ formData, ...rest }) => (
                          <>
                            <SelectInput
                                source="campaign3"
                                choices={[]}
                                style={{ width: 300 }}
                                className="autocomplete-input"
                                optionText="name"
                                label="Groupe d'exploitant du même niveau"
                                onChange={handleFilterChangeCampaign2}
                                onClick={handleSelectChange}
                                disabled={compare} // Ajout de la propriété disabled
                                {...rest}
                            />
                          </>
                      )}
                    </FormDataConsumer>
                  </div>

                </Dropdown.Menu>
              </Dropdown>
              
            </Form>
          </div>
          <Form>
          {(!waterBodyData && !compare && isChecked && selectedValues) ?
            <ReferenceArrayInput source={"water_bodies?user="+filterUser} reference={"water_bodies?user="+filterUser}>
              <AutocompleteArrayInput
                className="RaAutocompleteInput-textField"
                style={{ width: `${inputWidth}px` }}
                optionText='name'
                label={"Étangs à afficher"}
                defaultValue={selectedValues}
                onChange={handleInputChange}
                noOptionsText=""
              />
            </ReferenceArrayInput>
            : ''}
          </Form>
        </div>
        {(waterBodyData && !compare && (defaultValue1 || filterCampaign1)) ?

            <WaterBodyStats
                filterCampaign1={filterCampaign1}
                waterBodyData={waterBodyData}
                townData={townData}
                collectorData={collectorData}
                filterUser={filterUser}
            />
            : ''
        }

        {(!waterBodyData && !compare && !isChecked && filterCampaign1)?
            <GlobalStats filterCampaign1={filterCampaign1} filterUser={filterUser} />
            : ''
        }

        {(!waterBodyData && compare && filterCampaign1 && filterCampaign2) ?
            <CompareCampaign
                filterUser={filterUser}
                filterCampaign1={filterCampaign1}
                filterCampaign2={filterCampaign2}
            />
            : ''
        }
        {(waterBodyData && compare && filterCampaign1 && filterCampaign2) ?
            <CampaignAndWaterBody
                waterBodyData={waterBodyData}
                townData={townData}
                collectorData={collectorData}
                filterUser={filterUser}
                filterCampaign1={filterCampaign1}
                filterCampaign2={filterCampaign2} />
            : ''
        }
        {!waterBodyData && !compare && isChecked && filteredChoices1 && filterCampaign1?
            <Checked waterBodyList={selectedValues} filterUser={filterUser} filterCampaign1={filterCampaign1}/>
            : ''
        }

      </>
  );
}

export default ShowBalanceSheet;
