import React, { useState, useEffect } from "react";
import {Create,useRecordContext,useDataProvider,FormDataConsumer,useListController,useRedirect,useGetOne, SimpleForm,useGetList,TextInput,DateInput,required,BooleanInput, NumberInput,SelectInput,ReferenceInput,FunctionField } from 'react-admin';
import { Grid, Typography, Button} from '@mui/material';
import { Link } from 'react-router-dom';
import 'moment/locale/fr';
import { entrypoint } from "../config/index.js";
import GrantFormCreate from "../Tools/GrantForm/GrantFormCreate.js";


const GrantRequestCreate = () => {
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedRoleFormatted, setSelectedRoleFormatted] = useState(null);
    const [selectedGrantYear, setSelectedGrantYear] = useState(null);
    const handleUserChange = async (userId) => {
      const selectedUser = userChoices.find(user => user.id === userId);
      if (selectedUser) {
        setSelectedUserId(userId);
        setSelectedRoleFormatted(formatRole(selectedUser.roles));
      }
    };
  const handleGrantYearChange = (grantYear) => {
    setSelectedGrantYear(grantYear);
    
};
const defaultGrantData = [
  { "id": 66, "parent_id": null },
  { "id": 67, "parent_id": null },
  { "id": 68, "parent_id": 67 },
  { "id": 69, "parent_id": 67 },
  { "id": 70, "parent_id": 67 },
  { "id": 71, "parent_id": 67 },
  { "id": 72, "parent_id": 67 },
  { "id": 73, "parent_id": 67 },
  { "id": 74, "parent_id": 67 },
  { "id": 75, "parent_id": 67 },
  { "id": 76, "parent_id": 67 },
  { "id": 77, "parent_id": null },
  { "id": 78, "parent_id": null },
  { "id": 79, "parent_id": null },
  { "id": 80, "parent_id": null },
  { "id": 81, "parent_id": null },
  { "id": 82, "parent_id": null },
  { "id": 83, "parent_id": null },
  { "id": 84, "parent_id": null },
  { "id": 85, "parent_id": null }
];
const [grantData, setGrantData] = useState(null);

  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };
  const prepareYearChoices = () => {
    const currentYear = getCurrentYear()-5;
    const choices = [];

    for (let i = 0; i <= 10; i++) {
      const year = currentYear + i;
      choices.push({ id: year.toString(), name: year.toString() });
    }
    return choices;
  };
  const YearSelectInput = props => {
    return <SelectInput {...props} choices={prepareYearChoices()} />;
  };

  
  
  
  
const choicesUser = [
    { role: "ROLE_FISHFARMER", name: formatRole("ROLE_FISHFARMER") },
    { role: "ROLE_TRANSFORMER", name: formatRole("ROLE_TRANSFORMER") },
    { role: "ROLE_COLLECTOR", name: formatRole("ROLE_COLLECTOR") },
    { role: "ROLE_ENCLOSER", name: formatRole("ROLE_ENCLOSER") },
  ];

// Définir la hiérarchie des rôles
const roleHierarchy = [
  'ROLE_FISHFARMER', // Pisciculteur
  'ROLE_ENCLOSER',   // Ecloseur
  'ROLE_COLLECTOR',  // Collecteur
  'ROLE_TRANSFORMER',// Transformateur
  'ROLE_OTHERS',     // Autres
  'ROLE_RESTORER',   // Restaurateur
  'ROLE_VOLUNTEER',   // Bénévole
  'ROLE_USER',
  'ROLE_ADMIN'
];

function getHighestRole(roles) {
  // Trier les rôles du tableau selon la hiérarchie définie
  const sortedRoles = roles.sort((a, b) => {
    return roleHierarchy.indexOf(a) - roleHierarchy.indexOf(b);
  });
  
  // Retourner le rôle le plus élevé dans la hiérarchie
  return sortedRoles[0];
}

function formatRole(role) {
  // Si role est un tableau, trouver le rôle le plus élevé
  if (Array.isArray(role)) {
    role = getHighestRole(role);
  }
  return formatSingleRole(role);
}

// Fonction auxiliaire pour formater un seul rôle
function formatSingleRole(singleRole) {
  const withoutPrefix = singleRole.replace('ROLE_', '');

  switch (withoutPrefix) {
    case 'FISHFARMER':
      return 'Pisciculteur';
    case 'ENCLOSER':
      return 'Ecloseur';
    case 'COLLECTOR':
      return 'Collecteur';
    case 'TRANSFORMER':
      return 'Transformateur';
    case 'OTHERS':
      return 'Autres';
    case 'RESTORER':
      return 'Restaurateur';
    case 'VOLUNTEER':
      return 'Bénévole';
    default:
      return withoutPrefix;
  }
}



  const { data: filteredUsers } = useGetList(
    'users',
    {
      sort: { field: 'firstName', order: 'ASC' },

    },
);

  const userChoices = filteredUsers
    ? Object.values(filteredUsers).map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        roles: user.roles
      }))
    : [];
  const redirect = useRedirect();
  const onSuccess = () => {
    const grantYearIdFinal = selectedGrantYear;
    const userIdRaw = selectedUserId;  
      const userId = userIdRaw.split('/')[2];  
      const url = `${entrypoint}/grantrequest/grant-form-content/${userId}/${grantYearIdFinal}/${grantIndex}`;
      const token = localStorage.getItem('token');
      fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` 
          },
          body: JSON.stringify(grantData ? grantData : defaultGrantData)
      })
      .then(response => response.json())
      .then(data => {
          if (data.error) {
              console.error(`Erreur : ${data.error}`);
          } else {
          }
      })
      .catch(error => {
          console.error('Une erreur est survenue lors de l\'envoi des données:', error);
      });
    redirect({
      pathname: '/grant_requests',
    });
  };

  let current = useListController();
  const [grantIndex, setGrantIndex] = useState(null);


  const dataProvider = useDataProvider();
  useEffect(() => {
    if (selectedGrantYear && selectedUserId) {
        const fetchGrantIndex = async () => {
            try {
                const { data, total } = await dataProvider.getList('grant_requests', {
                    sort: { field: 'grantIndex', order: 'DESC' },
                    pagination: { page: 1, perPage: 1 },
                    filter: { user: selectedUserId, grantYear: selectedGrantYear }
                });
                if (total > 0) {
                    const highestIndex = parseInt(data[0].grantIndex, 10);
                    setGrantIndex(String(highestIndex + 1));
                    
                } else {
                    setGrantIndex("1");
                }
            } catch (error) {
                console.error('Erreur lors de la récupération de grantrequests:', error);
            }
        };

        fetchGrantIndex();
    }
}, [selectedGrantYear, selectedUserId, dataProvider]);

console.log(grantData);
  return (
    <Create mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleForm sanitizeEmptyValues>
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''}</h1>
        <Grid container className={"containerInput"}>
          <Grid item md={6} xs={12}>
            <YearSelectInput source="grantYear" label="Année de subvention" onChange={(e) => handleGrantYearChange(e.target.value)} fullWidth/>
          </Grid>
        </Grid>
        <SelectInput source="directoryNbr" label="Nombre dossier" optionText="name" optionValue="name" choices={[
              { id: 1, name: '1er dossier' },
              { id: 2, name: '2er dossier' },
          ]} />
          <TextInput
          source="role"
          defaultValue={selectedRoleFormatted}
          className={"hidden"}
          type={"hidden"}
          fullWidth
        />

        <Grid container>
            <Grid item xs={6}>
                  <SelectInput 
                    label="Utilisateur" 
                    source="user" 
                    choices={userChoices} 
                    fullWidth
                    onChange={(e) => handleUserChange(e.target.value)}
                  />
            </Grid>
        </Grid>
        
        {selectedUserId && selectedRoleFormatted && <GrantFormCreate role={selectedRoleFormatted} onUpdateData={setGrantData} />}

        <Grid container>
          <Grid item xs={6}>
            <TextInput label="Type de sous-dossier" source="directoryType" fullWidth multiline/>
          </Grid>
        </Grid>
          <TextInput
          label="Numéro de dossier"
          source="grantIndex"
          className={"hidden"}
          defaultValue={grantIndex}
          type={"hidden"}
          fullWidth
          
        />
      </SimpleForm>
    </Create>
  );
};

export default GrantRequestCreate;
