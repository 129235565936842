import {
    Filter,
    useDataProvider,
    SelectInput,
    TopToolbar,
    CreateButton,
    ExportButton,
    EditButton,
    List,
    ReferenceField,
    TextInput,
    ShowButton,
    Datagrid,
    TextField,
    FunctionField,
    Link,
    useRecordContext,
    useListController,
    usePermissions,
} from 'react-admin';
import React, { useState, useEffect } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';
import { Stack } from '@mui/material';
import Dropdown from 'react-bootstrap/Dropdown';
import { orderExporter, orderExporterPDF } from '../Tools/ExportExcel/exportUser';
import { entrypoint } from "../config/index.js";

const MyButton = () => {
    const record = useRecordContext();
    const handleClick = () => {
        console.log(record);
    };
    return <button onClick={handleClick}>Gerer</button>;
}

const ColoredTextField = ({ source, record }) => {
    const color = record.membershipStatus ? '#2E7D32' : '#B00020';
    return <span style={{ color }}>{record[source]}</span>;
};

const MemberStatusField = ({ mainRecord, record }) => {
    const membershipStatus = mainRecord.membershipStatus;
    return (
        <span style={{ color: membershipStatus ? '#2E7D32' : '#B00020' }}>
            {record.name}
        </span>
    );
};

const prepareMemberStatusChoices = async (dataProvider) => {
    const { data: statuses } = await dataProvider.getList('member_statuses', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'name', order: 'ASC' },
    });

    const uniqueStatusesMap = new Map();
    statuses.forEach(status => {
        if (!uniqueStatusesMap.has(status.name)) {
            uniqueStatusesMap.set(status.name, status);
        }
    });

    return Array.from(uniqueStatusesMap.values()).map(status => ({
        id: status.id,
        name: status.name
    }));
};

const PostFilters = ({ memberStatusChoices }) => (
    <Filter>
        <TextInput label="Nom" source="lastName" alwaysOn />
        <TextInput label="Prénom" source="firstName" alwaysOn />
        <TextInput label="Mail" source="email" alwaysOn />
        <TextInput label="Nom de la structure" source="structureName" alwaysOn />
        <SelectInput
            label="Statut de l'adhérent"
            source="memberStatus"
            optionValue="id"
            optionText="name"
            alwaysOn

            choices={memberStatusChoices}
        />
        <TextInput label="Année d'adhésion en cours" source="currentYearMembership" alwaysOn />
        <SelectInput
            label="Paiement reçu/non reçu"
            source="paymentReceipts"
            alwaysOn
            choices={[
                { id: 1, name: 'Reçu' },
                { id: 0, name: 'Non Reçu' }
            ]}
        />
        <SelectInput
            label="Statut de l'adhésion"
            source="membershipStatus"
            alwaysOn
            choices={[
                { id: 1, name: 'Actif' },
                { id: 0, name: 'Non Actif' }
            ]}
        />
    </Filter>
);

const ListActions = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    const dataProvider = useDataProvider();

    const handleTestClick = () => {
        setShowModal(true);
    };
    const handleConfirm = async () => {
        const apiEndpoint = `${entrypoint}/payment_alert`;
        const token = localStorage.getItem('token');

        const response = await dataProvider.getList('users', {
            pagination: {},
            sort: { field: 'id', order: 'ASC' },
            filter: { paymentReceipts: false },
        });

        const emailList = response.data.map(record => record.email);

        fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                emails: emailList
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });

        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };
    let dataUser = useListController();
    let exportFileName = 'Utilisateurs';

    return (
        <TopToolbar>
            <CreateButton />
            <ExportButton label='Exporter (Excel)' onClick={() => orderExporter(dataUser.data, null, dataProvider, exportFileName)} />
            <Button variant="contained" color="primary" onClick={handleTestClick}>
                Relance impayé(s)
            </Button>
            <Dialog
                open={showModal}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent>
                    Etes-vous sûr de vouloir envoyer une relance mail à tous les adhérents n'ayant pas réglé leur cotisation ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} color="primary">
                        Confirmer
                    </Button>
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </TopToolbar>
    );
};

const UserList = () => {
    useHideModifierLabel();
    const dataProvider = useDataProvider();
    const [memberStatusChoices, setMemberStatusChoices] = useState([]);
    const { permissions } = usePermissions();

    useEffect(() => {
        if (permissions && permissions.includes('ROLE_ADMIN')) {
            prepareMemberStatusChoices(dataProvider).then(setMemberStatusChoices);
        }
    }, [dataProvider, permissions]);

    const { data, isLoading } = useListController();

    return (
        <List actions={<ListActions data={data} />} exporter={false} title="Comptes" filters={<PostFilters memberStatusChoices={memberStatusChoices} />}>
            <Datagrid>
                <FunctionField source="lastName" label="Nom" render={record => <ColoredTextField source="lastName" record={record} />} />
                <FunctionField source="firstName" label="Prénom" render={record => <ColoredTextField source="firstName" record={record} />} />
                <FunctionField source="structureName" label="Nom de la structure" render={record => <ColoredTextField source="structureName" record={record} />} />
                <FunctionField label="Statut de l'adhérent" render={mainRecord => (
                    <ReferenceField label="Statut de l'adhérent" source="memberStatus" reference="memberStatuses" link={false}>
                        <FunctionField render={record => <MemberStatusField mainRecord={mainRecord} record={record} />} />
                    </ReferenceField>
                )} emptyText="missing data" />
                <FunctionField source="mobilePhone" label="Téléphone portable" render={record => <ColoredTextField source="mobilePhone" record={record} />} />
                <FunctionField source="email" label="Mail" render={record => <ColoredTextField source="email" record={record} />} />
                <FunctionField source="currentYearMembership" label="Année d'adhésion en cours" render={record => <ColoredTextField source="currentYearMembership" record={record} />} />
                <FunctionField
                    source="paymentReceipts"
                    label="Paiement"
                    render={record => (
                        <span style={{ color: record.paymentReceipts ? '#2E7D32' : '#B00020' }}>
                            {record.paymentReceipts ? 'Reçu' : 'Non reçu'}
                        </span>
                    )}
                />
                <FunctionField
                    source="membershipStatus"
                    label="Statut de l'adhesion"
                    render={record => (
                        <span style={{ color: record.membershipStatus ? '#2E7D32' : '#B00020' }}>
                            {record.membershipStatus ? 'Actif' : 'Inactif'}
                        </span>
                    )}
                />
                <EditButton label="MODIFIER" />
                <ShowButton />
            </Datagrid>
        </List>
    );
}

export default UserList;
