import GrantRequestCreate from './GrantRequestCreate';
import GrantRequestEdit from './GrantRequestEdit';
import GrantRequestList from './GrantRequestList';
import GrantRequestShow from './GrantRequestShow';

export default {
  list: GrantRequestList,
  create: GrantRequestCreate,
  edit: GrantRequestEdit,
  show: GrantRequestShow,
};
