import * as React from "react";
import { Show, SimpleShowLayout, FormDataConsumer, useShowController, useGetMany, useGetList, TextField, DateField, RichTextField, ReferenceField, FunctionField, BooleanField } from 'react-admin';
import moment from 'moment'
import 'moment/locale/fr'
import ComplianceFormShow from '../Tools/ComplianceForm/ComplianceFormShow';
import { rest } from "lodash";

function formatRole(role) {
  const withoutPrefix = role.replace('ROLE_', '');

  switch (withoutPrefix) {
      case 'FISHFARMER':
          return 'Pisciculteur';
      case 'TRANSFORMER':
          return 'Transformateur';
      case 'COLLECTOR':
          return 'Collecteur';
      default:
          return withoutPrefix;  // If the role doesn't match any of the specified cases, return the role as-is.
  }
}
const BrandComplianceShow = (props) => {
  const controllerProps = useShowController(props);
  
  const userId = controllerProps.record?.user;
  const campaignId = controllerProps.record?.campaign;
  console.log(campaignId);
  const { data: complianceContents } = useGetList('compliance_form_contents', {
    sort: { field: 'compliance_form_action_id', order: 'ASC' },
    filter: { user: userId, campaign: campaignId }
  });

  const actionIds = [...new Set(complianceContents?.map(content => content.complianceFormAction))];
 
  const { data: complianceActions } = useGetList('compliance_form_actions', {
    filters: { id_in: actionIds.join(",") }
  });

  const transformedData = complianceContents?.map(content => {
      const action = complianceActions?.find(action => action.id === content.complianceFormAction);
      return {
          ...content,
          complianceFormAction: {
              id: action?.id,
              action: action?.action
          }
      };
  });

  return (
    <Show actions={false} {...props} {...controllerProps}>
      <SimpleShowLayout>
        <ReferenceField link={false} label="Campagne" source="campaign" reference="campaigns">
            <FunctionField render={record => record && `${moment(record.startDate).format('YYYY')} / ${moment(record.endDate).format('YYYY')}`} />
        </ReferenceField>
        <FunctionField 
            source="role" 
            label="Rôle" 
            render={record => formatRole(record.role)} 
        />
        <ReferenceField label="Nom/Prénom/Organisme" source="user" reference="users" link={false}>
            <FunctionField render={record => {
                if (record && record.roles) {
                    if (record.roles.includes("ROLE_FISHFARMER")) {
                        return `${record.firstName} ${record.lastName}`;
                    }
                    if (record.roles.includes("ROLE_COLLECTOR")) {
                        return `${record.firstName} ${record.lastName} ${record.structureName ? ' / ' + record.structureName : ''}`;
                    }
                    if (record.roles.includes("ROLE_TRANSFORMER")) {
                        return record.structureName || '';
                    }
                }
                return '';
            }} emptyText="missing data"/>
        </ReferenceField>
        <FunctionField 
          label="Conformité" 
          source="compliance"
          render={record => (
              <span style={{ color: record.compliance ? '#2E7D32' : '#B00020'}}>
                  {record.compliance ? 'Conforme' : 'Non conforme'}
              </span>
          )}
        />
         {controllerProps.record?.note && <TextField source="note" label="Commentaire global" />}
        {<ComplianceFormShow actions={transformedData}/>}
       

      </SimpleShowLayout>
    </Show>
  )
  
};
export default BrandComplianceShow;
