import * as React from 'react';
import { Create, useGetList, AutocompleteArrayInput,useCreateController, useRedirect, ReferenceArrayInput, SimpleForm, TextInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput, ImageInput, ImageField   } from 'react-admin';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

const WaterBodiesCreate = () => {
  const location = useLocation();
  const isBasinCreate = location.pathname === '/basins/create';

  console.log(isBasinCreate);
  const { data } = useGetList('water_body_types', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 5 },
  });
  console.log(data);



const  dataTown  = useGetList('towns', {
  sort: { field: 'name', order: 'ASC' },
  pagination: { page: 1, perPage: 400 },
});

let choicesTown = [];
if (dataTown.data !== undefined) {
  dataTown.data.map((town, index) => {
    choicesTown[index] =
        {
          id: town.id,
          name: town.name
        };
  });
}
const redirect = useRedirect();

const onSuccess = () => {
  if (isBasinCreate) {
    redirect('/basins');
  } else {
    redirect('/water_bodies');
  }
};


  return (
      <Create redirect="list" mutationOptions={{ onSuccess }} mutationMode="pessimistic" sanitizeEmptyValues>
        <SimpleForm >
          <Grid container className={"containerInput"}>
            <Grid item md={6} xs={12}>
              <TextInput source="name" label={isBasinCreate ? "Nom du bassin" : "Nom de l’etang"} validate={[required()]} fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={6} xs={12}>
            <SelectInput  source="town" choices={choicesTown} label="Commune" initialValue={"/towns/133"} validate={[required()]} fullWidth />
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={6}>
              <TextInput source="numDDPP" label="Numéro DDPP" fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
              <Grid item md={3} className={"itemForm1"}>
                  <NumberInput
                      source="cadastralSurface"
                      label={isBasinCreate ? "Surface cadastrale (en m²)" : "Surface cadastrale (en ha)"}
                      fullWidth/>
              </Grid>
              <Grid item md={3} className={"itemForm2"}>
                  <NumberInput
                      source="waterSurface"
                      label={isBasinCreate ? "Surface en eau (en m²)" : "Surface en eau (en ha)"}
                      validate={[required()]}
                      fullWidth/>
              </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={3} className={"itemForm1"}>
              <TextInput source="currentOwner" label="Propriétaire actuel" fullWidth/>
            </Grid>
            <Grid item md={3} className={"itemForm2"}>
              <TextInput source="previousOwner" label="Propriétaire passé" fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={6}>
              <ReferenceInput source="currentCollector" label="Nom du collecteur" reference="collectors">
                <SelectInput optionText="name" label="Nom du collecteur" validate={[required()]} fullWidth/>
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={6} xs={12}>
              <ReferenceArrayInput source="previousCollector" reference="collectors" label="Nom du collecteur passé">
                <AutocompleteArrayInput label="Nom du collecteur passé" optionText="name"/>
              </ReferenceArrayInput>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={3} xs={12} className={"itemForm1"}>
              <NumberInput source="averageDepth" label="Profondeur moyenne (en m)" fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={2} className={"itemForm1"}>
              <BooleanInput source="aerator" label="Aérateur" fullWidth/>
            </Grid>
            <Grid item md={2}>
              <BooleanInput source="electricity" label="Electricité" fullWidth/>
            </Grid>
            <Grid item md={2}>
              <BooleanInput source="feeder" label="Nourrisseur" fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={3} className={"itemForm1"}>
              <NumberInput source="distance" label={isBasinCreate ? "Distance siège/bassin (en km)" : "Distance siège/étang (en km)"} fullWidth/>
            </Grid>
          </Grid>
          <Grid container className={"containerInput"}>
            <Grid item md={3}>
              <ImageInput source="picture" label="Photo" accept="image/*">
                <ImageField source="src" title="title"/>
              </ImageInput>
            </Grid>
          </Grid>
          {data !== undefined ? <TextInput source="waterBodyType" label="Etangs" defaultValue={isBasinCreate ? data[1].id : data[0].id} type={"hidden"}/> : ''}



        </SimpleForm>
      </Create>
  );
}
export default WaterBodiesCreate;
