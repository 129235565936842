import BrandComplianceCreate from './BrandComplianceCreate';
import BrandComplianceEdit from './BrandComplianceEdit';
import BrandComplianceList from './BrandComplianceList';
import BrandComplianceShow from './BrandComplianceShow';

export default {
  list: BrandComplianceList,
  create: BrandComplianceCreate,
  edit: BrandComplianceEdit,
  show: BrandComplianceShow,
};
