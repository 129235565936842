import * as React from 'react';
import { createElement } from 'react';
import { useMediaQuery } from '@mui/material';
import { Menu, useResourceDefinitions, usePermissions  } from 'react-admin';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from './img/logo.png';

export const MyMenu = () => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const resources = useResourceDefinitions();
  const { permissions } = usePermissions();
  return (
    <Menu>
      <div className={"logoImg"}><img src={logo} alt="Logo" /></div>
      <Menu.Item to="/news" primaryText="Actualités" leftIcon={<i className="fas fa-newspaper"></i>} />

      {permissions !== undefined && (permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_FISHFARMER')) ?
        <>

            <Menu.Item to="/balance_sheet" primaryText="Bilan d'exploitation" leftIcon={<i className="fas fa-tachometer-alt"></i>} />
            <Menu.Item to="/basins" primaryText="Mes bassins" leftIcon={<i className="fa-solid fa-water"></i>} />
            <Menu.Item to="/water_bodies" primaryText="Mes étangs" leftIcon={<i className="fa-solid fa-water"></i>} />
            <Menu.Item to="/fish_prices" primaryText="Mes tarifs" leftIcon={<i className="fas fa-euro-sign"></i>} />

        </>
      : ''}

        {permissions !== undefined && (permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_FISHFARMER') || permissions.includes('ROLE_COLLECTOR') || permissions.includes('ROLE_TRANSFORMER')) ?
            <>
                <Menu.Item to="/brand_compliances" primaryText="Conformité marque" leftIcon={<i class="fa-solid fa-file-lines"></i>} />
                <Menu.Item to="/grant_requests" primaryText="Demande de subventions" leftIcon={<i class="fa-solid fa-file-lines"></i>} />
            </>
            : ''}

        {permissions !== undefined && (permissions.includes('ROLE_ADMIN'))  ?
                <Menu.Item to="/member_ship_prices" primaryText="Prix cotisation" leftIcon={<i className="fas fa-euro-sign"></i>} />
            : ''}


        {/*<Menu.Item to="/towns" primaryText="Commune" leftIcon={<i className="fa-solid fa-water"></i>} />*/}
      {/*<Menu.Item to="/custom-route" primaryText="Mes bassins" leftIcon={<i className="fa-solid fa-water"></i>} />*/}

      {/*<Menu.Item to="/custom-route" primaryText="Calendrier des pêches" leftIcon={<i className="far fa-calendar-alt"></i>} />*/}
      {/*<Menu.Item to="/custom-route" primaryText="Ma conformité" leftIcon={<i className="far fa-check-circle"></i>} />*/}
      {/*<Menu.Item to="/custom-route" primaryText="Mes demandes de subventions" leftIcon={<i className="fas fa-list"></i>} />*/}
      {/*<Menu.Item to="/admin_manager" primaryText="Admin" leftIcon={<i className="fas fa-list"></i>} />*/}
      {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?
        <>
          <Menu.Item to="/users" primaryText="Comptes " leftIcon={<i className="fas fa-user"></i>} />
          <Menu.Item to="/level_indicators" primaryText="Indicateurs niveaux" leftIcon= {<i class="fa-solid fa-chart-simple"></i>} />

        </>
        : ''}
    </Menu>
  );
};
