import React, { useState, useEffect } from "react";
import "../index.css";
import StackedBarChart from '../Tools/Charts/BarChartStacked';
import { usePermissions } from 'react-admin';
import StackedTinyBarChart from '../Tools/Charts/StackedTinyBarChart';
import 'moment/locale/fr';
import TinyBarChart from "../Tools/Charts/TinyBarChart";
import 'bootstrap/dist/css/bootstrap.min.css';

const CampaignAndWaterBody = ({filterCampaign1, filterCampaign2, waterBodyData, filterUser}) => {
    const [fsPerCategory, setFishStockingPerCategory] = useState([]);
    const [fishPerCategory, setFishPerCategory] = useState([]);

    let campaignId = '';
    let campaignId2 = '';
    let waterBodyId = waterBodyData.id;
    const { permissions } = usePermissions();
    const speciesColors = [
        "#8249F4", 
        "#BBF449", 
        "#4966F4",
        "#D749F4", 
        "#F449BB",
        "#FFC107", 
      ];
    if (filterCampaign1) {
        campaignId = filterCampaign1.split("/").pop();
    }
    if (filterCampaign2) {
        campaignId2 = filterCampaign2.split("/").pop();
    }
    if (waterBodyId) {
      waterBodyId = waterBodyId.split("/").pop();
  }
    console.log(waterBodyId);
    const fetchFishStockingPerCategory= async (waterBodyId) => {
      try {
          const token = localStorage.getItem('token');
          let filterUserValid = filterUser != null && !isNaN(filterUser);
          let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
          ? `https://api.poissonsdedombes.fr/dashboard/total_fish_stocking_ByWaterBody/${campaignId}/${waterBodyId}/${campaignId2}/${filterUser}`
          : `https://api.poissonsdedombes.fr/dashboard/total_fish_stocking_ByWaterBody/${campaignId}/${waterBodyId}/${campaignId2}/null`;
          console.log(url);
          const response = await fetch(
              url,
              {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                  }
              }
          );
          const responseData = await response.json();
          const data = responseData.fishStocking_perCampaign;
          setFishStockingPerCategory(data);
      } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
      }
  };
  const fetchFishingPerCategory = async (waterBodyId) => {
      try {
          const token = localStorage.getItem('token');
          let filterUserValid = filterUser != null && !isNaN(filterUser);
          let url = (permissions !== undefined && permissions.includes('ROLE_ADMIN') && filterUserValid)
          ? `https://api.poissonsdedombes.fr/dashboard/total_fishing_ByWaterBody/${campaignId}/${waterBodyId}/${campaignId2}/${filterUser}`
          : `https://api.poissonsdedombes.fr/dashboard/total_fishing_ByWaterBody/${campaignId}/${waterBodyId}/${campaignId2}/null`;
          const response = await fetch(
              url,
              {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                  }
              }
          );
          const responseData = await response.json();
          const data = responseData.data;
          setFishPerCategory(data);
      } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
      }
  };
  useEffect(() => {
    fetchFishStockingPerCategory(waterBodyId);
    fetchFishingPerCategory(waterBodyId);
  }, [filterCampaign1, filterCampaign2, filterUser, waterBodyData]); 
  const getMaxWeight = (data) => {
    let weightsByCampaign = {};

    data.forEach(datum => {
        if (datum.fish_perCategory.length > 0) {
            if (!weightsByCampaign[datum.campaign_date]) {
                weightsByCampaign[datum.campaign_date] = 0;
            }
            datum.fish_perCategory.forEach(category => {
                weightsByCampaign[datum.campaign_date] += category.weight;
            });
        }
    });

    let maxWeight = 0;
    for (let campaign in weightsByCampaign) {
        if (weightsByCampaign[campaign] > maxWeight) {
            maxWeight = weightsByCampaign[campaign];
        }
    }

    return Math.round(maxWeight * 100) / 100;
  }
  const getMaxWeightFS = (data) => {
    let weightsByCampaign = {};

    data.forEach(datum => {
        if (datum.fs_perCategoryKG.length > 0) {
            if (!weightsByCampaign[datum.campaign_date]) {
                weightsByCampaign[datum.campaign_date] = 0;
            }
            datum.fs_perCategoryKG.forEach(category => {
                weightsByCampaign[datum.campaign_date] += category.weight;
            });
        }
    });

    let maxWeight = 0;
    for (let campaign in weightsByCampaign) {
        if (weightsByCampaign[campaign] > maxWeight) {
            maxWeight = weightsByCampaign[campaign];
        }
    }

    return Math.round(maxWeight * 100) / 100;
  }
  let maxWeight = getMaxWeight(fishPerCategory);
  let maxWeightFS = getMaxWeightFS(fsPerCategory);
  const roundToNextMagnitude = (value) => {
    const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(value))));
    const roundedValue = Math.ceil(Math.abs(value) / magnitude) * magnitude;
    return value >= 0 ? roundedValue : -roundedValue;
  };
  const roundedMaxWeight = roundToNextMagnitude(maxWeight);
  const roundedMaxWeightFS = roundToNextMagnitude(maxWeightFS);
  console.log(fsPerCategory);
  console.log(fishPerCategory);
  return (

    <div className="backgroundBalance">
      {/* <img src={balanceSheetImg} className="img-balanceSheet"></img> */}
      <p className="section-title">Evolution des empoissonnages et pêches</p>
      <div className="background-container container-fluid">
        <div className="chart-container row">
          <div className='flextab'>
            <div style={{ marginBottom: 50 }}>
              <StackedTinyBarChart
                title={[
                  `Empoissonnage de l'étang « ${waterBodyData.name} »`,
                  <span>sur plusieurs années</span>
                ]}
                ticks={[0, roundedMaxWeightFS]}
                rawData={fsPerCategory}
                unityValue='kg'
                categoryPropertyName="fs_perCategoryKG"
                valuePropertyName="weight" 
              />
            </div>
            <div style={{ marginBottom: 50 }}>
              <StackedTinyBarChart
                title={[
                  `Pêches de l'étang « ${waterBodyData.name} »`,
                  <span>sur plusieurs années</span>
                ]}
                ticks={[0, roundedMaxWeight]}
                rawData={fishPerCategory}
                unityValue='kg'
                categoryPropertyName="fish_perCategory"
                valuePropertyName="weight" 

              />
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default CampaignAndWaterBody;
