import * as React from "react";
import {
  Show,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  DateField,
  RichTextField,
  usePermissions,useRecordContext,
  BooleanField, FunctionField
} from 'react-admin';



const UserShow = () => {
  const {permissions} = usePermissions();
  return (
      <Show>
        <SimpleShowLayout>
          <TextField source="civility" label="Civilité"/>
          <TextField source="firstName" label="Prénom"/>
          <TextField source="lastName" label="Nom"/>
          {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?

              <FunctionField source="roles" label="Rôles" render={record => {
                if (record && record.roles.includes('ROLE_ADMIN')) {
                  return "Administrateur";
                }
                if (record && record.roles.includes('ROLE_USER')) {
                  return "Pisciculteur";
                }

                return null;
              }}/>




              : ''}
          <ReferenceField label="Statut de l'adhérent" source="memberStatus" reference="memberStatus">
            <FunctionField label="Statut de l'adhérent" render={record => record && `${record.name}`} />
          </ReferenceField>
          <TextField source="email" label="Adresse email"/>
          <TextField source="structureName" label="Entreprise/Organisme"/>
          <TextField source="address" label="Adresse"/>
          <TextField source="postcode" label="Code postal"/>
          <TextField source="city" label="Ville"/>
          <BooleanField source="sendsIdenticalContribution" label="Adresse envoi cotisation différente"/>
          <TextField source="contributionAddress" label="Adresse envoie cotisation"/>
          <TextField source="postCodeContribution" label="Code postal envoi cotisation"/>
          <TextField source="cityContribution" label="Ville envoi cotisation"/>
          <TextField source="structureName2" label="Entreprise/Organisme cotisation"/>
          <TextField source="contactPerson" label="Personne à contacter"/>
          <TextField source="homePhone" label="Téléphone fixe"/>
          <TextField source="mobilePhone" label="Téléphone portable"/>
          <TextField source="contactPerson2" label="Personne à contacter supplémentaire"/>
          <TextField source="homePhone2" label="Téléphone fixe"/>
          <TextField source="mobilePhone2" label="Téléphone portable"/>
          <TextField source="email2" label="Adresse email"/>
          <TextField source="registeredArea" label="Surface ha cadastré"/>
          <TextField source="currentYearMembership" label="Année d'adhésion en cours"/>
          <TextField source="waterBodiesCount" label="Nombre d'étang"/>
          <ReferenceField label="Cotisation en € HT" source="memberShipPrice" reference="memberShipPrice">
            <FunctionField label="cotisation en € HT" render={record => record && `${record.contribution}`} />
          </ReferenceField>
          <ReferenceField label="Cotisation en € TTC" source="memberShipPrice" reference="memberShipPrice">
            <FunctionField label="cotisation en € TTC" render={record => record && `${record.contribution*1.2}`} />
          </ReferenceField>
          <BooleanField source="paymentReceipts" label="Règlement reçu"/>
          {permissions !== undefined && permissions.includes('ROLE_ADMIN') ?

              <TextField source="technicalLevel" label="Niveau technique"/>
              : ''}


        </SimpleShowLayout>
      </Show>
  );
}
export default UserShow;
