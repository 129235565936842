import React from 'react';
import { useLogin, useNotify, useTranslate } from 'react-admin';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import LockIcon from '@mui/icons-material/Lock';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { bottom } from '@popperjs/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useLocation, Link } from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: {
            main: '#38a9b4',
        },
    },
});

const Background = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundImage: 'radial-gradient(circle at 50% 14em, #90dfe7 0%, #288690 60%, #288690 100%)',
});

const LoginCard = styled(Card)({
    width: '300px',
    margin: 'auto',

    marginTop: '9%',
    textAlign: 'center',
    padding: '16px',
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '4px',
    boxShadow:' rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    overflow: 'hidden',
});
const StyledCardContent = styled(CardContent)({
    padding:'0 !important',
    marginBottom:0,
    
    
});


const StyledAvatar = styled(Avatar)({
    backgroundColor: 'rgb(40, 134, 144)',
    
});

const StyledTextField = styled(TextField)({
    width: '100%', // Ajustez la largeur ici, par exemple '350px' si vous souhaitez une largeur spécifique
    '& .MuiInputLabel-root': {
        color: 'rgba(0, 0, 0, 0.6)',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '400',
        fontSize: '1rem',
        lineHeight: '1.4375em',
        letterSpacing: '0.00938em',
        whiteSpace: 'nowrap',
        transform: 'translate(12px, 4px) scale(0.75)'
    },
    
    '& .MuiInputBase-input.MuiFilledInput-input': {
        height: '1.4375em',
        padding: '21px 12px 4px',
        letterSpacing: 'inherit',
    },

    '& .MuiFilledInput-underline:before': {
       
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        left: '0px',
        bottom: '0px',
        position: 'absolute',
        right: '0px',
        transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        pointerEvents: 'none',
    },
    '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `2px solid ${theme.palette.primary.main}` // Barre verte au survol
    },
    '& .MuiFilledInput-underline.Mui-focused:before': {
        borderBottom: `2px solid ${theme.palette.primary.main}` // Barre verte lors du focus
    },
    '& .MuiFilledInput-root': {
        // backgroundColor: 'rgba(0, 0, 0, 0.03)'
        backgroundColor: '#fff',
        border: '1px solid #d1d3e2',
        borderRadius: '4px'
    },
    
    margin: '1em 0',
});

const LoginFormButton = styled(Button)({
    marginTop: '15px',
    color: '#ffffff',
});

const MyLoginPage = () => {
    const login = useLogin();
    const notify = useNotify();
    const translate = useTranslate();

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        login({ username, password }).catch(() => notify('Invalid email or password'));
    };

    return (
        <ThemeProvider theme={theme}>
            <Background>
                <div className="MuiScopedCssBaseline-root">
                    <div className="css-1rujn38-RaLogin-root">
                        <LoginCard>
                            <div className='RaLogin-avatar'>
                                <StyledAvatar>
                                    <LockIcon />
                                </StyledAvatar>
                            </div>
                            <form noValidate onSubmit={handleSubmit}>
                                <StyledCardContent>
                                <StyledTextField
                                    label={translate('ra.auth.username')}
                                    name="username"
                                    type="text"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    fullWidth
                                    variant="filled"
                                    required  // Ajoutez ceci
                                    style={{marginBottom:22}}
                                />
                                <StyledTextField
                                    label={translate('ra.auth.password')}
                                    style={{marginBottom:25}}
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    fullWidth
                                    variant="filled"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleTogglePasswordVisibility}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                                
                                    <LoginFormButton
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                    >
                                        {translate('ra.auth.sign_in')}
                                    </LoginFormButton>
                                    <Link 
                                        to={`/forgot_password?email=${username}`} 
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button 
                                            color="primary"
                                            style={{ textTransform: "none", margin:'20px 0 4px',}}
                                        >
                                            Mot de passe oublié ?
                                        </Button>
                                    </Link>
                                </StyledCardContent>
                            </form>
                        </LoginCard>
                    </div>
                </div>
            </Background>
        </ThemeProvider>
    );
}

export default MyLoginPage;
