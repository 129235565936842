import {
    ListBase,
    FilterForm,
    CreateButton,
    ReferenceInput,
    SelectInput,
    usePermissions,
    useGetOne,
    useListController,
    ExportButton,
    useListContext,
    EditButton,
    Button,
    TopToolbar,
    Link,
    linkToRecord,
    DeleteButton,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import 'moment/locale/fr';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import ShortDescription from "../Tools/ShortDescription";
import * as React from "react";
import Chip from '@mui/material/Chip';
import { entrypoint } from "../config/index.js";

const Empty = () => {
    let data = useListController();
    return (
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore d'actualités.
            </Typography>
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            <Button href={"#/news/create"}>Créer</Button>
        </Box>
    );
};

const postFilters = [
    <ReferenceInput source="user" label="Utilisateur" reference="users" alwaysOn>
        <SelectInput emptyText="Tous les utilisateurs" optionText="username" label="Utilisateur" fullWidth defaultValue="TOUS" />
    </ReferenceInput>
];

const ListImage = ({ data }) => {
    const { data: image, loading, error } = useGetOne('media_object', { id: data.file });
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading image</div>;
    if (!image) return null;

    return (
        <CardMedia
            component="div"
            sx={{
                pt: '100%',
            }}
            image={`${entrypoint}/images/` + image.contentUrl}
        />
    );
};

const NewsList = () => {
    const { data } = useListController();
    const { permissions } = usePermissions();

    if (!data) return null;
    console.log(data);
    // Trier les données par date (du plus récent au plus ancien)
    const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return (
        <>
            <Container sx={{ py: 12 }} maxWidth="xl" style={{
                backgroundColor: 'white',
                marginTop: '80px',
                paddingTop: '20px',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
            }}>

                {permissions !== undefined && (permissions.includes('ROLE_ADMIN')) ?
                    <TopToolbar>
                        <Button href={"#/news/create"}>+ Créer</Button>
                    </TopToolbar>
                    : ''}
                {/* End hero unit */}
                <h1 className={"titleName"} style={{ fontWeight: '600' }}>Actualités</h1>

                <Grid container spacing={12}>

                    {sortedData.map((card) => (
                        card.state === true ? (
                            <Grid item key={card.originId} xs={12} sm={6} md={3}>
                                <Link to={"/news/%2Fnews%2F" + card.originId + "/show"}>
                                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <ListImage data={card} />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: '600' }}>
                                                {card.title}
                                            </Typography>
                                            <Typography>
                                                <ShortDescription description={card.content} maxLength={100} />
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            {permissions !== undefined && permissions.includes('ROLE_ADMIN') ? (
                                                <div style={{ width: '50%' }}>
                                                    <Button href={"/#/news/%2Fnews%2F" + card.originId}><i className="fas fa-edit"></i></Button>
                                                    <DeleteButton label={""} basePath="/news" record={card} />
                                                </div>
                                            ) : null}
                                        </CardActions>
                                    </Card>
                                </Link>
                            </Grid>
                        ) : (permissions !== undefined && permissions.includes('ROLE_ADMIN') && card.state !== true) ? (
                            <Grid item key={card.originId} xs={12} sm={6} md={3}>
                                <Link to={"/news/%2Fnews%2F" + card.originId + "/show"}>
                                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <ListImage data={card} />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Chip label="Brouillon" color="primary" sx={{ top: '10px', left: '10px', zIndex: 1 }} />
                                            <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: '600' }}>
                                                {card.title}
                                            </Typography>
                                            <Typography>
                                                <ShortDescription description={card.content} maxLength={100} />
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            {permissions !== undefined && permissions.includes('ROLE_ADMIN') ? (
                                                <div style={{ width: '50%' }}>
                                                    <Button href={"/#/news/%2Fnews%2F" + card.originId}><i className="fas fa-edit"></i></Button>
                                                    <DeleteButton label={""} basePath="/news" record={card} />
                                                </div>
                                            ) : null}
                                        </CardActions>
                                    </Card>
                                </Link>
                            </Grid>
                        ) : null
                    ))}

                </Grid>
            </Container>
        </>
    );
};
export default NewsList;
