import LevelIndicatorCreate from './LevelIndicatorCreate';
import LevelIndicatorEdit from './LevelIndicatorEdit';
import LevelIndicatorList from './LevelIndicatorList';
import LevelIndicatorShow from './LevelIndicatorShow';

export default {
  list: LevelIndicatorList,
  create: LevelIndicatorCreate,
  edit: LevelIndicatorEdit,
  show: LevelIndicatorShow,
};
