import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const ProgressContainer = styled('div')({

  position: 'relative',
  margin: '20px 10px 20px 10px',
  width: '100px',
  height: '100px',
});

const StyledCircularProgress = styled(CircularProgress)(({ coefficient }) => ({
  color: getColorFromCoefficient(coefficient),
  '& .MuiCircularProgress-circle': {
    strokeLinecap: 'round',
  },
}));

const StyledTypography = styled(Typography)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontWeight: 'bold',
  fontSize: '16px',
});

const getColorFromCoefficient = (coefficient) => {
  if (coefficient <= 2) {
    return '#FF0000'; // Rouge pour un coefficient inférieur ou égal à 2
  } else if (coefficient <= 4) {
    return '#FFA500'; // Orange pour un coefficient entre 2 (exclus) et 4 (inclus)
  } else {
    return '#4e73df'; // Vert pour un coefficient supérieur à 4
  }
};

const CoefficientCircle = ({ total_coeff }) => {
  return (
    <ProgressContainer>
      <StyledCircularProgress coefficient={4.02} variant="determinate" value={80} size={100} thickness={6} />
      <StyledTypography variant="h6" component="p">
        {total_coeff}
      </StyledTypography>
    </ProgressContainer>
  );
};

export default CoefficientCircle;
