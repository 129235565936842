import { List, useMutation, useRecordContext, ReferenceField, FunctionField, useGetOne, useListController, TopToolbar, ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box, Typography } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment'
import 'moment/locale/fr'
import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getAllData(dataProvider, waterBodyId, campaignValue) {
  let allData = [];
  let page = 1;
  const perPage = 100; 
  const filters = { waterBody: waterBodyId };
  if (campaignValue !== null) {
    filters.campaign = campaignValue;
  }
  return new Promise(async (resolve) => {
    
      while (true) {
          const { data, total } = await dataProvider.getList('action_regulated_animals', {
              pagination: { page: page, perPage: perPage },
              sort: { field: 'id', order: 'ASC' },
              filter: filters
          });

          allData = allData.concat(data);

          if (allData.length >= total) {
              resolve(allData);
              return;
          }
          page++;
      }
  });
}
let cache = {};

async function fetchRecords(dataProvider, orders, resource, key) {
  const ids = orders.map(order => order[key]).filter(id => id !== undefined);
  if (cache[resource] && ids.every(id => cache[resource][id])) {
    return { data: ids.map(id => cache[resource][id]) };
  }

  const result = await dataProvider.getMany(resource, { ids: ids });
  if (!cache[resource]) {
    cache[resource] = {};
  }
  result.data.forEach(item => {
    cache[resource][item.id] = item;
  });

  return result;
}

async function processData(datavide, dataProvider, campaignValue) {
  const orders = await getAllData(dataProvider, datavide[0].waterBody, campaignValue);
  const [campaigns, regulatedAnimals] = await Promise.all([
    fetchRecords(dataProvider, orders, 'campaigns', 'campaign'),
    fetchRecords(dataProvider, orders, 'regulatedAnimal', 'regulatedAnimal'),
  ]);
  // let data =
  //   dataProvider.getMany('campaigns', { ids: orders.map(order => order.campaign) }).then(campaigns => {
  //     const regulatedAnimalIds = orders.map(order => order.regulatedAnimal).filter(regulatedAnimalId => regulatedAnimalId !== undefined);
  //     dataProvider.getMany('regulatedAnimal', { ids: regulatedAnimalIds }).then(regulatedAnimals => {
  let key = 0;
  return orders.map(order => {
    let campaign = campaigns.data[key];
    let regulatedAnimal = regulatedAnimals.data.find(regulatedAnimal => regulatedAnimal.id === order.regulatedAnimal);
    key++;
    return {
      "ID": order.originId,
      "Campagne": `${moment(campaign.startDate).format('YYYY')} / ${moment(campaign.endDate).format('YYYY')}`,
      "Date": moment(order.date).format('DD MMMM YYYY'),
      "Animal": regulatedAnimal.name,
      "Nombre animaux piégés": order.trappedNumber,
      "Nombre animaux tirés": order.firedNumber ? order.firedNumber : "",
      "Nombre animaux observés": order.killedNumber ? order.killedNumber : "",
      "Remarques": order.note ? order.note : "",
    };
  });
}

export async function orderExporter(datavide, fetchRelatedRecords, dataProvider, campaignValue, exportFileName) {
  const ordersForExport = await processData(datavide, dataProvider, campaignValue);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(exportFileName);
  const headerRow = worksheet.addRow(Object.keys(ordersForExport[0]));
  headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
  headerRow.eachCell(cell => {
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    if (cell.value) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4e73df' }
      };
    }
  });

  ordersForExport.forEach(order => {
    const row = worksheet.addRow(Object.values(order));
    row.eachCell(cell => {
      cell.alignment = { horizontal: 'left' };
    });
  });
  worksheet.getRow(1).height = 30;

  const defaultColumnWidth = 20;
  const specificColumnWidths = {
    2: 28,
    6: 25,
    10: 25

  };
  worksheet.columns.forEach((column, columnIndex) => {
    if (specificColumnWidths[columnIndex + 1]) {
      column.width = specificColumnWidths[columnIndex + 1];
    } else {
      column.width = defaultColumnWidth;
    }
  });
  workbook.xlsx.writeBuffer().then(buffer => {
    downloadExcel(buffer, exportFileName);
  });
}
export async function orderExporterPDF(datavide, fetchRelatedRecords, dataProvider, campaignValue, exportFileName) {
  const ordersForExport = await processData(datavide, dataProvider, campaignValue);
  
  const pdfContent = {
    pageOrientation: 'landscape',
    content: [
      { text: exportFileName, style: 'title' }
    ],
    styles: {
      title: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      header: {
        color:'white',
        fontSize: 6,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      body: {
        fontSize: 6,
      },
      
    }
  };
  const defaultColumnWidth = 85
  const specificColumnWidths = {};

  const tableHeader = Object.keys(ordersForExport[0]);
  const tableBody = ordersForExport.map(order => Object.values(order));
  const styledTableBody = tableBody.map(row => {
    return row.map(cellValue => ({ text: cellValue, style: 'body' }));
  });
  pdfContent.content.push({
    layout: {
      fillColor: function (rowIndex, node, columnIndex) {
        return rowIndex === 0 ? '#4e73df' : null; // If it's the header row, set the background color.
      },
      hLineColor: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 'black' : '#AAAAAA';
      },
      vLineColor: function (i, node) {
        return '#AAAAAA';
      },
      paddingLeft: function (i, node) { return 3.5; },
      paddingRight: function (i, node) { return 3.5; },
      paddingTop: function (i, node) { return 3.5; },
      paddingBottom: function (i, node) { return 3.5; }
    },
    table: {
      headerRows: 1,
      widths: tableHeader.map((_, index) => specificColumnWidths[index + 1] || defaultColumnWidth), // Assuming specificColumnWidths and defaultColumnWidth are defined.
      body: [[...tableHeader.map(header => ({ text: header, style: 'header' }))], ...styledTableBody]
    }
  });


  pdfMake.createPdf(pdfContent).download(exportFileName+'.pdf');
              
}


const downloadExcel = (buffer, filename) => {
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
function clearCache() {
  cache = {};
}
clearCache();
export default orderExporter;