import { List, useMutation, useRecordContext, ReferenceField, FunctionField, useGetOne, useListController, TopToolbar, ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box, Typography } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import moment from 'moment'
import 'moment/locale/fr'
import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

async function getAllData(dataProvider) {
    let allUsers = [];
    let page = 1;
    const perPage = 100; 
    return new Promise(async (resolve) => {
        while (true) {
            const { data, total } = await dataProvider.getList('users', {
                pagination: { page: page, perPage: perPage },
                sort: { field: 'id', order: 'ASC' }
            });
            allUsers = allUsers.concat(data);
            if (allUsers.length >= total) {
                resolve(allUsers);
                return;
            }
            page++;
        }
    });
  }
let cache = {};

async function fetchRecords(dataProvider, orders, resource, key) {
  const ids = orders.map(order => order[key]).filter(id => id !== undefined);
  if (cache[resource] && ids.every(id => cache[resource][id])) {
    return { data: ids.map(id => cache[resource][id]) };
  }

  const result = await dataProvider.getMany(resource, { ids: ids });
  if (!cache[resource]) {
    cache[resource] = {};
  }
  result.data.forEach(item => {
    cache[resource][item.id] = item;
  });

  return result;
}

async function processData(datavide, dataProvider) {
    const users = await getAllData(dataProvider);
    const [memberStatuses, memberShipPrices, collectors] = await Promise.all([
      fetchRecords(dataProvider, users, 'memberStatuses', 'memberStatus'),
      fetchRecords(dataProvider, users, 'memberShipPrices', 'memberShipPrice'),
      fetchRecords(dataProvider, users, 'collectors', 'collector')
    ]);
    return users.map(user => {
        let memberStatus = memberStatuses.data.find(status => status.id === user.memberStatus) || {};
        let memberShipPrice = memberShipPrices.data.find(price => price.id === user.memberShipPrice) || {};
        let collector = collectors.data.find(col => col.id === user.collector) || {};
        return {
          "ID": user.originId,
          "Statut du Membre": memberStatus.name || "", 
          "Tarif d'Adhésion": memberShipPrice.contribution || "", 
          "Collecteur": collector.name || "",
          "Email": user.email,
          "Rôles": JSON.stringify(user.roles),
          "Civilité": user.civility,
          "Prénom": user.firstName,
          "Nom de Famille": user.lastName,
          "Nom de la Structure": user.structureName,
          "Nom de la Structure 2": user.structureName2,
          "Email 2": user.email2,
          "Adresse": user.address,
          "Code Postal": user.postcode,
          "Ville": user.city,
          "Personne de Contact": user.contactPerson,
          "Personne de Contact 2": user.contactPerson2,
          "Téléphone Domicile": user.homePhone,
          "Téléphone Domicile 2": user.homePhone2,
          "Téléphone Mobile": user.mobilePhone,
          "Téléphone Mobile 2": user.mobilePhone2,
          "Zone Enregistrée": user.registeredArea,
          "Nombre de Plans d'Eau": user.waterBodiesCount,
          "Adhésion Année en Cours": user.currentYearMembership,
          "Reçus de Paiement": user.paymentReceipts ? 'Oui' : 'Non', 
          "Détail de Paiement": user.paymentDetail,
          "Statut d'Adhésion": user.membershipStatus ? 'Actif' : 'Inactif',
          "Niveau Technique": user.technicalLevel,
          "Adresse de Contribution": user.contributionAddress,
          "Envoi Contribution Identique": user.sendsIdenticalContribution ? 'Oui' : 'Non',
          "Code Postal de Contribution": user.postCodeContribution,
          "Ville de Contribution": user.cityContribution
        };
      });      
}

export async function orderExporter(datavide, fetchRelatedRecords, dataProvider, exportFileName) {
  const ordersForExport = await processData(datavide, dataProvider);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(exportFileName);
  const headerRow = worksheet.addRow(Object.keys(ordersForExport[0]));
  headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
  headerRow.eachCell(cell => {
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    if (cell.value) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4e73df' }
      };
    }
  });

  ordersForExport.forEach(order => {
    const row = worksheet.addRow(Object.values(order));
    row.eachCell(cell => {
      cell.alignment = { horizontal: 'left' };
    });
  });
  worksheet.getRow(1).height = 30;

  const defaultColumnWidth = 20;
  const specificColumnWidths = {
    2: 28,
    6: 28,
    10: 25

  };
  worksheet.columns.forEach((column, columnIndex) => {
    if (specificColumnWidths[columnIndex + 1]) {
      column.width = specificColumnWidths[columnIndex + 1];
    } else {
      column.width = defaultColumnWidth;
    }
  });
  workbook.xlsx.writeBuffer().then(buffer => {
    downloadExcel(buffer, exportFileName);
  });
}
export async function orderExporterPDF(datavide, fetchRelatedRecords, dataProvider, exportFileName) {
  const ordersForExport = await processData(datavide, dataProvider);
  
  const pdfContent = {
    pageOrientation: 'landscape',
    content: [
      { text: exportFileName, style: 'title' }
    ],
    styles: {
      title: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      header: {
        color:'white',
        fontSize: 6,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      body: {
        fontSize: 6,
      },
      
    }
  };
  const defaultColumnWidth = 50
  const specificColumnWidths = {};

  const tableHeader = Object.keys(ordersForExport[0]);
  const tableBody = ordersForExport.map(order => Object.values(order));
  const styledTableBody = tableBody.map(row => {
    return row.map(cellValue => ({ text: cellValue, style: 'body' }));
  });
  pdfContent.content.push({
    layout: {
      fillColor: function (rowIndex, node, columnIndex) {
        return rowIndex === 0 ? '#4e73df' : null; 
      },
      hLineColor: function (i, node) {
        return (i === 0 || i === node.table.body.length) ? 'black' : '#AAAAAA';
      },
      vLineColor: function (i, node) {
        return '#AAAAAA';
      },
      paddingLeft: function (i, node) { return 3.5; },
      paddingRight: function (i, node) { return 3.5; },
      paddingTop: function (i, node) { return 3.5; },
      paddingBottom: function (i, node) { return 3.5; }
    },
    table: {
      headerRows: 1,
      widths: tableHeader.map((_, index) => specificColumnWidths[index + 1] || defaultColumnWidth), // Assuming specificColumnWidths and defaultColumnWidth are defined.
      body: [[...tableHeader.map(header => ({ text: header, style: 'header' }))], ...styledTableBody]
    }
  });


  pdfMake.createPdf(pdfContent).download(exportFileName+'.pdf');
              
}


const downloadExcel = (buffer, filename) => {
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
function clearCache() {
  cache = {};
}
clearCache();
export default orderExporter;