import FishPriceCreate from './FishPriceCreate';
import FishPriceEdit from './FishPriceEdit';
import FishPriceList from './FishPriceList';
import FishPriceShow from './FishPriceShow';

export default {
  list: FishPriceList,
  create: FishPriceCreate,
  edit: FishPriceEdit,
  show: FishPriceShow,
};
