// in src/posts.js
import { List, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link, useRecordContext } from 'react-admin';

const MyButton = () => {
  const record = useRecordContext();
  const handleClick = () => {
    console.log(record);
  };
  return <button onClick={handleClick}>Gerer</button>;
}


function handleClick() {
  alert("test");
}

const TownList = () => (
  <List title="Mes Etangs">
    <Datagrid>
      <TextField source="originId" label="#" />
      <TextField source="name" label="Nom"/>
      <TextField source="town.name"  label="Commune"/>
      <TextField source="waterSurface" label="Surface en eau"/>
      <TextField source="numDDPP"  label="N° DDPP"/>
      <Link to="/manager">
        <Button>Gerer</Button>
      </Link>

      <EditButton label="MODIFIER" />
    </Datagrid>
  </List>
);
export default TownList;
