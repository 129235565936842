import ProtectionCreate from './ProtectionCreate';
import ProtectionEdit from './ProtectionEdit';
import ProtectionList from './ProtectionList';
import ProtectionShow from './ProtectionShow';

export default {
  list: ProtectionList,
  create: ProtectionCreate,
  edit: ProtectionEdit,
  show: ProtectionShow,
};
