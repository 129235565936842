import * as React from "react";
import { Card, CardContent } from '@mui/material';
import { Title,useCreatePath, Link, useRecordContext, useGetRecordId, useGetOne } from 'react-admin';
import { Grid } from '@mui/material';
import '@fortawesome/fontawesome-free/css/all.min.css';



const AdminManager = () => {




  return (
      <Card className={"ManagerPage"}>
        <CardContent>
          <Grid container>
            <Grid item xs={4}>
              <div className={"btnAction"}><i className="fas fa-circle-plus"></i></div>
              Empoissonnage
            </Grid>
            <Grid item xs={4}>
              <div className={"btnAction"}><i className="fas fa-fish"></i></div>
              Pêche
            </Grid>
            <Grid item xs={4}>
              <div className={"btnAction"}><i className="fas fa-wheat-awn"></i></div>
              Apport d'alimentation
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
}
export default AdminManager;





