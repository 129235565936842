import * as React from 'react';
import {
    BooleanInput,
    Edit, ImageField, ImageInput,
    SimpleForm,
    TextInput, useGetOne, useShowController, FormDataConsumer, Labeled, useCreate,useUpdate, useNotify, useRedirect
} from 'react-admin';
import {Grid} from '@mui/material';
import 'moment/locale/fr'
import {RichTextInput} from 'ra-input-rich-text';
import axios from 'axios';

const ImageUrl = ({data}) => {

    const {data: image, loading, error} = useGetOne('media_object', {id: data.file});
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading image</div>;
    if (!image) return null;



    return (
        <>

            <FormDataConsumer>
                {({formData, dispatch, ...rest}) => {
                    if (!formData.file.src) {
                        return (
                            <><ImageInput source="file" label="Photo" accept="image/*">
                                <ImageField source="src" title="title"/>
                            </ImageInput>
                                <div>
                                    <Labeled label="Original image">
                                        <img src={"https://api.poissonsdedombes.fr/images/" + image.contentUrl}/>
                                    </Labeled>
                                </div>
                            </>

                        );
                    } else {
                        return (
                            <ImageInput source="file" label="Photo" accept="image/*">
                                <ImageField source="src" title="title"/>
                            </ImageInput>
                        )

                    }
                }}
            </FormDataConsumer>


        </>
    );
};

    let dateForm = undefined;
const NewsEdit = () => {
    const notify = useNotify();
    const showData  = useShowController();
    let [create, { data: createResponse, isLoading, error }] = useCreate();
    const [update, { data: updateResponse }] = useUpdate();
        const redirect = useRedirect();


    const postSave = async (formData) => {
        dateForm = formData;
        console.log(dateForm);

        if (typeof dateForm.file === 'string' || dateForm.file instanceof String){
            update('news', { id: showData.record.id, data: dateForm });
            notify(`Élément mis à jour`, { type: 'success' });
            redirect('/news');
        }
        else {
                    if (!createResponse) {
                        create('media_objects', {data: formData});
                    }
        }


    };

    if (createResponse && dateForm !== undefined) {
        dateForm.file = createResponse.id;
        update('news', { id: showData.record.id, data: dateForm });
        notify(`Élément mis à jour`, { type: 'success' });

        createResponse = undefined;
        dateForm = undefined;
        redirect('/news');

    }

    return (
        <Edit >
            <h1 className={"titleName"}></h1>
            <SimpleForm sanitizeEmptyValues onSubmit={postSave} redirect="list">
                <Grid container>
                    <Grid container className={"containerInput"}>
                        <Grid item md={3} className={"itemForm1"}>
                            <TextInput source="title" label="Titre" fullWidth/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <RichTextInput source="content" label="Contenu"/>
                    </Grid>
                </Grid>
                <Grid container className={"containerInput"}>
                    <Grid item md={3}>
                        <ImageUrl data={showData.record}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} className={"itemForm1"}>
                        <BooleanInput source="state" label="Actif / Inactif" fullWidth/>
                    </Grid>
                </Grid>

            </SimpleForm>
        </Edit>
    )
        ;
}


export default NewsEdit;
