import { Layout,useGetIdentity, useGetOne, useRecordContext   } from "react-admin";
import { useLocation  } from 'react-router-dom';
import BackButton from '../Tools/BackButton/BackButton';
import BackActionsButton from '../Tools/BackButton/BackActionsButton';
import { ReactQueryDevtools } from 'react-query/devtools'
import { MyAppBar } from '../MyAppBar';

const CustomLayout = (props) => {

  const location = useLocation();
  const search = location.search; 
    console.log(search);
  const hiddenNavbarRoutes = [
      "/forgot_password",
      "/forgot_password/",
      "/change_password",
      "/change_password/"
  ];

  const shouldHideNavbar = () => {
      return hiddenNavbarRoutes.includes(location.pathname);
  }

  if (shouldHideNavbar()) {
      return <div>{props.children}</div>;
  }



  // Vérifier si l'URL se termine par "balance_sheet"
    const isMenuPage = location.pathname.endsWith("/balance_sheet")
    + location.pathname.endsWith("/water_bodies")
    + location.pathname.endsWith("/custom-route")
    + location.pathname.endsWith("/water_bodies")
    + location.pathname.endsWith("/admin_manager")
    + location.pathname.endsWith("/news")
    + location.pathname.endsWith("/fish_prices");

    const shouldShowBackActionsButton = search.includes('?filter={%22waterBody%22:%22/water_bodies/');
    console.log('shouldShowBackActionsButton:', shouldShowBackActionsButton);


  return (
    <Layout             appBar={MyAppBar}
                        {...props}>
      <ReactQueryDevtools />
      <div>

      {!isMenuPage ? (<BackButton/>) : ('')}
      {shouldShowBackActionsButton ? (<BackActionsButton />) : ('')}
      </div>
        {props.dashboard}
        {props.children}

    </Layout>
  );
};

export default CustomLayout;
