import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TextField } from '@mui/material';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

function GrantFormShow({actions , grantYear}) {
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [currentActionIndex, setCurrentActionIndex] = useState(null);

    const handleOpenDialog = (index, content) => {
        setDialogContent(content);
        setCurrentActionIndex(index);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    console.log("grantYear",grantYear);
    const mainActions = actions?.filter(action => action.grantFormAction.parent_id == null).filter(action => action.grantYear === grantYear);
    const subActions = actions?.filter(action => action.grantFormAction.parent_id != null);
    console.log("main",mainActions);
    return (
        <>
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            PaperProps={{ style: { minWidth: '300px' } }}
        >
            <DialogTitle>{"Contenu"}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>

        <Table className="compliance-table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '500px' }}>Étapes</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold' }}>Cocher si étape terminée</TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight:'bold', width: '250px' }}>Commentaires</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {mainActions ? mainActions.map((action, index) => (
                    <React.Fragment key={`main-action-${action.id}`}>
                        <TableRow key={index}>
                            <TableCell>{action.grantFormAction.action}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                {action.grantBoolean ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '100%'
                                }}>
                                    <span>{action.comment ? action.comment : ''}</span>
                                    <IconButton size="small" onClick={() => handleOpenDialog(index, action.comment)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </div>
                            </TableCell>

                        </TableRow>
                        {subActions.filter(subAction => {
                            const parentIdNumber = parseInt(subAction.grantFormAction.parent_id, 10);
                            const actionIdNumberMatch = action.grantFormAction.id.match(/\d+$/);
                            const actionIdNumber = actionIdNumberMatch ? parseInt(actionIdNumberMatch[0], 10) : null;
                            return parentIdNumber === actionIdNumber;
                            }).map((subAction, subIndex) => (
                            <TableRow key={`sub-action-${subAction.id}`} style={{backgroundColor: '#f4f4f4'}}>
                                    <TableCell style={{ paddingLeft: 40 }}>
                                        <SubdirectoryArrowRightIcon style={{marginTop:-8}} />
                                        {subAction.grantFormAction.action}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                    {subAction.grantBoolean ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%'
                                    }}>
                                        <span>{subAction.comment ? subAction.comment : ''}</span>
                                        <IconButton size="small" onClick={() => handleOpenDialog(index, subAction.comment)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </React.Fragment>
                )): ''}
            </TableBody>
        </Table>
        </>
    );
}

export default GrantFormShow;
