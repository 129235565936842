import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import JumboDemoCard from "../../@jumbo/components/JumboDemoCard";

const StackedBarChart = (props) => {
  const { title, legend, ticks, data, size, switchBtn, titleBtn, functionBtn, switchBtn2, titleBtn2, functionBtn2, selectedBtn, unityValue } = props;
  const chartData = [{ name: 'Stacked', ...data.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.valeur }), {}) }];

  const formatNumber = (number) => {
    return parseFloat(number).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    });
  };

  const formatTick = (value) => {
    if (value >= 1000 && value < 1000000) {
      return (value / 1000).toLocaleString('en') + 'k';
    }
    if (value >= 1000000) {
      return (value / 1000000).toLocaleString('en') + 'M';
    }
    return value.toLocaleString('en');
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) { 
      return (
        <div style={{ backgroundColor: 'white', padding: 15, borderRadius: '5px', border: '1px solid darkgray' }}>
          {payload.map((entry, index) => (
            <p key={`tooltip-${index}`} style={{ color: entry.fill, wordWrap: 'break-word' }}>
              {`${entry.name} : ${formatNumber(entry.value)} ${unityValue ? unityValue : ''}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
  const CustomLegend = () => {
    if (legend === "none") {
      return null;
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        {data.map((entry, index) => (
          <div key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center', margin: "30px 5px 5px 10px" }}>
            <div style={{ width: 10, height: 10, backgroundColor: entry.fill, marginRight: 5 }}></div>
            <span style={{ fontSize: '12px' }}>{entry.name.replace(/\s*\([^)]*\)\s*/g, '')}</span>
          </div>
        ))}
      </div>
    );
  };
  
  return (
    <JumboDemoCard
      wrapperSx={{ pt: 0, backgroundColor: 'background.paper' }}
      title={
        <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {Array.isArray(title)
            ? title.map((line, i) => <div key={i} style={{ lineHeight: '1.8' }}>{line}</div>)
            : title}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
          {switchBtn && (
            <button
              style={{
                color: 'white',
                backgroundColor: selectedBtn === 'kg' ? '#4e73df' : 'lightgray',
                border: 'none',
                height: 40,
                width: 60,
                borderRadius: 5,
                padding: '0 10px 0 10px',
                fontSize: '15px',
              }}
              onClick={functionBtn}
            >
              {titleBtn}
            </button>
          )}
          {switchBtn2 && (
            <button
              style={{
                color: 'white',
                backgroundColor: selectedBtn === 'kg/ha' ? '#4e73df' : 'lightgray',
                border: 'none',
                height: 40,
                width: 60,
                borderRadius: 5,
                padding: '0 10px 0 10px',
                fontSize: '15px',
                marginLeft: '5px',
              }}
              onClick={functionBtn2}
            >
              {titleBtn2}
            </button>
          )}
        </div>

        </div>

      }

    >
      <ResponsiveContainer width="100%" height={size ? size : 200}>
        <BarChart data={chartData} margin={{ top: 10, right: 0, left: 0, bottom: 10 }}>
          <YAxis ticks={[0, ...ticks]} tickFormatter={formatTick} axisLine={false} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          {
            data.map((entry, index) => (
              <Bar
                key={`bar-${index}`}
                dataKey={entry.name}
                stackId="stack"
                fill={entry.fill}
                barSize={100}
              />
            ))
          }
        </BarChart>
      </ResponsiveContainer>
    </JumboDemoCard>
  );
};

export default StackedBarChart;
