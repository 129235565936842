import * as React from 'react';
import { Create,useListController,ReferenceField, FormDataConsumer, useRedirect, useGetOne, SimpleForm, useGetList, TextInput, DateInput, required, BooleanInput, NumberInput, SelectInput, ReferenceInput   } from 'react-admin';
import { Grid } from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'


const ContributionPriceCreate = () => {
  const redirect = useRedirect();


  let recordId;
  const queryParameters = new URLSearchParams(window.location.hash.split('?')[1])
  const id = queryParameters.get("id")
  const  waterBody   = useGetOne('water_bodies', { id: id });
  const onSuccess = () => {
    redirect({
      pathname: '/member_ship_prices',
      search: `filter=${JSON.stringify({ waterBody: "" })}`,
    });
  };
    const getCurrentYear = () => {
        const date = new Date();
        return date.getFullYear();
    };

    const prepareYearChoices = () => {
        const currentYear = getCurrentYear()-5;
        const choices = [];

        for (let i = 0; i <= 10; i++) {
            const year = currentYear + i;
            choices.push({ id: year.toString(), name: year.toString() });
        }
        console.log(choices);
        return choices;
    };
    const YearSelectInput = props => {
        return <SelectInput {...props} choices={prepareYearChoices()} />;
    };

  let current = useListController();

  return(
      <Create  mutationOptions={{ onSuccess }} mutationMode="pessimistic" >
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''}</h1>
        <SimpleForm sanitizeEmptyValues>
            <Grid container>
                <Grid item xs={6}>
                    <ReferenceInput source="memberStatus"  reference="member_statuses" allowEmpty>
                        <SelectInput optionText="name" label="Statut de l'adhérent" validate={[required()]} fullWidth />
                    </ReferenceInput>
                </Grid>
            </Grid>
          <Grid container>
            <Grid item xs={6}>
                <YearSelectInput source="memberShipYear" label="Année d'adhésion" />
            </Grid>
          </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <NumberInput source="contribution"  label="Cotisation HT (en € HT)" validate={[required()]} fullWidth />
                </Grid>
            </Grid>

        </SimpleForm>
      </Create>
  );
}


export default ContributionPriceCreate;
