import FertilizationCreate from './FertilizationCreate';
import FertilizationEdit from './FertilizationEdit';
import FertilizationList from './FertilizationList';
import FertilizationShow from './FertilizationShow';

export default {
  list: FertilizationList,
  create: FertilizationCreate,
  edit: FertilizationEdit,
  show: FertilizationShow,
};
