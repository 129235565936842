import { List,ReferenceField,useDataProvider,BooleanInput, useNotify, useUnselectAll, FilterForm,ReferenceInput,SelectInput,useRecordContext,BulkDeleteButton,usePermissions,ShowButton,useListContext,useList,FunctionField,useGetOne,useListController,TopToolbar,ExportButton, Datagrid, TextField, DateField, BooleanField, EditButton, Button, Link } from 'react-admin';
import { Box,  Typography } from '@mui/material';
import moment from 'moment'
import 'moment/locale/fr'
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';

const Empty = () => {
    const { permissions } = usePermissions();
    let data = useListController();
    return(
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore de subventions.
            </Typography>
            {permissions !== undefined && permissions?.includes('ROLE_ADMIN') ?
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            :''}
            {permissions !== undefined && permissions?.includes('ROLE_ADMIN') ?
            <Button href={"#/grant_requests/create"} label="Créer"/>
            :''}

        </Box>
    );
}

function formatRole(role) {
  const withoutPrefix = role.replace('ROLE_', '');

  switch (withoutPrefix) {
      case 'FISHFARMER':
          return 'Pisciculteur';
      case 'TRANSFORMER':
          return 'Transformateur';
      case 'COLLECTOR':
          return 'Collecteur';
      case 'ENCLOSER':
          return 'Ecloseur';
      default:
          return withoutPrefix;
  }
}
const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };
  const prepareYearChoices = () => {
    const currentYear = getCurrentYear()-5;
    const choices = [];

    for (let i = 0; i <= 10; i++) {
      const year = currentYear + i;
      choices.push({ id: year.toString(), name: year.toString() });
    }
    console.log(choices);
    return choices;
  };
  const prepareUserChoices = async (dataProvider) => {
    const { data: users } = await dataProvider.getList('users', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'lastName', order: 'ASC' },
    });

    return users.map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName} ${user.structureName ? '/ ' + user.structureName : ''}`
    }));
};

const PostListActions = () => {
    let data = useListController();
    const dataProvider = useDataProvider();
    const [userChoices, setUserChoices] = useState([]);
    const { permissions } = usePermissions();

    useEffect(() => {
        if (permissions?.includes('ROLE_ADMIN')) {
            prepareUserChoices(dataProvider).then(setUserChoices);
        }
    }, [dataProvider, permissions]);
    const choicesUser = [
        { role: "ROLE_FISHFARMER", name: formatRole("ROLE_FISHFARMER") },
        { role: "ROLE_TRANSFORMER", name: formatRole("ROLE_TRANSFORMER") },
        { role: "ROLE_COLLECTOR", name: formatRole("ROLE_COLLECTOR") },
      ];
      const booleanFilters = permissions?.includes('ROLE_ADMIN') ? [
        <SelectInput
            source="grantBoolean"
            label="Dossier Clos"
            choices={[
                { id: 1, name: 'Dossier clos' },
                { id: 0, name: 'Dossier en cours' }
            ]}
            alwaysOn
        />
    ] : [];
      const userFilters = permissions?.includes('ROLE_ADMIN') ? [
        <SelectInput
            source="user"
            choices={userChoices}
            optionValue="id"
            optionText="name"
            label="Nom/Prénom/Organisme"
            fullWidth
            alwaysOn
        />,
    ] : [];

    const postFilters = permissions?.includes('ROLE_ADMIN') ? [
        <SelectInput
            source="grantYear"
            choices={prepareYearChoices()}
            label="Année"
            fullWidth
            alwaysOn
        />
    ] : [];

    const postFiltersRoles = permissions?.includes('ROLE_ADMIN') ? [
        <SelectInput
            source="role"
            choices={choicesUser}
            optionValue="role"
            optionText="name"
            label="Rôles"
            fullWidth
            alwaysOn
        />
    ] : [];

    return (
        <TopToolbar>
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
                {permissions?.includes('ROLE_ADMIN') && (
                    <>
                        <span style={{display: 'contents'}}><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={userFilters} /></span>
                        <span style={{display: 'contents'}}><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFilters} /></span>
                        {/*<span style={{display: 'contents'}}><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFiltersRoles} /></span>*/}
                        <span style={{display: 'contents'}}><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={booleanFilters} /></span>
                    </>
                )}
                {permissions?.includes('ROLE_ADMIN') && (
                    <div style={{display: 'flex', marginRight: 10}}>
                        <Button href={"#/grant_requests/create"} label="Créer"/>
                    </div>
                )}
            </Stack>
        </TopToolbar>
    );
}
const CustomBulkDeleteButton = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = useListContext();

    const customHandleDelete = async () => {
        try {

            const { data: grantRequests } = await dataProvider.getMany('grant_requests', { ids: selectedIds });
            const userIds = grantRequests.map(item => item.user);
            for (const userId of userIds) {
                const { data: grantContents } = await dataProvider.getList('grant_form_contents', {
                    filter: { user: userId },
                    pagination: { page: 1, perPage: 10000 },
                    sort: { field: "id", order: "ASC" }
                });
                const contentIds = grantContents.map(item => item.id);
                if (contentIds.length > 0) {
                    await dataProvider.deleteMany('grant_form_contents', { ids: contentIds });
                }
            }

            notify('Elements supprimés');
            unselectAll(props.resource);
        } catch (error) {
            notify('Erreur lors de la suppression', 'warning');
        }
    };

    return <BulkDeleteButton {...props} onClick={customHandleDelete} />;
};


const PostBulkActionButtons = (props) => (
    <>
        <CustomBulkDeleteButton {...props} />
    </>
);

const GrantRequestList = () => {
    useHideModifierLabel();
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    let data = useListController();
    const { data: grantRequests } = useListController();
    const [currentRole, setCurrentRole] = useState(null);
    const [grantBooleanStatuses, setGrantBooleanStatuses] = useState([]);
    useEffect(() => {
        const fetchGrantFormActionId = async () => {
            try {
                const { data: grantFormActions } = await dataProvider.getList('grant_form_actions', {
                    filter: { action: "Dossier clos" },
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'id', order: 'ASC' }
                });

                if (grantFormActions.length > 0) {
                    return grantFormActions[0].id;
                } else {
                    console.error("Aucune action 'Dossier clos' trouvée.");
                    return null;
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de l'ID de l'action :", error);
                return null;
            }
        };

        if (grantRequests && grantRequests.length > 0) {
            const fetchGrantFormContents = async () => {
                const grantFormActionId = await fetchGrantFormActionId();
                if (grantFormActionId) {
                    const promises = grantRequests.map(async (grantRequest) => {
                        const { data: grantFormContents } = await dataProvider.getList('grant_form_contents', {
                            filter: { grantRequest: grantRequest.id, grantFormAction: grantFormActionId },
                            pagination: { page: 1, perPage: 1 },
                            sort: { field: 'id', order: 'ASC' }
                        });
                        const hasTrueGrantBoolean = grantFormContents.some(content => content.grantBoolean === true);
                        return { id: grantRequest.id, status: hasTrueGrantBoolean };
                    });
                    const results = await Promise.all(promises);
                    setGrantBooleanStatuses(results);
                }
            };

            fetchGrantFormContents();
        }
    }, [dataProvider, grantRequests]);


    const rowStyle = (record) => {
        const status = grantBooleanStatuses.find(status => status.id === record.id);
        if (status && status.status) {
            return { backgroundColor: 'rgb(174 247 177)', color:'black' }; // Si grantBooleanStatuses est true
        }
        return { backgroundColor: 'rgb(255 197 118 / 87%)', color:'black' }; // Si grantBooleanStatuses est false ou indéfini
    };
    return (
        <List actions={<PostListActions />} empty={<Empty />}>
            <h1 className={"titleName"}>{data !== undefined ? data.defaultTitle : ''}</h1>

            <Datagrid rowStyle={rowStyle} bulkActionButtons={<PostBulkActionButtons {...data} />} >
                <TextField source="originId" label="#" />
                <FunctionField
                    label="Année de subvention"
                    render={record => `${record.grantYear}-${record.grantIndex}`}
                />
                <FunctionField
                source="role"
                label="Rôle"
                render={record => {
                    const role = formatRole(record.role);
                    setCurrentRole(role);
                    return role;
                }}
            />
            <ReferenceField label="Nom/Prénom/Organisme" source="user" reference="grantrequests" link={false}>
                <FunctionField render={record => record &&  `${record.firstName} ${record.lastName} ${record.structureName ? ' / ' + record.structureName : ''}`} emptyText="missing data"/>
            </ReferenceField>

                {permissions !== undefined && permissions?.includes('ROLE_ADMIN') ?
                    <EditButton label="MODIFIER"/>
                :''}

                <ShowButton/>
            </Datagrid>
        </List>
    );
}
export default GrantRequestList;

