import React, { useState, useEffect } from "react";
import {Create,useRecordContext,useDataProvider,FormDataConsumer,useListController,useRedirect,useGetOne, SimpleForm,useGetList,TextInput,DateInput,required,BooleanInput, NumberInput,SelectInput,ReferenceInput,FunctionField } from 'react-admin';
import { Grid, Typography, Button} from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { RichTextInput } from 'ra-input-rich-text';
import 'moment/locale/fr';
import ComplianceTable from '../Tools/ComplianceForm/ComplianceFormCreate';


const BrandComplianceCreate = () => {
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedRoleFormatted, setSelectedRoleFormatted] = useState(null);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const handleUserChange = (userId) => {
      setSelectedUserId(userId);
      setSelectedRoleFormatted(formatRole(selectedRole));
  };
  const handleCampaignChange = (campaign) => {
    setSelectedCampaign(campaign);

};
  const [complianceData, setComplianceData] = useState(null);

  const { data } = useGetList('campaigns', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
  });
  let choices = [];
  if (data !== undefined) {
    data.map((campaign, index) => {
      moment.locale('fr');
      const startDate = moment(campaign.startDate).format('YYYY');
      const endDate = moment(campaign.endDate).format('YYYY');
      choices[index] =
        {
          id: campaign.id,
          name: startDate+" / "+endDate
        };


    });
  }

const choicesUser = [
    { role: "ROLE_FISHFARMER", name: formatRole("ROLE_FISHFARMER") },
    { role: "ROLE_TRANSFORMER", name: formatRole("ROLE_TRANSFORMER") },
    { role: "ROLE_COLLECTOR", name: formatRole("ROLE_COLLECTOR") },
  ];

function formatRole(role) {
  const withoutPrefix = role.replace('ROLE_', '');

  switch (withoutPrefix) {
      case 'FISHFARMER':
          return 'Pisciculteur';
      case 'TRANSFORMER':
          return 'Transformateur';
      case 'COLLECTOR':
          return 'Collecteur';
      default:
          return withoutPrefix;  // If the role doesn't match any of the specified cases, return the role as-is.
  }
}


  const { data: filteredUsers } = useGetList(
    'users',
    {
      sort: { field: 'firstName', order: 'ASC' },
      filter: { roles: selectedRole ? [selectedRole] : [] }, // Utilisation d'un tableau ici
    },
    { enabled: !!selectedRole }
);

  const userChoices = filteredUsers
    ? Object.values(filteredUsers).map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`
      }))
    : [];
    const handleRoleChange = (roleValue) => {
      setSelectedUserId(null);
      setSelectedRole(roleValue);
    };
  const redirect = useRedirect();
  const onSuccess = () => {
    const campaignIdContent = selectedCampaign;
    const campaignIdFinal = campaignIdContent.split('/')[2];
    const userIdRaw = selectedUserId;
      const userId = userIdRaw.split('/')[2];
      const url = `https://api.poissonsdedombes.fr/brandcompliance/compliance-form-content/${userId}/${campaignIdFinal}`;
      const token = localStorage.getItem('token');
      fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(complianceData)
      })
      .then(response => response.json())
      .then(data => {
          if (data.error) {
              console.error(`Erreur : ${data.error}`);
          } else {
              console.log(data.message);
          }
      })
      .catch(error => {
          console.error('Une erreur est survenue lors de l\'envoi des données:', error);
      });

      console.log(complianceData);
    redirect({
      pathname: '/brand_compliances',
    });
  };

  let current = useListController();
  const [combinationExists, setCombinationExists] = useState(null);
  const [existingEntryId, setExistingEntryId] = useState(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
      if (selectedCampaign && selectedUserId) {
          const checkExistingCompliance = async () => {
            dataProvider.getList('brand_compliances', {
              sort: { field: 'id', order: 'ASC' },
              pagination: { page: 1, perPage: 1 },
              filter: {
                  user: selectedUserId,
                  role: selectedRole,
                  campaign: selectedCampaign
              }
            }).then(({ data, total }) => {
                setCombinationExists(total > 0);
                if (total > 0) {
                    setExistingEntryId(data[0].id); // Supposons que data est un tableau et que vous voulez l'ID de la première entrée
                }
            }).catch(error => {
                console.error('Erreur lors de la récupération de brandcompliance:', error);
            });

          };

          checkExistingCompliance();
      }
  }, [selectedCampaign, selectedUserId, dataProvider]);

  return (
    <Create mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleForm sanitizeEmptyValues>
        <h1 className={"titleName"}>{current !== undefined ? current.defaultTitle : ''}</h1>
        <Grid container>
          <Grid item xs={6}>
          <SelectInput source="campaign" choices={choices} label="Campagne"  onChange={(e) => handleCampaignChange(e.target.value)} validate={[required()]} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
          <SelectInput
            source="role"
            choices={choicesUser}
            optionValue="role"
            optionText="name"
            label="Rôles de l'utilisateur"
            onChange={(e) => handleRoleChange(e.target.value)}
            fullWidth
          />

          </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={6}>
                {selectedRole &&
                    <SelectInput
                      label="Utilisateur"
                      source="user"
                      choices={userChoices}
                      fullWidth
                      onChange={(e) => handleUserChange(e.target.value)}
                    />

                }
            </Grid>
        </Grid>
        {combinationExists && (
        <div>
            <Typography variant="body1" style={{color:'crimson'}}>
                Cette utilisateur possède déjà une conformité marque pour cette campagne.
                <br/>
                Appuyez sur
                <Link to={`/brand_compliances/${encodeURIComponent(`${existingEntryId}`)}`}><Button style={{fontWeight:'bold'}}>MODIFIER</Button></Link>
                si vous voulez la modifier

            </Typography>
        </div>
    )}
        {selectedRole && selectedUserId && combinationExists === false && ["ROLE_FISHFARMER", "ROLE_COLLECTOR", "ROLE_TRANSFORMER"].includes(selectedRole) && <ComplianceTable role={selectedRoleFormatted} onUpdateData={setComplianceData} />}

        <Grid container>
          <Grid item xs={4}>
            <TextInput label="Commentaire global" source="note" fullWidth/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <BooleanInput label="Conformité" source="compliance" validate={required()}/>
          </Grid>
        </Grid>

      </SimpleForm>
    </Create>
  );
};

export default BrandComplianceCreate;
