import { TranslationMessages } from "react-admin";
import frenchMessages from "ra-language-french";

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    pos: {
        edit: "Modifier"
    }
};

export default customFrenchMessages;
