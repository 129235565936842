import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styles for the table
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(1),
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderBottom: 'none',
  color: 'black',
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}));

const CustomTable = ({ columns, data, verticalHeader = false, bold = false }) => {
    const showTableHead = columns.some((column) => column.label.trim() !== "");

    if (verticalHeader) {
    return (
        <StyledTableContainer component={Paper}>
            <Table>
                <TableBody>
                    {columns.map((column, colIndex) => (
                        <StyledTableRow key={column.key}>
                            <StyledTableCell>{column.label}</StyledTableCell>
                            {data.map((row, rowIndex) => {
                                const isResultRow = Object.values(row).some(
                                    (value) => String(value).toLowerCase().includes("résultat")
                                );
                                if ((bold && column.key === 'indicator') || column.bold) {
                                    return (
                                        <BoldTableCell key={`${column.key}-${rowIndex}`}>{row[column.key]}</BoldTableCell>
                                    );
                                } else if (isResultRow) {
                                    return (
                                        <BoldTableCell key={`${column.key}-${rowIndex}`}>{row[column.key]}</BoldTableCell>
                                    );
                                } else {
                                    return (
                                        <TableCell key={`${column.key}-${rowIndex}`}>{row[column.key]}</TableCell>
                                    );
                                }
                            })}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
}


    // The original table layout (horizontal headers)
    return (
        <StyledTableContainer component={Paper}>
            <Table>
                {showTableHead && (
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.key}>{column.label}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {data.map((row, index) => {
                        const isResultRow = Object.values(row).some(
                            (value) => String(value).toLowerCase().includes("résultat")
                        );
                        return (
                            <StyledTableRow key={index}>
                                {columns.map((column) => (
                                    isResultRow ?
                                        <BoldTableCell key={column.key}>{row[column.key]}</BoldTableCell> :
                                        <TableCell key={column.key}>{row[column.key]}</TableCell>
                                ))}
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default CustomTable;
