import MaintenanceWorkCreate from './MaintenanceWorkCreate';
import MaintenanceWorkEdit from './MaintenanceWorkEdit';
import MaintenanceWorkList from './MaintenanceWorkList';
import MaintenanceWorkShow from './MaintenanceWorkShow';

export default {
  list: MaintenanceWorkList,
  create: MaintenanceWorkCreate,
  edit: MaintenanceWorkEdit,
  show: MaintenanceWorkShow,
};
